import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appMaxNumber]'
})
export class MaxNumberDirective
{

    @Input() appMaxNumber: number;

    constructor(private _el: ElementRef, private _control: NgControl)
    {
    }

    @HostListener('input', ['$event'])
    @HostListener('change', ['$event'])
    onInput(e: Event): void
    {
        const value = +(e.target as HTMLInputElement).value;

        if (value > this.appMaxNumber) {
            this._control.valueAccessor.writeValue(this.appMaxNumber);
            this._control.control.patchValue(this.appMaxNumber);
        }
    }
}
