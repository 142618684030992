import { Component, Input } from '@angular/core';
import { Warehouse } from '../../../../domain/entity/warehouse/warehouse.entity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-warehouse-details',
  templateUrl: './warehouse-details.component.html',
  styleUrls: ['./warehouse-details.component.scss']
})
export class WarehouseDetailsComponent {

  @Input() warehouse: Warehouse;

  constructor(public activeModal: NgbActiveModal) {}

}
