import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {AbstractRepository} from './abstract.repository';
import {BrandResponse} from '../response/brand-response';
// import {ClassResponse} from '../response/class-response';

@Injectable({
    providedIn: 'root',
})

export class CatalogRepository extends AbstractRepository
{
    fetchBrandsList = (): Observable<{items: BrandResponse[]}> => this.httpClient.get<{items: BrandResponse[]}>(this.buildUrl('srm/brand/list'));

    // fetchClassesList = (): Observable<{items: ClassResponse[]}> => this.httpClient.get<{items: ClassResponse[]}>(this.buildUrl('class/list'));
}
