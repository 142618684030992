import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessMappingForm } from '../../../forms/process-mapping.form';
import { UntypedFormGroup } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { ToastrService } from '../../../services/toastr.service';
import { Subscription } from 'rxjs';
import { BoodmoBrandInterface } from '../../../../domain/models/boodmo-brand.interface';
import { ImportService } from '../../../services/import.service';
import { BrandMappingFacade } from '../../../facades/brand-mapping/brand-mapping.facade';
import { CreateStatusMessageInterface } from '../../../../domain/models/create-status-message.interface';
import { StatusMessageInterface } from '../../../../domain/models/status-message.interface';

@Component({
  selector: 'app-process-mapping',
  templateUrl: './process-mapping.component.html',
  styleUrls: ['./process-mapping.component.scss']
})
export class ProcessMappingComponent implements OnInit
{

  @Input() brands: BoodmoBrandInterface[];
  @Input() mapping: BrandMappingFacade;

  fb: ProcessMappingForm;
  form: UntypedFormGroup;
  inlineLoader: Subscription | boolean;

  constructor(
    public activeModal: NgbActiveModal,
    private importService: ImportService,
    private toastrService: ToastrService,
  )
  { }

  ngOnInit(): void
  {
    this.fb = new ProcessMappingForm(this.mapping);
    this.form =  this.fb.getForm();
  }

  processMapping(): void
  {
    this.fb.markSubmitted();
    if (!this.form.valid) {
      return;
    }

    const value = this.form.value;
    const observable$ = this.mapping
      ? this.importService.updateMapping(value.brandId, this.mapping.id, value.active, value.additional)
      : this.importService.createMapping(value.brandName, value.brandId, value.additional);

    this.inlineLoader = observable$.pipe(finalize(() => this.inlineLoader = false))
      .subscribe((res: CreateStatusMessageInterface | StatusMessageInterface) => {
        const mapping: BrandMappingFacade = BrandMappingFacade.build({
          id: 'id' in res ? res?.id : this.mapping.id,
          brand_name: value.brandName,
          brand_id: value.brandId,
          active: this.mapping ? value.active : true,
          additional: value.additional,
        }, this.brands);

        this.toastrService.successNotify(res.message ?? `Brand mapping successfully ${this.mapping.id ? 'updated' : 'created'}`);
        this.activeModal.close(mapping);
      });
  }

  selectBrand(brand: BoodmoBrandInterface): void
  {
    this.form.patchValue({
      brandId: brand.id,
      boodmoName: brand.name
    });
  }

}
