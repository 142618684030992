import { Injectable, TemplateRef } from '@angular/core';
import { StatusMessageInterface } from '../../domain/models/status-message.interface';

@Injectable({
    providedIn: 'root'
})
export class ToastrService
{

    toasts: any[] = [];

    show(textOrTpl: string | TemplateRef<any>, options: any = {}): void
    {
        this.toasts.push({textOrTpl, ...options});
    }

    remove(toast: any): void
    {
        this.toasts = this.toasts.filter(t => t !== toast);
    }

    successNotify(data: StatusMessageInterface | string, options: any = {}): boolean
    {
        this.show(typeof data === 'string' ? data : data.message, {
            ...options,
            classname: `bg-success text-light ${options.classname ? options.classname : ''}`
        });
        return false;
    }

    errorNotify(data: StatusMessageInterface | string, options: any = {}): boolean
    {
        this.show(typeof data === 'string' ? data : data.error && (data.error?.message || data.error?.detail) || data.message, {
            ...options,
            classname: `bg-danger text-light ${options.classname ? options.classname : ''}`,
        });
        return false;
    }
}
