import { AbstractForm } from './abstract.form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {BoxDimensionsInterface} from '../../domain/models/box-dimensions.interface';

export class BoxDimensionsForm extends AbstractForm
{
  min = 1;
  minWeight = 0.01;
  step = 1;
  stepWeight = 0.01;

  constructor(dimensions?: BoxDimensionsInterface) {
    super();

    this.form = new UntypedFormGroup({
      height: new UntypedFormControl(dimensions?.height || 0, [
        Validators.required,
        Validators.min(this.min),
      ]),
      width: new UntypedFormControl(dimensions?.width || 0, [
        Validators.required,
        Validators.min(this.min),
      ]),
      length: new UntypedFormControl(dimensions?.length || 0, [
        Validators.required,
        Validators.min(this.min),
      ]),
      weight: new UntypedFormControl(dimensions?.weight || 0, [
        Validators.required,
        Validators.min(this.minWeight),
        Validators.pattern(`(\\d+)?(\\.)?(\\d{0,2})?`)
      ]),
    });

    Object.assign(this.errorMsg, {
      step: 'Only integers',
      pattern: `Multiple of ${this.stepWeight}`,
    });
  }
}
