<app-loader *ngIf="loader"></app-loader>

<div class="card">
  <div class="card-body" *ngIf="fb">
    <form *ngIf="form" novalidate [formGroup]="form" [ngClass]="{'was-validated' : fb?.isSubmitted()}" (ngSubmit)="save()">

      <div class="mb-3 row" *ngFor="let key of formControls" [formGroupName]="key">
        <label class="form-label col-sm-4" [for]="key + 'method'">{{key.split('_').join(' ') | titlecase}}:</label>
        <div class="col-sm-3 pb-2 pb-sm-0">
          <input type="text" [id]="key + 'method'" class="form-control form-control-sm"
                 formControlName="method" required>
          <div class="invalid-feedback" *ngIf="form.getError('required', [key, 'method'])">{{fb.getErrorMsg('required')}}</div>
        </div>
        <div class="col-sm-5">
          <input type="text" [id]="key + 'action'" class="form-control form-control-sm"
                 formControlName="action"> <!-- TODO required ?-->
          <div class="invalid-feedback" *ngIf="form.getError('required', [key, 'action'])">{{fb.getErrorMsg('required')}}</div>
        </div>
      </div>

      <div class="mt-4 text-end">
        <button type="submit" class="btn btn-primary btn-sm px-4 btn-sm-down-block">Save</button>
      </div>
    </form>
  </div>
</div>
