import { Pipe, PipeTransform } from '@angular/core';
import _get from 'lodash-es/get';

@Pipe({
  name: 'filterBy'
})
export class FilterByPipe implements PipeTransform
{

  transform<T>(collection: T[], term: string, keys?: string): T[]
  {
    if (!collection) { return []; }
    if (!term) { return collection; }
    if (!keys) { return (collection).filter(item => String(item).indexOf(term) !== -1 )}

    return (collection || [])
      .filter(item =>
        keys.split(',').some(key => {
          const val = _get(item, key, undefined);
          return val !== undefined && new RegExp(term, 'gi').test(val);
        })
      );
  }
}
