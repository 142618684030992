import { Pipe, PipeTransform } from '@angular/core';
import {APP_CONFIG} from 'src/config/app.server.config';

@Pipe({
  name: 'buildPartLink'
})
export class BuildPartLinkPipe implements PipeTransform {

  transform(partId: number | null, partNumber?: string): any
  {
    if (!partId) {
      return null;
    }
    partNumber = partNumber ? (partNumber.toLowerCase()).replace(/[^a-z0-9]/g, '_') : 'number';
    return `${APP_CONFIG.MAIN_SERVER}/catalog/part-${partNumber}-${partId}/`;
  }

}
