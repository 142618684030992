<div class="modal-header">
  <h5 class="modal-title">New item details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="facade">
  <p class="row">
    <b class="col-5">Order ID:</b>
    <span class="col-7">{{ facade.orderNumber }}</span>
  </p>
  <p class="row">
    <b class="col-5">Part Number:</b>
    <span class="col-7"><app-part-link [item]="facade?.salesItem"></app-part-link></span>
  </p>
  <p class="row">
    <b class="col-5">Brand:</b>
    <span class="col-7">{{ facade?.brand }}</span>
  </p>
  <p class="row">
    <b class="col-5">Part Name:</b>
    <span class="col-7">{{ facade?.salesItem.partName }}</span>
  </p>
  <p class="row">
    <b class="col-5">Qty:</b>
    <span class="col-7">{{ facade?.salesItem.qty }}</span>
  </p>
  <p class="row">
    <b class="col-5">Unit Price:</b>
    <span class="col-7">{{ facade?.price | indianCurrency}}</span>
  </p>
  <p class="row">
    <b class="col-5">Row Total:</b>
    <span class="col-7">{{ facade?.total | indianCurrency }}</span>
  </p>
  <p class="row">
    <b class="col-5">Confirm Before:</b>
    <span class="col-7">{{ facade?.confirmationBefore | bdate: 'dateTime' }}</span>
  </p>

</div>
<div class="modal-footer btn-modal-wrapp">
  <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
