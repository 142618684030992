import { Component, Input } from '@angular/core';
import { OfferFacade } from '../../../facades/offer/offer.facade';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-price-details',
  templateUrl: './price-details.component.html',
  styleUrls: ['./price-details.component.scss']
})
export class PriceDetailsComponent {

  @Input() price: OfferFacade;

  public constructor(public activeModal: NgbActiveModal)
  { }

}
