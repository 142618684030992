import { Component, Input, OnInit } from '@angular/core';
import { Warehouse } from '../../../../domain/entity/warehouse/warehouse.entity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from '../../../services/toastr.service';
import { ProcessWarehouseForm } from '../../../forms/process-warehouse.form';
import { UntypedFormGroup } from '@angular/forms';
import { Observable, Subscription } from 'rxjs';
import { StatusMessageInterface } from '../../../../domain/models/status-message.interface';
import { finalize } from 'rxjs/operators';
import { WarehouseService } from '../../../services/warehouse.service';
import {WarehouseRequest} from '../../../../infrastructure/requests/warehouse.request';
import {Residence} from '../../../../domain/entity/residence/residence.entity';
import {StateInterface} from '../../../../domain/models/state.interface';
import {DictionaryService} from '../../../services/dictionary.service';

@Component({
  selector: 'app-process-warehouse',
  templateUrl: './process-warehouse.component.html',
  styleUrls: ['./process-warehouse.component.scss']
})
export class ProcessWarehouseComponent implements OnInit {
  @Input() warehouse?: Warehouse;
  @Input() readonly allResidences: Residence[];
  inlineLoader: Subscription | boolean;
  fb: ProcessWarehouseForm;
  form: UntypedFormGroup;
  isNewWarehouse: boolean;
  title: string;
  previousResidence: Residence | undefined;
  statesLoading: Subscription | boolean;
  // states: StateInterface[];
  states$: Observable<StateInterface[]>;

  constructor(
    public activeModal: NgbActiveModal,
    private toastrService: ToastrService,
    private warehouseService: WarehouseService,
    private dictionaryService: DictionaryService,
  ) {
  }

  get residences(): Residence[] {
    return this.allResidences?.filter(r => !r.fulfilmentCenter);
  }

  ngOnInit(): void {
    this.previousResidence = this.allResidences?.find(r => r.name === this.warehouse?.residenceName);
    this.isNewWarehouse = !this.warehouse;
    this.title = `${this.isNewWarehouse ? 'Add' : 'Edit'} warehouse`;
    this.fb = new ProcessWarehouseForm(this.warehouse, this.previousResidence);
    this.form = this.fb.getForm();
    this.states$ = this.dictionaryService.loadStateList();
  }

  save() {
    this.fb.markSubmitted();
    if (!this.form.valid) {
      if (this.form.disabled) {
        this.toastrService.errorNotify('Can\'t update disabled form');
      }
      return;
    }
    const {
      name,
      residence,
      dispatchDays,
      enabled,
      manageQty,
      shippingAddress
    } = this.form.getRawValue();
    const newWarehouse: WarehouseRequest = {
      name,
      residence,
      dispatch_days: Number(dispatchDays),
      enabled,
      manage_qty: manageQty,
      address: {
        title: shippingAddress.title || null,
        contact_first_name: this.warehouse?.shippingAddress?.contactFirstName ?? '',
        contact_last_name: this.warehouse?.shippingAddress?.contactLastName ?? '',
        contact_phone: this.warehouse?.shippingAddress?.contactPhone ?? '',
        pin: +(shippingAddress?.pin),
        city_name: shippingAddress?.cityName,
        address_line: shippingAddress?.addressLine,
        state: shippingAddress?.state,
      }
    };
    const process = (): Observable<StatusMessageInterface> => this.isNewWarehouse
      ? this.warehouseService.create(newWarehouse)
      : this.warehouseService.update(newWarehouse, this.warehouse.id);
    this.inlineLoader = process().pipe(
      finalize(() => {
        this.inlineLoader = false;
      })
    ).subscribe((response: StatusMessageInterface) => {
      this.toastrService.successNotify(response);
      this.activeModal.close('success');
    });
  }

  handlerChangeState(): void {
    this.form.patchValue({
      shippingAddress: {
        pin: null,
      }
    });
  }
}
