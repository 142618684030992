<app-toastr-container aria-live="polite" aria-atomic="true"></app-toastr-container>
<ng-template appConfirm>
  <app-confirm-modal-component></app-confirm-modal-component>
</ng-template>

<div class="app">

  <app-sidebar class="sidebar sidebar-dark sidebar-fixed bg-gradient-brand bg-gradient-brand--v"
               *ngIf="logged"
               [showMenu]="showMenu"
               [showMobileMenu]="showMobileMenu"
               [sidebarUnfoldable]="sidebarUnfoldable"
               (hideSidebar)="toggleMobileMenu(); showProfilePanel=false"></app-sidebar>

  <div class="wrapper" [ngClass]="{ 'bg-gradient-brand bg-gradient-brand--h': !logged }">

    <header class="header header-fixed" *ngIf="logged">
      <div class="align-items-center d-flex justify-content-between w-100">
        <div>
          <button class="header-toggler class-toggler d-md-down-none"
                  type="button"
                  (click)="toggleSidebar()"
          >
            <svg-icon [src]="iconUrls.menu"></svg-icon>
          </button>
          <button class="header-toggler class-toggler d-lg-none"
                  type="button"
                  (click)="toggleMobileMenu()"
          >
            <svg-icon [src]="iconUrls.menu"></svg-icon>
          </button>
        </div>
        <div class="d-flex align-items-center gap-2">
          <app-export-results #exportResults
                              (click)="toggleProfilePanel(false);"
          />

          <div *ngIf="sellerService.$currentSeller | async as currentSeller">
            <div (click)="toggleProfilePanel();exportResults.dropdown.close()">
              <button class="btn btn-link p-0 text-truncate profile-panel-toggler">{{currentSeller?.name}}</button>
              <button class="btn btn-link p-0">
                <img [src]="currentSeller?.avatarPath" [alt]="currentSeller?.name"
                     class="profile-image rounded-circle">
              </button>
            </div>
          </div>
        </div>
      </div>
    </header>
    <app-profile-panel
      class="card shadow"
      [showProfilePanel]="showProfilePanel"
      (hideProfilePanel)="showProfilePanel=$event"
    ></app-profile-panel>

    <div class="body">
      <main class="main" role="main"
            [class.background]="!logged">
        <div class="container-fluid">
          <app-breadcrumbs *ngIf="logged"></app-breadcrumbs>
          <div class="fade-in">
            <router-outlet></router-outlet>
          </div>
        </div>
      </main>
    </div>
    <footer class="footer" *ngIf="logged">
      <div class="d-flex align-items-center flex-1 text-muted">
        <!--        <button class="sidebar-minimizer class-toggler" type="button" (click)="toggleMenu()"></button>-->

        <span class="d-none d-md-block fw-700" [innerHTML]="copyrightNotice"></span>
      </div>
    </footer>
  </div>

  <div class="sidebar-backdrop fade show"
       *ngIf="showMobileMenu"
       (click)="toggleMobileMenu()"
  ></div>
</div>
