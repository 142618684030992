import { DateUtil } from 'src/app/application/utils/date/date.util';
import {SellerService} from '../../../application/services/seller.service';
import {ImportBatchStatus} from '../../models/import-batch-status.enum';

export class ImportBatch {
  static readonly STATUS_NEW = ImportBatchStatus[ImportBatchStatus.New] as 'New';
  static readonly STATUS_QUEUED = ImportBatchStatus[ImportBatchStatus.Queued] as 'Queued';
  static readonly STATUS_PROCESSING = ImportBatchStatus[ImportBatchStatus.Processing] as 'Processing';
  static readonly STATUS_DONE = ImportBatchStatus[ImportBatchStatus.Done] as 'Done';
  static readonly STATUS_FAILED = ImportBatchStatus[ImportBatchStatus.Failed] as 'Failed';

  private static IMPORT_STEPS_LINK_INTERNAL_PREFIX = '/products/import/';
  private static IMPORT_STEPS_LINK_EXTERNAL_PREFIX = 'seller/';
  private static IMPORT_STEPS_LINK_SUFFIX = 'step';
  private static FOLDER_NAME = '/imports';
  private sellerService: SellerService;

  private constructor(
    private _id: string,
    private _step: string,
    private _status: keyof typeof ImportBatchStatus,
    private _filename: string,
    private _originalFilename: string,
    private _validFilename: string | null,
    private _errorFilename: string | null,
    private _warehouse: string,
    private _createdAt?: Date,
    private _batchNumber?: number,
  ) {
  }

  get stepId(): number {
    return +this._step.slice(-1);
  }

  get id(): string {
    return this._id;
  }

  get batchNumber(): number {
    return this._batchNumber;
  }

  get step(): string {
    return this._step;
  }

  get status(): keyof typeof ImportBatchStatus {
    return this._status;
  }

  get filename(): string {
    return this._filename;
  }

  get originalFilename(): string {
    return this._originalFilename;
  }

  get validFilename(): string | null {
    return this._validFilename;
  }

  get errorFilename(): string | null {
    return this._errorFilename;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get warehouse(): string {
    return this._warehouse;
  }

  get isFullConfigured(): boolean {
    return Boolean(this.filename && this.id);
  }

  get hasErrors(): boolean {
    return this._errorFilename !== null;
  }

  static build = (object: any): ImportBatch => new ImportBatch(
      object.id,
      object.step,
      object.status,
      object.filename,
      object.original_filename,
      object.valid_filename,
      object.error_filename,
      object.warehouse,
      DateUtil.convertTimestampToDate(object.createdat),
      object.batch_number,
  );

  static getLinkByStep = (stepId: number): string => `${ImportBatch.IMPORT_STEPS_LINK_INTERNAL_PREFIX}${ImportBatch.IMPORT_STEPS_LINK_SUFFIX}${stepId}`;

  static getStepName = (stepId: number): string => `${ImportBatch.IMPORT_STEPS_LINK_SUFFIX}${stepId}`;

  static folderPath = (sellerId: string, warehouseId: string): string =>
    `${ImportBatch.IMPORT_STEPS_LINK_EXTERNAL_PREFIX}${sellerId}${ImportBatch.FOLDER_NAME}/${warehouseId}`;

  changeStep = (step: string) => {
    this._step = step;
    return this;
  };

  changeFilename = (filename: string, originalFilename: string) => {
    this._filename = filename;
    this._originalFilename = originalFilename;
    return this;
  };

  getLinkByStep = (stepId: number): string => ImportBatch.getLinkByStep(stepId);

  validateImport = (validPath: string = null, errorPath: string = null) => {
    this._errorFilename = errorPath;
    this._validFilename = validPath;
    return this;
  };
}
