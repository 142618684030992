<app-loader *ngIf="loading"></app-loader>

<div class="" *ngIf="batch">

    <div class="card-body">
        <p class="mb-2">
            You will receive import results on your email.
        </p>
    </div>

    <!--footer-->
    <div class="card-footer justify-content-between d-flex align-items-center">
        <span class="text-muted">Step 3 of 3</span>
        <div class="d-inline-block">
            <button class="btn btn-secondary me-1"
                    [routerLink]="batch?.getLinkByStep(stepId - 1)"
                    [queryParams]="{batchID: batch?.id}">Previous
            </button>
        </div>
    </div>
</div>
