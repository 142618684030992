import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SalesItem} from '../../../../../domain/entity/sales-item/sales-item.entity';
import {Warehouse} from '../../../../../domain/entity/warehouse/warehouse.entity';
import {ShippingAddress} from '../../../../../domain/vo/shipping-address/shipping-address.vo';
import {ConfirmService} from '../../../../services/confirm-modal.service';
import {BoxDimensionsForm} from '../../../../forms/box-dimensions.form';
import {ToastrService} from '../../../../services/toastr.service';
import {SalesItemService} from '../../../../services/sales-item.service';
import {ShipmentService} from '../../../../services/shipment.service';
import {ShipmentInfoFacade} from '../../../../facades/shipment-info/shipment-info.facade';

@Component({
  selector: 'app-shipment-details',
  templateUrl: './shipment-details.component.html',
  styleUrls: ['./shipment-details.component.scss']
})
export class ShipmentDetailsComponent implements OnInit {
  @Input() shipmentId?: string;
  @Input() items?: SalesItem[];
  @Input() warehouse?: Warehouse;
  loader: Subscription | boolean;
  fb: BoxDimensionsForm;
  form: UntypedFormGroup;
  qtyMap: Record<string, number> = {};
  shipmentInfo: ShipmentInfoFacade;

  constructor(
    public activeModal: NgbActiveModal,
    public confirmService: ConfirmService,
    private toastrService: ToastrService,
    public salesItemService: SalesItemService,
    private shipmentService: ShipmentService,
  ) {
  }

  get isPacked(): boolean {
    return this.shipmentId !== undefined;
  }

  get pickupAddress(): ShippingAddress {
    return this.isPacked ? this.shipmentInfo?.pickupAddress : (this.items && this.items[0].residenceAddress);
  }

  ngOnInit(): void {
    if (this.isPacked) {
      this.getShipmentInfo();
    } else {
      this.fb = new BoxDimensionsForm();
      this.form = this.fb.getForm();
      if (this.items) {
        for (const item of this.items) {
          this.qtyMap[item.id] = item.qty - item.packedQty;
        }
      }
    }
  }

  removeItem(item: SalesItem) {
    this.confirmService.confirm({
      title: 'Accept removing',
      message: 'Are you sure you want to remove this item?',
    }).then(() => {
      const itemIndex = this.items.indexOf(item);
      if (itemIndex >= 0) {
        delete this.qtyMap[item.id];
        this.items.splice(itemIndex, 1);
      }
    }, () => {});
  }

  tryPack() {
    this.fb.markSubmitted();
    if (!this.form.valid) {
      return;
    }
    this.loader = this.shipmentService.createBox(this.items[0].residenceId, this.form.value, this.qtyMap).pipe(
      finalize(() => {
        this.loader = false;
      })
    ).subscribe((response) => {
      this.toastrService.successNotify(response);
      this.activeModal.close(response);
    });
  }

  tryCancelOrder() {

  }

  private getShipmentInfo(): void {
    this.loader = this.shipmentService.fetchShipmentInfo(this.shipmentId).pipe(
      finalize(() => {
        this.loader = false;
      })
    ).subscribe((shipmentInfo) => {
      this.shipmentInfo = shipmentInfo;
    }, (err) => {
      this.toastrService.errorNotify(err);
    });
  }
}
