<div class="" *ngIf="show">
    <h2 *ngIf="title">{{title}}</h2>

    <table class="table table-striped table-hover table-bordered">
        <thead>
        <tr>
            <th width="50%">
                {{tableTitleFirst}}
            </th>
            <th>
                {{tableTitleSecond}}
            </th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let brand of brands; index as brandIndex; trackBy:identify">
            <td>
                {{brand.brand_name}}
            </td>
            <td>
                <div class="d-inline-block" ngbDropdown #brandDrop="ngbDropdown" autoClose="outside">
                    <button class="btn btn-sm btn-outline-secondary"
                            [ngClass]="brand?.saved ? 'btn-outline-success' : brand?.fail ? 'btn-outline-danger' : 'btn-outline-secondary'"
                            [id]="'brandDropdown' + brandIndex"
                            ngbDropdownToggle
                    >
                        {{brand?.mapped_brand || 'No selected brand' }}
                        <app-inline-loader *ngIf="brand.loading"></app-inline-loader>
                    </button>

                    <div ngbDropdownMenu [attr.aria-labelledby]="'brandDropdown' + brandIndex">
                        <div class="p-1">
                            <input class="form-control form-control-sm"
                                   [(ngModel)]="filter.value"
                                   #filter
                                   type="text"
                                   [appAutofocus]="brandDrop.isOpen()"
                            >
                        </div>
                        <div class="dropdown-inner" *ngIf="brandDrop.isOpen()">
                            <button *ngFor="let option of boodmoBrands | filterBy: filter.value: 'name' | orderBy:'sort' "
                                    (click)="saveMapping(brand, option); brandDrop.close()"
                                    ngbDropdownItem
                            >
                                {{option.name}}
                            </button>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        </tbody>
    </table>
</div>
