<div class="d-flex justify-content-end mb-1">
  <button type="button" class="btn btn-info btn-sm" (click)="processResidence($event)">New Residence</button>
</div>

<div class="card">
  <div class="card-body p-0">
    <div class="table-wrapper">
      <table class="table table-bordered table-hover table-striped table-pointer">
        <thead>
        <tr>
          <th>Display name</th>
          <th>GST</th>
          <th class="d-none d-sm-table-cell">Billing address</th>
          <th class="d-none d-sm-table-cell">Shipping address</th>
          <th>Type</th>
          <th class="d-none d-sm-table-cell">Shipping</th>
        </tr>
        </thead>
        <tbody>
        <ng-container
          *ngFor="let facade of residences | paginate: { itemsPerPage: itemsPerPage, currentPage: p }; let i = index">
          <tr (click)="openDetail(facade)">
            <td class="position-relative row-toggler-wrap--left">
              <div class="row-toggler" container="body" ngbDropdown>
                <div [id]="'dropdownToggleMore' + i"
                     ngbDropdownToggle
                     (click)="$event.stopPropagation()">
                  <svg-icon [src]="iconUrls.ellipsis"></svg-icon>
                </div>

                <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownToggleMore' + i">
                  <button class="btn" ngbDropdownItem
                          (click)="processResidence($event, facade)">
                    <svg-icon [src]="iconUrls.edit"></svg-icon>
                    Edit
                  </button>
                </div>
              </div>
              {{ facade.residence.name }}
            </td>
            <td>{{facade.residence.billingAddress.gstNumber}}</td>
            <td class="d-none d-sm-table-cell"
                [innerHTML]="facade.residence.billingAddress.convertAddressToLine | safeHtml"></td>
            <td class="d-none d-sm-table-cell"
                [innerHTML]="facade.residence.shippingAddress.convertAddressToLine | safeHtml"></td>
            <td>{{facade.type}}</td>
            <td class="d-none d-sm-table-cell">{{facade.shipping}}</td>
          </tr>
        </ng-container>

        </tbody>
      </table>
    </div>

    <app-tfoot [dataList]="residences"
               [loader]="loader"
               [itemsPerPage]="itemsPerPage"
               [currentPage]="p"
               (changePageNum)="p=$event"
    ></app-tfoot>
  </div>
</div>
