<div class="modal-header">
  <h5 class="modal-title">{{title}}</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <form novalidate [formGroup]="form" (ngSubmit)="save()">

    <div class="mb-3 row">
      <label for="name" class="form-label col-5">Display Name:</label>
      <div class="col-7">
        <input type="text" id="name" class="form-control form-control-sm" required formControlName="name"
               [class.is-invalid]="fb.isSubmitted() && form.get('name').invalid"
               [class.is-valid]="fb.isSubmitted() && !form.get('name').invalid">
        <app-form-control-error [control]="form.get('name')"
                                [formSubmit]="fb.isSubmitted()"
        ></app-form-control-error>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="residence" class="form-label col-5">Residence:</label>
      <div class="col-7">
        <input type="text" readonly
               *ngIf="previousResidence?.isFC"
               name="residence"
               [value]="previousResidence.name"
               class="form-control form-control-sm">
        <select class="form-select form-select-sm"
                *ngIf="!previousResidence?.isFC"
                required
                id="residence"
                name="residence"
                formControlName="residence"
                [class.is-invalid]="fb.isSubmitted() && form.get('residence').invalid"
                [class.is-valid]="fb.isSubmitted() && !form.get('residence').invalid"
        >
          <ng-container *ngIf="residences?.length; else emptyResidenceRef">
            <option value="" disabled>Select</option>
            <option *ngFor="let residence of residences" [value]="residence.id">{{ residence.name }}</option>
          </ng-container>
          <ng-template #emptyResidenceRef>
            <option value="" selected disabled>There are no active residences</option>
          </ng-template>
        </select>
        <app-form-control-error [control]="form.get('residence')"
                                [formSubmit]="fb.isSubmitted()"
        ></app-form-control-error>
      </div>
    </div>
    <div class="mb-3 row">
      <label for="dispatchDays" class="form-label col-5">Dispatch days:</label>
      <div class="col-7">
        <input type="number" step="1"
               id="dispatchDays"
               class="form-control form-control-sm"
               required
               appNumeric
               [min]="fb.minDays"
               formControlName="dispatchDays"
               [class.is-invalid]="fb.isSubmitted() && form.get('dispatchDays').invalid"
               [class.is-valid]="fb.isSubmitted() && !form.get('dispatchDays').invalid">
        <app-form-control-error [control]="form.get('dispatchDays')"
                                [formSubmit]="fb.isSubmitted()"
        ></app-form-control-error>
<!--        <div class="invalid-feedback" *ngIf="form.getError('min', 'dispatchDays')">{{fb.getErrorMsg('min', 'dispatchDays', fb.minDays)}}</div>-->
      </div>
    </div>

    <div class="mb-3 row">
      <label class="form-label col-5" for="enabled1">Status:</label>
      <div class="col-7">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="enabled" name="enabled" id="enabled1"
                 required
                 [value]="true">
          <label class="form-check-label" for="enabled1">Enabled</label>
        </div>
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" formControlName="enabled" name="enabled" id="enabled2"
                 [value]="false">
          <label class="form-check-label" for="enabled2">Disabled</label>
        </div>
        <app-form-control-error [control]="form.get('enabled')"
                                [formSubmit]="fb.isSubmitted()"
        ></app-form-control-error>
      </div>
    </div>

    <div class="mb-3 row">
      <label class="form-label col-5">Qty managed:</label>
      <div class="col-7">
        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="manageQty"
                 required
                 id="managedQty"
                 [value]="true"
                 formControlName="manageQty"
          />
          <label class="form-check-label" for="managedQty">Yes</label>
        </div>

        <div class="form-check form-check-inline">
          <input class="form-check-input" type="radio" name="manageQty"
                 id="notManagedQty"
                 [value]="false"
                 formControlName="manageQty"
          />
          <label class="form-check-label" for="notManagedQty">No</label>
        </div>
        <div class="invalid-feedback d-block"
             *ngIf="fb.isSubmitted() && form.getError('required', 'manageQty')">{{fb.getErrorMsg('required')}}</div>
      </div>
    </div>

    <fieldset formGroupName="shippingAddress">
      <legend>Shipping address</legend>

      <div *ngIf="previousResidence?.fulfilmentCenter && warehouse; else shippingAddressForm" [innerHTML]="warehouse.shippingAddress.convertAddressToLine | safeHtml"></div>
      <ng-template #shippingAddressForm>
        <div class="mb-3 row">
          <label class="form-label col-sm-4" for="address">Shipping Address:</label>
          <div class="col-sm-8">
            <input type="text" id="address" class="form-control form-control-sm"
                   required
                   [minLength]="fb.minAddressLength"
                   [maxLength]="fb.maxAddressLength"
                   formControlName="addressLine"
                   [class.is-invalid]="fb.isSubmitted() && form.get(['shippingAddress', 'addressLine']).invalid"
                   [class.is-valid]="fb.isSubmitted() && !form.get(['shippingAddress', 'addressLine']).invalid">
            <app-form-control-error [control]="form.get(['shippingAddress', 'addressLine'])"
                                    [formSubmit]="fb.isSubmitted()"
            ></app-form-control-error>
          </div>
        </div>

        <div class="mb-3 row">
          <label class="form-label col-sm-4">PIN:</label>
          <div class="col-sm-8">
            <app-pin-control formControlName="pin"
                             [groupName]="'shippingAddress'"
                             [fb]="fb"
                             [form]="form"
            ></app-pin-control>
          </div>
        </div>

        <div class="mb-3 row">
          <label class="form-label col-sm-4" for="state">State:</label>
          <div class="col-sm-8">
            <select id="state" class="form-select form-select-sm"
                    required
                    formControlName="state"
                    (change)="handlerChangeState()"
                    [class.is-invalid]="fb.isSubmitted() && form.get(['shippingAddress', 'state']).invalid"
                    [class.is-valid]="fb.isSubmitted() && !form.get(['shippingAddress', 'state']).invalid"
            >
              <option value="" disabled>Select state</option>
              <option [value]="state.abbr" *ngFor="let state of states$ | async">{{state.name}}</option>
            </select>
            <app-inline-loader *ngIf="statesLoading"></app-inline-loader>
            <app-form-control-error [control]="form.get(['shippingAddress', 'state'])"
                                    [formSubmit]="fb.isSubmitted()"
            ></app-form-control-error>
          </div>
        </div>

        <div class="mb-3 row">
          <label class="form-label col-sm-4" for="cityName">City:</label>
          <div class="col-sm-8">
            <input type="text" id="cityName" class="form-control form-control-sm"
                   required
                   [minLength]="fb.minLength"
                   [maxLength]="fb.cityLength"
                   formControlName="cityName"
                   [class.is-invalid]="fb.isSubmitted() && form.get(['shippingAddress', 'cityName']).invalid"
                   [class.is-valid]="fb.isSubmitted() && !form.get(['shippingAddress', 'cityName']).invalid"
            >
            <app-form-control-error [control]="form.get(['shippingAddress', 'cityName'])"
                                    [formSubmit]="fb.isSubmitted()"
            ></app-form-control-error>
          </div>
        </div>
      </ng-template>
      <!--            </div>-->
    </fieldset>

    <button type="submit" hidden #saveButton>Save</button>
  </form>

</div>
<div class="modal-footer btn-modal-wrapp">
  <button type="button" class="btn btn-primary btn-modal" (click)="saveButton.click()">
    Save
    <app-loader *ngIf="inlineLoader"></app-loader>
  </button>
</div>
