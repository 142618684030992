import {ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {UntypedFormGroup} from '@angular/forms';
import {BankDetailsForm} from '../../forms/bank-details.form';
import {Seller} from '../../../domain/entity/seller/seller.entity';
import {SellerService} from '../../services/seller.service';
import {ToastrService} from '../../services/toastr.service';
import {Subscription, zip} from 'rxjs';
import {BreadcrumbsService} from '../../services/breadcrumbs/breadcrumbs.service';
import {finalize} from 'rxjs/operators';
import {IconUrls} from '../../../constants/icon-urls';
import {UploadFileResponse} from '../../../infrastructure/response/upload-file.response';
import {CoreService} from '../../services/core.service';
import {APP_CONFIG} from '../../../../config/app.server.config';
import {MerchantAccount} from '../../../domain/entity/merchant-account/merchant-account.entity';


@Component({
    selector: 'app-bank-details',
    templateUrl: './bank-details.component.html',
    styleUrls: ['./bank-details.component.scss']
})
export class BankDetailsComponent implements OnInit {

    loader: Subscription | boolean;
    fb: BankDetailsForm;
    form: UntypedFormGroup;
    private merchantAccount: MerchantAccount;

    constructor(
        public iconUrls: IconUrls,
        private titleService: Title,
        private sellerService: SellerService,
        private toastrService: ToastrService,
        private breadcrumbsService: BreadcrumbsService,
        private coreService: CoreService,
        private cd: ChangeDetectorRef,
    ) {
        const title = 'Settings - Bank details';
        this.titleService.setTitle(`${title} · Seller Cabinet`);
        this.breadcrumbsService.init(title);
    }

    ngOnInit(): void {
        this.getBankDetails();
    }

    trySave(): void {
        this.fb.markSubmitted();
        if (!this.form.valid) {
            return;
        }

        const uploadBatchMap = {};
        for (const key in this.form.controls) {
            if (this.form.value[key] instanceof File) {
                const file: File = this.form.value[key];
                uploadBatchMap[key] = this.coreService.uploadFile(
                    file,
                    this.sellerService.currentSeller.folderPath,
                    file.name,
                    true
                );
            }
        }

        const uploadBatchValues = Object.values(uploadBatchMap);

        if (!uploadBatchValues.length) {
          return this.save();
        }

        this.loader = zip(Object.values(uploadBatchMap) as UploadFileResponse[]).pipe(
            finalize(() => {
                this.loader = false;
            })
        ).subscribe((responseArray: UploadFileResponse[]) => {
            const value = {};
            let index = 0;
            for (const key of Object.keys(uploadBatchMap)) {
                value[key] = responseArray[index].filename;
                index++;
            }
            this.form.patchValue(value);
            this.cd.markForCheck();
            this.save();

        }, err => {
            this.toastrService.errorNotify(err);
        });
    }

    getFileLink = (path): string =>
        (typeof path === 'string')
            ? `${APP_CONFIG.MAIN_SERVER}${APP_CONFIG.MEDIA_SERVER_FOLDER}/${this.sellerService.currentSeller.folderPath}/${path}`
            : null;

    private getBankDetails(): void {
        this.loader = this.sellerService.loadSellerInfo().pipe(finalize(() => {
            this.loader = false;
        })).subscribe((seller: Seller) => {
            this.merchantAccount = seller.merchantAccount;
            this.fb = new BankDetailsForm(seller.merchantAccount);
            this.form = this.fb.getForm();
            setTimeout(() => {
                this.fb.setFiles();
            });
        });
    }

    private save() {
        const {
            name,
            ifsc,
            accountNumber,
            branchName,
            cancelledCheque,
            panCard,
            gstRegistrationCertificate,
            signatureAuthorizedPerson,
        } = this.form.value;
        this.loader = this.sellerService.updateSellerInfo(this.sellerService.currentSeller.id, {
            merchant_account: {
                contact_email: this.merchantAccount.contactEmail,
                cin_number: this.merchantAccount.cinNumber,
                bank_name: name,
                bank_ifsc: ifsc,
                bank_account_number: accountNumber,
                bank_branch_name: branchName,
                cancelled_cheque: cancelledCheque,
                pan_card: panCard,
                gst_cert: gstRegistrationCertificate,
                seller_signature: signatureAuthorizedPerson,
            },
        }).pipe(
            finalize(() => {
                this.loader = false;
            })
        ).subscribe((res) => {
            this.toastrService.successNotify(res.message ?? 'Bank details was successfully saved');
            // this.getBankDetails();
        });
    }
}
