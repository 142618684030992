import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

import {AbstractRepository} from './abstract.repository';
import {StatusMessageInterface} from '../../domain/models/status-message.interface';
import {WarehouseRequest} from '../requests/warehouse.request';

@Injectable({
  providedIn: 'root',
})
export class WarehouseRepository extends AbstractRepository {
  create = (obj: WarehouseRequest): Observable<StatusMessageInterface> =>
    this.httpClient.post<StatusMessageInterface>(this.buildUrl(`srm/warehouse`), obj);

  update = (id: string, obj: WarehouseRequest): Observable<StatusMessageInterface> =>
    this.httpClient.put<StatusMessageInterface>(this.buildUrl(`srm/warehouse/update/${id}`), obj);
}
