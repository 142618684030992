import {Warehouse} from '../warehouse/warehouse.entity';
import {APP_CONFIG} from 'src/config/app.server.config';

export class CurrentSeller {
  private constructor(
    private _id: string, // TODO accountId ?
    private _name: string, // TODO companyName?
    private _email: string | null,
    private _warehouses: Warehouse[],
    private _accountId?: string,
    private _companyName?: string,
    private _profilePhoto?: string,
  ) {
  }

  get id(): string {
    return this._id;
  }

  get accountId(): string {
    return this._accountId;
  }

  get name() {
    return this._name;
  }

  get email(): string | null {
    return this._email;
  }

  get warehouses(): Warehouse[] {
    return this._warehouses;
  }

  get companyName(): string {
    return this._companyName;
  }

  get profilePhoto(): string | null {
    return this._profilePhoto;
  }

  get folderPath(): string {
    return `seller/${this.id}`;
  }

  get avatarPath(): string
  {
    return this.profilePhoto ?
      `${APP_CONFIG.MAIN_SERVER}${APP_CONFIG.MEDIA_SERVER_FOLDER}/${this.folderPath}/${this._profilePhoto}` :
      '/assets/img/avatar-default.png';
  }

  static build = (seller: any): CurrentSeller => new CurrentSeller(
      seller.id,
      seller.name,
      seller.email,
      seller.warehouses.map(Warehouse.build),
      seller.account_id,
      seller?.company_name,
      seller?.profile_photo ?? null
  );
}
