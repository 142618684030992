import {Directive, ElementRef, Input, OnDestroy, OnInit} from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[invalid], [appInvalid]'
})
export class InvalidDirective implements OnInit, OnDestroy {
  @Input('invalid') checkAtFirst = false;
  subscription$: any;

  constructor(
    private control: NgControl,
    private el: ElementRef
  ) {
  }

  ngOnInit() {
    this.subscription$ = this.control.statusChanges.subscribe(res => {
      this.el.nativeElement.setCustomValidity(res === 'INVALID' ? 'error' : '');
    });
    if (this.checkAtFirst && this.control.invalid) {
      this.el.nativeElement.setCustomValidity('error');
    }
  }

  ngOnDestroy() {
    this.subscription$.unsubscribe();
  }
}
