<app-loader *ngIf="loader"></app-loader>
<div class="card shadow">
  <div class="card-body p-0" style="position: relative;" *ngIf="shipmentsLoaded">
    <div class="table-wrapper overflow-auto">
      <table class="table table-bordered table-hover">
        <thead>
        <tr>
          <th class="p-0"></th>
          <th class="d-none d-sm-table-cell">ShipmentId</th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'carrier'"
                    [config]="cols.carrier"
                    [queryBuilder]="queryBuilder"
                    [options]="carrierList"
                    [name]="'Carrier'"
                    (update)="filterData()"
                    [inputWidth]="'120px'"
                    [showAllOption]="true"
                    (sort)="sort($event)"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'invoiceTotal'"
                    [config]="cols.invoiceTotal"
                    [queryBuilder]="queryBuilder"
                    [name]="'Invoice total'"
                    (update)="filterData()"
                    (sort)="sort($event)"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell">Boxes count</th>
          <th class="d-none d-sm-table-cell">Weight</th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'invoiceNumber'"
                    [config]="cols.invoiceNumber"
                    [queryBuilder]="queryBuilder"
                    [name]="'Invoice'"
                    [placeholder]="'Invoice'"
                    (update)="filterData()"
                    (sort)="sort($event)"
            ></app-th>
          </th>
          <th>AWD</th>
          <th>
            ETA
            <!--            <app-th [colKey]="'eta'"-->
            <!--                    [config]="cols.eta"-->
            <!--                    [queryBuilder]="queryBuilder"-->
            <!--                    [name]="'ETA'"-->
            <!--                    (update)="filterData()"-->
            <!--                    (sort)="sort($event)"-->
            <!--                    [dropdownPosition]="'bottom-right'"-->
            <!--            ></app-th>-->
          </th>
          <th>Track status</th>
          <!--          <th class="p-0"></th>-->
        </tr>
        </thead>
        <tbody>

        <ng-container
          *ngFor="let facade of shipments | orderBy: sortOrder?.field : sortOrder?.reverse | paginate: { itemsPerPage: queryBuilder.getLimit(), currentPage: queryBuilder.getPage() }; let i = index">
          <tr (click)="openDetail(facade)">
            <td class="position-relative row-toggler-wrap">
              <div class="row-toggler" container="body" ngbDropdown>
                <div [id]="'dropdownToggleMore' + i"
                     ngbDropdownToggle
                     (click)="$event.stopPropagation()">
                  <svg-icon [src]="iconUrls.ellipsis"></svg-icon>
                </div>
                <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownToggleMore' + i">
                  <button class="btn btn-success" ngbDropdownItem>
                    <!--                        (click)="printLabels(facade)">-->
                    <!--                  <svg-icon [src]="iconUrls.edit"></svg-icon>-->
                    Print labels
                  </button>
                  <button class="btn btn-info" ngbDropdownItem>
                    <!--                        (click)="printInvoice(facade)">-->
                    <!--                  <svg-icon [src]="iconUrls.timesLight"></svg-icon>-->
                    Print Invoice
                  </button>
                </div>
              </div>
            </td>
            <td class="d-none d-sm-table-cell">
              <a type="button" class="text-decoration-underline"
                    (click)="openShipmentDetails(facade)">{{facade.shipment.reference}}</a>
            </td>
            <td class="d-none d-sm-table-cell">{{facade.carrier}}</td>
            <td class="d-none d-sm-table-cell">{{facade.invoiceTotal | indianCurrency}}</td>
            <td class="d-none d-sm-table-cell">{{facade.boxCount}}</td>
            <td class="d-none d-sm-table-cell">{{facade.shipment.dimensions.weight}}kg</td>
            <td class="d-none d-sm-table-cell">{{facade.invoiceNumber}}</td> <!-- TODO add link-->
            <td>{{facade.shipment.trackNumber}}</td> <!-- TODO add link-->
            <td>{{facade.eta | bdate: 'date'}}</td> <!-- TODO add real shipment.eta -->
            <td>{{facade.shipment.trackStatus}}</td> <!-- TODO open track status popup-->
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>

    <app-tfoot [queryBuilder]="queryBuilder"
               [dataList]="shipments"
               [loader]="loader"
               (changePageNum)="queryBuilder.changePageNum($event)"
    ></app-tfoot>
  </div>
</div>
