import {BoxDimensionsInterface} from '../../models/box-dimensions.interface';
import {AddressInterface} from '../../models/address.interface';
import {CarrierInterface} from '../../models/carrier.interface';
import {InvoiceInfo} from '../../vo/invoice-info/invoice-info.vo';
import { DateUtil } from 'src/app/application/utils/date/date.util';

export class Shipment {
  private constructor(
    private _id: string,
    private _provider: string,
    private _reference: string,
    private _pickupAddress: AddressInterface,
    private _carrier: CarrierInterface,
    private _invoiceInfo: InvoiceInfo,
    private _dimensions: BoxDimensionsInterface,
    private _trackNumber: string,
    private _trackStatus: string,
    private _deliveredAt: Date,
  ) {
  }

  get id(): string {
    return this._id;
  }

  get provider(): string {
    return this._provider;
  }

  get reference(): string {
    return this._reference;
  }

  get pickupAddress(): AddressInterface {
    return this._pickupAddress;
  }

  get carrier(): CarrierInterface {
    return this._carrier;
  }

  get invoiceInfo(): InvoiceInfo {
    return this._invoiceInfo;
  }

  get dimensions(): BoxDimensionsInterface {
    return this._dimensions;
  }

  get trackNumber(): string {
    return this._trackNumber;
  }

  get trackStatus(): string {
    return this._trackStatus;
  }

  get deliveredAt(): Date {
    return this._deliveredAt;
  }

  static build = (shipment: {
    id: string;
    provider: string;
    reference: string;
    pickup_address: string;
    carrier: string;
    weight: string;
    width: number;
    length: number;
    height: number;
    invoice_date: number;
    invoice_number: string;
    invoice_value: number;
    track_number: string;
    track_status: string;
    delivered_at: number;
  }): Shipment => new Shipment(
      shipment.id,
      shipment.provider,
      shipment.reference,
      JSON.parse(shipment.pickup_address),
      shipment.carrier ? JSON.parse(shipment.carrier) : shipment.carrier,
      InvoiceInfo.build({
        invoice_date: shipment.invoice_date,
        invoice_number: shipment.invoice_number,
        invoice_value: shipment.invoice_value
      }),
      {
        weight: +shipment.weight,
        width: shipment.width,
        length: shipment.length,
        height: shipment.height,
      },
      shipment.track_number,
      shipment.track_status,
      DateUtil.convertTimestampToDate(shipment.delivered_at),
  );
}
