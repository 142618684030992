import {Injectable} from '@angular/core';
import {AbstractRepository} from './abstract.repository';
import {FilterQueryBuilder} from '../../application/utils/query-builder/filter-query-builder';
import {Observable} from 'rxjs';
import {PartResponse} from '../response/part-response';

@Injectable({
    providedIn: 'root'
})
export class PartRepository extends AbstractRepository {

    readonly partUrl = 'pim/part';

    fetchParts = (queryBuilder: FilterQueryBuilder): Observable<PartResponse[]> => {
        const {filters, sorting, limit} = queryBuilder.getRequestObject();
        const sortingKey = Object.keys(sorting)[0];
        return this.httpClient.post<PartResponse[]>(this.buildUrl(`${this.partUrl}/grid`), {
            filters,
            limit,
            sorting: sorting[sortingKey] === 'desc' ? `-${sortingKey}` : sortingKey,
        });
    };
}
