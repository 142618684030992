<div class="card">
<!--  title-->
  <div class="card-hdr">
    <h1 class="card-title py-3">
<!--      Import-->
<!--      <app-inline-inlineLoader *ngIf="inlineLoader"></app-inline-inlineLoader>-->

      <a class="btn-link" href="javascript:void(0);"
         ngbTooltip="Download previous uploaded file"
         *ngIf="batch?.filename as filename"
         (click)="coreService.downloadByPath(ImportBatch.folderPath(currentSeller.id, batch.warehouse) + '/' + filename)">
        {{batch?.originalFilename}}
      </a>
    </h1>
  </div>

  <!--navs-->
  <div class="card-hdr steps">

    <ul class="list-unstyled p-0 m-0 d-flex flex-column flex-sm-row justify-content-between align-items-sm-center w-100">
      <li *ngFor="let item of stepsMap | keyvalue; let index = index"
          [ngClass]="{touched : checkTab(item.value.id), active: item.value.id === stepId}"
          class="align-items-center flex-grow-1"
      >
        <a class="d-flex align-items-center my-1 my-sm-0">
          <span class="number">{{index + 1}}</span>
          <span class="title-text">{{item.value.title}}</span>
        </a>
      </li>
    </ul>
  </div>

  <!--content-->
  <router-outlet (activate)="onActivate($event)"></router-outlet>
</div>
