<div class="card">
  <div class="card-body">

    <!--    <app- -->
    <form novalidate [formGroup]="form" (ngSubmit)="save()" *ngIf="fb">
      <div class="row justify-content-sm-center">
        <div class=" col-lg-8 col-xl-6">

          <h5 class="form-group-title">Seller portal</h5>

<!--          form-group-with-btn-->
          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="login">Login:</label>
            <div class="col-sm-8">
              <input type="email" id="login" class="form-control form-control-sm"
                     required placeholder="login@example.com"
                     formControlName="login"
                     [minLength]="fb.minEmail"
                     [maxLength]="fb.maxEmail"
                     [class.is-invalid]="fb.isSubmitted() && form.get('login').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('login').invalid">
              <app-form-control-error [control]="form.get('login')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
<!--              <button type="button"-->
<!--                      class="btn btn-secondary btn-sm"-->
<!--                      (click)="resetPassword()">-->
<!--                Reset password-->
<!--              </button>-->
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="displayName">Display name:</label>
            <div class="col-sm-8">
              <input type="text" id="displayName" class="form-control form-control-sm"
                     required
                     [minLength]="fb.minLength"
                     [maxLength]="fb.nameMaxLength"
                     formControlName="displayName"
                     [class.is-invalid]="fb.isSubmitted() && form.get('displayName').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('displayName').invalid">
              <app-form-control-error [control]="form.get('displayName')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error></div>
          </div>

          <h5 class="mb-3-title">Company info</h5>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="companyName">Company name:</label>
            <div class="col-sm-8">
              <input type="text" id="companyName" class="form-control form-control-sm"
                     required [minLength]="fb.minLength"
                     [maxLength]="fb.nameMaxLength"
                     formControlName="companyName"
                     [class.is-invalid]="fb.isSubmitted() && form.get('companyName').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('companyName').invalid">
              <app-form-control-error [control]="form.get('companyName')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="contactEmail">Contact email:</label>
            <div class="col-sm-8">
              <input type="email" id="contactEmail" class="form-control form-control-sm"
                     required
                     [minLength]="fb.minEmail"
                     [maxLength]="fb.maxEmail"
                     formControlName="contactEmail"
                     [class.is-invalid]="fb.isSubmitted() && form.get('contactEmail').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('contactEmail').invalid">
              <app-form-control-error [control]="form.get('contactEmail')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="cinNumber">CIN number:</label>
            <div class="col-sm-8">
              <input type="text" id="cinNumber" class="form-control form-control-sm"
                     required formControlName="cinNumber"
                     [class.is-invalid]="fb.isSubmitted() && form.get('cinNumber').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('cinNumber').invalid">
              <app-form-control-error [control]="form.get('cinNumber')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="firstName">Contact first name:</label>
            <div class="col-sm-8">
              <input type="text" id="firstName" class="form-control form-control-sm"
                     required
                     [minLength]="fb.nameMinLength"
                     [maxLength]="fb.nameMaxLength"
                     formControlName="firstName"
                     [class.is-invalid]="fb.isSubmitted() && form.get('firstName').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('firstName').invalid">
              <app-form-control-error [control]="form.get('firstName')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="lastName">Contact last name:</label>
            <div class="col-sm-8">
              <input type="text" id="lastName" class="form-control form-control-sm"
                     required
                     [minLength]="fb.nameMinLength"
                     [maxLength]="fb.nameMaxLength"
                     formControlName="lastName"
                     [class.is-invalid]="fb.isSubmitted() && form.get('lastName').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('lastName').invalid">
              <app-form-control-error [control]="form.get('lastName')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>
        </div>

        <div class=" col-lg-8 col-xl-6" formGroupName="billingAddress">
          <h5 class="form-group-title">Billing address</h5>

          <div class="mb-3 row">
            <label class="form-label col-sm-4">GST code:</label>
            <div class="col-sm-8">
              <app-gst-control formControlName="gstNumber"
                               [fb]="fb"
                               [form]="form"
                               [isRequired]="true"
                               groupName="billingAddress"
              ></app-gst-control>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="contactPhone">Phone number:</label>
            <div class="col-sm-8">
              <input type="text" id="contactPhone" class="form-control form-control-sm"
                     required
                     formControlName="contactPhone"
                     [minLength]="fb.phoneLength"
                     [maxLength]="fb.phoneLength"
                     [class.is-invalid]="fb.isSubmitted() && form.get('billingAddress.contactPhone').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('billingAddress.contactPhone').invalid">
              <app-form-control-error [control]="form.get('billingAddress.contactPhone')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="address">Office address:</label>
            <div class="col-sm-8">
              <input type="text" id="address" class="form-control form-control-sm"
                     required
                     [minLength]="fb.minAddressLength"
                     [maxLength]="fb.maxAddressLength"
                     formControlName="addressLine"
                     [class.is-invalid]="fb.isSubmitted() && form.get('billingAddress.addressLine').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('billingAddress.addressLine').invalid">
              <app-form-control-error [control]="form.get('billingAddress.addressLine')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4">PIN:</label>
            <div class="col-sm-8">

              <app-pin-control formControlName="pin"
                               [fb]="fb"
                               [form]="form"
                               groupName="billingAddress"
              ></app-pin-control>
<!--              <input type="text" id="pin" class="form-control form-control-sm"-->
<!--                     appNumeric-->
<!--                     required [minLength]="fb.pinLength"-->
<!--                     [maxLength]="fb.pinLength"-->
<!--                     [pattern]="fb.intPattern"-->
<!--                     formControlName="pin">-->
<!--              <div class="invalid-feedback">{{fb.getErrorMsg('general', 'pin')}}<br>-->
<!--                {{fb.getErrorMsg('length', 'Pin Code', fb.pinLength)}}</div>-->
<!--              <div class="invalid-feedback" *ngIf="form.getError('required', 'pin')">{{fb.getErrorMsg('required')}}</div>-->

            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="state">State:</label>
            <div class="col-sm-8">
              <select id="state" class="form-select form-select-sm"
                      required formControlName="state"
                      [class.is-invalid]="fb.isSubmitted() && form.get('billingAddress.state').invalid"
                      [class.is-valid]="fb.isSubmitted() && !form.get('billingAddress.state').invalid">
                <option [value]="null">Select state</option>
                <option [value]="state.abbr" *ngFor="let state of states">{{state.name}}</option>
              </select>
              <app-inline-loader *ngIf="statesLoading"></app-inline-loader>
              <app-form-control-error [control]="form.get('billingAddress.state')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>

          <div class="mb-3 row">
            <label class="form-label col-sm-4" for="city">City:</label>
            <div class="col-sm-8">
              <input type="text" id="city" class="form-control form-control-sm"
                     required
                     [minLength]="fb.minLength"
                     [maxLength]="fb.cityLength"
                     formControlName="city"
                     [class.is-invalid]="fb.isSubmitted() && form.get('billingAddress.city').invalid"
                     [class.is-valid]="fb.isSubmitted() && !form.get('billingAddress.city').invalid">
              <app-form-control-error [control]="form.get('billingAddress.city')"
                                      [formSubmit]="fb.isSubmitted()"
              ></app-form-control-error>
            </div>
          </div>
        </div>
        <div class="mt-4 col-12 text-end">
          <button type="submit" class="btn btn-primary btn-sm px-4 btn-sm-down-block">Save</button>
        </div>

      </div>

    </form>
  </div>
</div>
