<app-loader *ngIf="loader"></app-loader>
<div class="card">
    <div class="card-body">
        <form *ngIf="form" novalidate [formGroup]="form" [ngClass]="{'was-validated' : fb?.isSubmitted()}"
              (ngSubmit)="trySave()">
            <div class="row justify-content-sm-center">
                <div class=" col-lg-8 col-xl-6">

                    <div class="mb-3 row">
                        <label class="form-label col-sm-4" for="name">Bank name:</label>
                        <div class="col-sm-8">
                            <input type="text" id="name" class="form-control form-control-sm"
                                   required
                                   formControlName="name">
                            <div class="invalid-feedback"
                                 *ngIf="form.getError('required', 'name')">{{fb.getErrorMsg('required')}}</div>
                        </div>
                    </div>

                    <div class="mb-3 row mt-4">
                        <label class="form-label col-sm-4" for="ifsc">Bank IFSG:</label>
                        <div class="col-sm-8">
                            <input type="text" id="ifsc" class="form-control form-control-sm"
                                   required
                                   formControlName="ifsc">
                            <div class="invalid-feedback"
                                 *ngIf="form.getError('required', 'ifsc')">{{fb.getErrorMsg('required')}}</div>
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="form-label col-sm-4" for="accountNumber">Bank account number:</label>
                        <div class="col-sm-8">
                            <input type="text" id="accountNumber" class="form-control form-control-sm"
                                   required
                                   formControlName="accountNumber">
                          <div class="invalid-feedback" *ngIf="form.getError('required', 'accountNumber')">{{fb.getErrorMsg('required')}}</div>
                        </div>
                    </div>

                    <div class="mb-3 row">
                        <label class="form-label col-sm-4" for="branchName">Bank branch name:</label>
                        <div class="col-sm-8">
                            <input type="text" id="branchName" class="form-control form-control-sm"
                                   required
                                   formControlName="branchName">
                          <div class="invalid-feedback" *ngIf="form.getError('required', 'branchName')">{{fb.getErrorMsg('required')}}</div>
                        </div>
                    </div>

                </div>

                <div class=" col-lg-8 col-xl-6">
                    <div class="mb-3 row">
                        <label class="form-label col-sm-4" for="cancelledCheque">Cancelled cheque:</label>
                        <div class="col-sm-8">
                            <div class="d-flex file-upload">
                                <input class="flex-grow-1 p-1 file-upload-sm-input"
                                       id="cancelledCheque"
                                       formControlName="cancelledCheque"
                                       required
                                       appFileUploader
                                       [openElement]="cancelledChequeUploadBtn"
                                       [value]="form.controls.cancelledCheque.value?.name || form.controls.cancelledCheque.value"
                                >
                                <button #cancelledChequeUploadBtn type="button"
                                        class="btn btn btn-outline-dark file-upload-btn">
                                    <svg-icon [src]="iconUrls.folder"></svg-icon>
                                    Choose file
                                </button>
                            </div>
                          <div class="invalid-feedback"
                               [class.d-block]="fb.isSubmitted() && !form.get('cancelledCheque')?.valid"
                               *ngIf="form.getError('required', 'cancelledCheque')">{{fb.getErrorMsg('required')}}</div>

                          <a *ngIf="getFileLink(form.controls.cancelledCheque.valueChanges | async) as link" download
                               target="_blank" [href]="link">Download</a>
                        </div>

                    </div>

                    <div class="mb-3 row">
                        <label class="form-label col-sm-4" for="panCard">PAN card:</label>
                        <div class="col-sm-8">
                            <div class="d-flex file-upload">
                                <input class="flex-grow-1 p-1 file-upload-sm-input"
                                       id="panCard"
                                       formControlName="panCard"
                                       appFileUploader
                                       required
                                       [openElement]="panCardUploadBtn"
                                       [value]="form.controls.panCard.value?.name || form.controls.panCard.value"
                                >
                                <button #panCardUploadBtn type="button"
                                        class="btn btn btn-outline-dark file-upload-btn">
                                    <svg-icon [src]="iconUrls.folder"></svg-icon>
                                    Choose file
                                </button>
                            </div>
                          <div class="invalid-feedback"
                               [class.d-block]="fb.isSubmitted() && !form.get('panCard')?.valid"
                               *ngIf="form.getError('required', 'panCard')">{{fb.getErrorMsg('required')}}</div>
                          <a *ngIf="getFileLink(form.controls.panCard.valueChanges | async) as link" download
                               target="_blank" [href]="link">Download</a>
                        </div>

                    </div>

                    <div class="mb-3 row">
                        <label class="form-label col-sm-4" for="gstRegistrationCertificate">GST registration certificate:</label>
                        <div class="col-sm-8">
                            <div class="d-flex file-upload">
                                <input class="flex-grow-1 p-1 file-upload-sm-input"
                                       id="gstRegistrationCertificate"
                                       formControlName="gstRegistrationCertificate"
                                       appFileUploader
                                       required
                                       [openElement]="gstRegistrationCertificateUploadBtn"
                                       [value]="form.controls.gstRegistrationCertificate.value?.name || form.controls.gstRegistrationCertificate.value"
                                >
                                <button #gstRegistrationCertificateUploadBtn type="button"
                                        class="btn btn btn-outline-dark file-upload-btn">
                                    <svg-icon [src]="iconUrls.folder"></svg-icon>
                                    Choose file
                                </button>
                            </div>
                          <div class="invalid-feedback"
                               [class.d-block]="fb.isSubmitted() && !form.get('gstRegistrationCertificate')?.valid"
                               *ngIf="form.getError('required', 'gstRegistrationCertificate')">{{fb.getErrorMsg('required')}}</div>
                            <a *ngIf="getFileLink(form.controls.gstRegistrationCertificate.valueChanges | async) as link"
                               download target="_blank" [href]="link">Download</a>
                        </div>

                    </div>

                    <div class="row">
                        <label class="form-label col-sm-4" for="signatureAuthorizedPerson">Signature of authorized person:</label>
                        <div class="col-sm-8">
                            <div class="d-flex file-upload">
                                <input class="flex-grow-1 p-1 file-upload-sm-input"
                                       id="signatureAuthorizedPerson"
                                       formControlName="signatureAuthorizedPerson"
                                       appFileUploader
                                       required
                                       [openElement]="signatureAuthorizedPersonUploadBtn"
                                       [value]="form.controls.signatureAuthorizedPerson.value?.name || form.controls.signatureAuthorizedPerson.value"
                                >
                                <button #signatureAuthorizedPersonUploadBtn type="button"
                                        class="btn btn btn-outline-dark file-upload-btn">
                                    <svg-icon [src]="iconUrls.folder"></svg-icon>
                                    Choose file
                                </button>
                            </div>
                          <div class="invalid-feedback"
                               [class.d-block]="fb.isSubmitted() && !form.get('signatureAuthorizedPerson')?.valid"
                               *ngIf="form.getError('required', 'signatureAuthorizedPerson')">{{fb.getErrorMsg('required')}}</div>
                            <a *ngIf="getFileLink(form.controls.signatureAuthorizedPerson.valueChanges | async) as link"
                               download target="_blank" [href]="link">Download</a>
                        </div>
                    </div>
                </div>

                <div class="mt-4 col-12 text-end">
                    <button type="submit" class="btn btn-primary btn-sm px-4 btn-sm-down-block">Save</button>
                </div>
            </div>
        </form>
    </div>
</div>
