import { MappedBrandInterface } from '../../models/mapped-brand.interface';

export class BrandMappingEntity
{

  private constructor(
    private _id: string,
    private _brandName: string,
    private _brandId: number,
    private _active: boolean,
    private _additional: string | null,
  )
  { }

  get id(): string {
    return this._id;
  }

  get brandName(): string {
    return this._brandName;
  }

  get brandId(): number {
    return this._brandId;
  }

  get active(): boolean {
    return this._active;
  }

  get additional(): string | null {
    return this._additional;
  }

  static build = (obj: MappedBrandInterface): BrandMappingEntity => new BrandMappingEntity(
      obj.id,
      obj.brand_name,
      obj.brand_id,
      obj.active,
      obj.additional,
  );
}
