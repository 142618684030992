import {Injectable} from '@angular/core';
import {FilterQueryBuilder} from '../utils/query-builder/filter-query-builder';
import {Observable} from 'rxjs';
import {PartResponse} from '../../infrastructure/response/part-response';
import {PartRepository} from '../../infrastructure/repositories/part.repository';

@Injectable({
    providedIn: 'root'
})
export class PartService {

    constructor(
        private partRepository: PartRepository,
    ) {
    }

    loadPrices = (queryBuilder: FilterQueryBuilder): Observable<PartResponse[]> => this.partRepository.fetchParts(queryBuilder);
}
