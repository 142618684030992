<div class="pin-control"
     [class.is-invalid]="isInvalid"
     [class.is-valid]="isValid"
>
    <input type="text"
           class="form-control form-control-sm"
           placeholder="Enter Pin code"
           required
           appNumeric
           [minLength]="fb.pinLength"
           [maxLength]="fb.pinLength"
           [ngModel]="value"
           (ngModelChange)="writeValue($event)"
           [class.is-invalid]="isInvalid"
           [class.is-valid]="isValid"
           [readOnly]="addressCheckLoading"
    />
    <app-inline-loader *ngIf="addressCheckLoading"
                       class="pin-control__loading"
    ></app-inline-loader>
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('required', getControlKey('pin'))"
>
    {{fb.getErrorMsg('required')}}
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('minlength', getControlKey('pin'))"
>
    {{fb.getErrorMsg('min', 'pin', fb.pinLength)}}
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('pinInvalid', getControlKey('pin'))"
>
    {{fb.getErrorMsg('pinInvalid')}}
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('pinNotBelongState', getControlKey('pin'))"
>
    {{fb.getErrorMsg('pinNotBelongState')}}
</div>
