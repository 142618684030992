<div *ngIf="hasError()"
     class="invalid-feedback d-block"
>

  <!-- Required -->

  <div *ngIf="control.errors.required">
    This field is required.
  </div>

  <!-- Max number -->

  <div *ngIf="control.errors.max">
<!--    Value should be less than {{control.errors.max.max}}-->
    Maximum value is {{control.errors.max.max}}
  </div>

  <!-- Min number -->

  <div *ngIf="control.errors.min">
<!--    Value should be greater than {{control.errors.min.min}}-->
    Minimum value is {{control.errors.min.min}}
  </div>

  <!-- Min length -->

  <div *ngIf="control.errors.minlength">
    Please enter at least {{ control.errors.minlength.requiredLength }} characters.
  </div>

  <!-- Max length -->

  <div *ngIf="control.errors.maxlength">
    Maximum length is {{ control.errors.maxlength.requiredLength }} characters.
  </div>

  <!-- Addresses -->

  <div *ngIf="control.errors.wrongPin">
    Wrong PIN.
  </div>

  <div *ngIf="control.errors.pinNotBelongState">
    State in PIN and in Address is not the same
  </div>

  <div *ngIf="control.errors.gstPinNotBelongState">
    The PIN should be valid and match the state from your address and GST number
  </div>

  <div *ngIf="control.errors.gstInvalid"
  >
<!--    class="invalid-feedback d-block"-->
    Wrong GST.
  </div>
  <div *ngIf="control.errors.gstNotUnique"
  >
<!--    class="invalid-feedback d-block"-->
    GST number is not unique.
  </div>
  <div *ngIf="control.errors.gstNotBelongState"
  >
<!--    class="invalid-feedback d-block"-->
    GST number does not match the state
  </div>

  <!-- Pattern -->

  <div *ngIf="control.errors.pattern">
    <span *ngIf="isPattern(validators.EMAIL)">
      Please enter a valid email address.
    </span>
    <span *ngIf="isPattern(validators.NUMBER)">
      Please enter only numbers.
    </span>
    <span *ngIf="isPattern(validators.NAME)">
      Please, enter only Latin characters, spaces, ' or - symbols.
    </span>
    <span *ngIf="isPattern(validators.PHONE)">
      Please enter valid phone number.
    </span>
    <span *ngIf="isPattern(validators.ADDRESS)">
      Address is not valid.
    </span>
    <span *ngIf="isPattern(validators.PIN)">
      Please enter valid PIN.
    </span>
    <span *ngIf="isPattern(validators.VIN)">
      Please enter valid VIN.
    </span>
    <span *ngIf="isPattern(validators.GST)">
      Please enter valid GST.
    </span>
    <span *ngIf="isPattern(validators.CITY)">
      Please, use English characters.
    </span>
  </div>
</div>
