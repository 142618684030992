import {SellerInfoResponse} from '../../../infrastructure/response/seller-info.response';
import {Warehouse} from '../warehouse/warehouse.entity';
import {Residence} from '../residence/residence.entity';
import {BillingAddress} from '../../vo/billing-address/billing-address.vo';
import {ShippingAddress} from '../../vo/shipping-address/shipping-address.vo';
import {IntegrationInterface} from '../../models/integration.interface';
import {MerchantAccount} from '../merchant-account/merchant-account.entity';

export class Seller {
  private constructor(
    private _id: string,
    private _name,
    private _email,
    private _status,
    private _enabled,
    private _merchantAccount: MerchantAccount,
    private _warehouses: Warehouse[],
    private _residences: Residence[],
    private _integration: IntegrationInterface[],
  ) {
  }
  get id(): string {
    return this._id;
  }

  get name() {
    return this._name;
  }

  get email() {
    return this._email;
  }

  get status() {
    return this._status;
  }

  get enabled() {
    return this._enabled;
  }

  get merchantAccount(): MerchantAccount {
    return this._merchantAccount;
  }

  get warehouses(): Warehouse[] {
    return this._warehouses;
  }

  get residences(): Residence[] {
    return this._residences;
  }

  get folderPath(): string {
    return `seller/${this.id}`;
  }

  get integration(): IntegrationInterface[] {
    return this._integration;
  }

  static build = (seller: SellerInfoResponse): Seller => new Seller(
      seller.id,
      seller.name,
      seller.email,
      seller.status,
      seller.enabled,
      MerchantAccount.build(seller?.merchant_account),
      seller.warehouses?.map(Warehouse.build),
      seller.residences?.map(({id, name, fulfilment_center, invoice_prefix, dispatch_days, shipping_address, billing_address}) => Residence.build({
        id,
        name,
        fulfilment_center,
        invoice_prefix,
        dispatch_days,
        shippingAddress: ShippingAddress.build(shipping_address),
        billingAddress: BillingAddress.build(billing_address)
      })),
      seller.integration,
  );

}
