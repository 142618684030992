import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {catchError, finalize} from 'rxjs/operators';
import {ImportService} from '../../../services/import.service';
import {BoodmoBrandInterface} from '../../../../domain/models/boodmo-brand.interface';
import {MappedBrandInterface} from '../../../../domain/models/mapped-brand.interface';
import {ImportBatch} from '../../../../domain/entity/import-batch/import-batch.entity';
import {ToastrService} from '../../../services/toastr.service';
import {StatusMessageInterface} from '../../../../domain/models/status-message.interface';
import {throwError} from 'rxjs';

@Component({
  selector: 'app-step2',
  templateUrl: './step2.component.html',
  styleUrls: ['./step2.component.scss']
})
export class Step2Component implements OnInit {
  batch: ImportBatch;
  stepId: number;

  boodmoBrands: BoodmoBrandInterface[];
  fileBrands: MappedBrandInterface[];
  importMapping: MappedBrandInterface[];

  initLoading: Promise<any> | boolean = true;
  loading: any;

  constructor(
    private importService: ImportService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    // after reload page need this.batch from parent component
    const timer = setInterval(() => {
      if (this.batch) {
        this.initLoading = this.importService.prepareMappingDataWithFileBrands(this.batch).then((
          {
            boodmoBrands,
            importMapping,
            fileBrands
          }
        ) => {
          this.initLoading = false;
          this.boodmoBrands = boodmoBrands;
          this.fileBrands = fileBrands;
          this.importMapping = importMapping;
        }, () => {
          this.initLoading = false;
          this.router.navigate([ImportBatch.getLinkByStep(1)]);
        });
        clearInterval(timer);
      }
    }, 400);
  }

  public run(): void {
    this.loading = true;
    this.importService.startFullImport(this.batch.id, true).subscribe(() => {
      this.route.parent.snapshot.data.batch = this.batch
        .validateImport()
        .changeStep(ImportBatch.getStepName(this.stepId + 1));
      this.router.navigate([ImportBatch.getLinkByStep(this.stepId + 1)], {queryParams: {batchID: this.batch.id}});
    });
  }
}
