import { Offer } from '../../../domain/entity/offer/offer.entity';
import { OfferResponse } from '../../../infrastructure/response/offer/offer.response';
import { Warehouse } from '../../../domain/entity/warehouse/warehouse.entity';

export class FCOfferFacade
{
    private constructor(private _offer: Offer)
    {}

    get id(): string
    {
        return this.offer.id;
    }

    get offer(): Offer
    {
        return this._offer;
    }

    get fullName(): string {
        return [this.offer.brand, this.offer.partNumber, this.offer.name].filter((x) => Boolean(x)).join(', ');
    }

    static build = (offer: OfferResponse, warehouse: Warehouse): FCOfferFacade => new FCOfferFacade(
        Offer.build(offer, warehouse),
    );
}
