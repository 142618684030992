import { QueryBuilderInterface } from './query-builder';

export abstract class QueryBuilderCore implements QueryBuilderInterface
{
    protected request: Record<string, unknown> = {};

    protected requestSet = [];

    setRequestSet(requestSet: string[]): void
    {
        this.requestSet = requestSet;
    }

    getRequestObject(): Record<string, unknown>
    {
        this.buildRequestObject();

        return this.request;
    }

    abstract buildRequestObject(): void;
}
