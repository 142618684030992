import { Component } from '@angular/core';
import { ToastrService } from '../../services/toastr.service';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';
import { finalize } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { ImportBatch } from '../../../domain/entity/import-batch/import-batch.entity';
import { ImportService } from '../../services/import.service';
import { CoreService } from '../../services/core.service';
import { CurrentSeller } from '../../../domain/entity/current-seller/current-seller.entity';
import { SellerService } from '../../services/seller.service';

const QUERY_PARAMS_BATCH_ID = 'batchID';
const DEFAULT_STEP = 1;

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent {

  currentSeller: CurrentSeller;
  inlineLoader: any;
  batch: ImportBatch;
  stepId: number;
  batchId: string;
  ImportBatch = ImportBatch;

  stepsMap = {
    step1: {key: 'step1', title: 'Upload File', id: 1},
    step2: {key: 'step2', title: 'Mapping Brands', id: 2},
    step3: {key: 'step3', title: 'Overview', id: 3},
  };

  constructor(
    public coreService: CoreService,
    private importService: ImportService,
    private toastrService: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private titleService: Title,
    public breadcrumbsService: BreadcrumbsService,
    private sellerService: SellerService,
  ) {
    const title = 'Listing - Upload price lists';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
  }

  onActivate(childComponent): void
  {
    this.route.queryParamMap.subscribe((queryParams: ParamMap) => {
      childComponent.currentSeller = this.currentSeller = this.sellerService.currentSeller;

      // get required params
      this.stepId = childComponent.stepId = this.route.snapshot.firstChild.data.stepId;
      this.batchId = queryParams.get(QUERY_PARAMS_BATCH_ID);

      if (QUERY_PARAMS_BATCH_ID in this.route.snapshot.queryParams) {
        if (!this.batch && !this.route.snapshot.data.batch) {

          // get batch
          this.inlineLoader = this.importService.loadBatch(this.batchId).pipe(
            finalize(() => {
              this.inlineLoader = false;
            })
          ).subscribe((batch: ImportBatch) => {
            if (batch.step !== ImportBatch.getStepName(this.stepId)) {

              // go to saved or first step
              this.router.navigate([ImportBatch.getLinkByStep(batch.stepId)],
                {queryParams: {batchID: batch.id}});
              return;
            }
            this.route.snapshot.data.batch = this.batch = childComponent.batch = batch;
          }, error => {
            this.initEmptyImport(childComponent);
            return throwError(error);
          });
        } else {

          // update current batch (change filename & step)
          if (this.route.snapshot.data.batch) {
            this.batch = this.route.snapshot.data.batch;
          }
          childComponent.batch = this.batch;
        }
      } else {

        // no batchID, clear batch & go to first step
        this.initEmptyImport(childComponent);
      }
    });
  }

  checkTab = (stepId: number): boolean => this.stepId > stepId;

  private initEmptyImport = (childComponent): void =>
  {
    delete this.batch;
    delete childComponent.batch;
    setTimeout(() => this.router.navigate([ImportBatch.getLinkByStep(DEFAULT_STEP)]));
  };
}
