import { AbstractForm } from './abstract.form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BrandMappingFacade } from '../facades/brand-mapping/brand-mapping.facade';

export class ProcessMappingForm extends AbstractForm
{

  constructor(mapping?: BrandMappingFacade) {
    super();

    this.form = new UntypedFormGroup({
      brandName: new UntypedFormControl(mapping?.brandName, [Validators.required]),
      boodmoName: new UntypedFormControl(mapping?.boodmoName),
      brandId: new UntypedFormControl(mapping?.brandId, [Validators.required]),
      additional: new UntypedFormControl(mapping?.additional, []),
    });

    if (mapping) {
      this.form.setControl('active', new UntypedFormControl(mapping.active));
    }
  }
}
