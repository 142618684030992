import {SalesItem} from '../../../domain/entity/sales-item/sales-item.entity';
import {SalesItemResponse} from '../../../infrastructure/response/sales-item.response';
import {DateUtil} from '../../utils/date/date.util';

export class NewOrderItemFacade {
  private constructor(
    private _salesItem: SalesItem,
  ) {
  }

  get salesItem(): SalesItem {
    return this._salesItem;
  }

  get id(): string
  {
    return this.salesItem.id;
  }

  get brand(): string {
    return this.salesItem.brand;
  }

  get partNumber(): string {
    return this.salesItem.partNumber;
  }

  get orderNumber(): string {
    return this.salesItem.orderNumber;
  }

  get price(): number {
    return this.salesItem.price;
  }

  get subTotal(): number {
    return this.salesItem.subTotal;
  }

  get tax(): number {
    return this.salesItem.tax;
  }

  get total(): number {
    return this.salesItem.total;
  }

  get confirmationBefore(): Date {
    return DateUtil.addDays(this.salesItem.createdAt, this.salesItem.confirmationDays);
  }

  get qty(): number
  {
    return this.salesItem.qty;
  }

  get packedQty(): number
  {
    return this.salesItem.packedQty;
  }

  static build = (response: SalesItemResponse): NewOrderItemFacade => new NewOrderItemFacade(
      SalesItem.build(response),
  );
}
