import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {
  AbstractForm,
  controlToUpperCase,
  NAME,
  ADDRESS,
  NUMBER,
  normalizePin,
  removeSpaces, CITY, PIN
} from './abstract.form';
import {ResidenceFacade} from '../facades/residence/residence.facade';
import {AbstractAddress} from '../../domain/vo/abstract-address/abstract-address.vo';
import {BillingAddress} from '../../domain/vo/billing-address/billing-address.vo';

export class ProcessResidenceForm extends AbstractForm {

  constructor(public isUpdate: boolean, public facade?: ResidenceFacade) {
    super();

    this.form = new UntypedFormGroup({
      name: new UntypedFormControl(facade?.residence?.name || '', [
        Validators.required as any,
        Validators.minLength(this.minLength) as any,
        Validators.maxLength(this.nameMaxLength) as any,
        Validators.pattern(NAME),
        removeSpaces,
      ]),
      companyName: new UntypedFormControl(facade?.residence.billingAddress.companyName || '', [
        Validators.required as any,
        Validators.minLength(this.nameMinLength) as any,
        Validators.maxLength(this.nameMaxLength) as any,
        Validators.pattern(NAME),
        removeSpaces,
      ]),
      billingAddress: this.createAddressFb(facade?.residence?.billingAddress, true),
      shippingAddress: this.createAddressFb(facade?.residence?.shippingAddress),
    });
  }

  private createAddressFb(addressFb?: AbstractAddress, isBilling?: boolean): UntypedFormGroup
  {
    const groupAddress = {
      addressLine : new UntypedFormControl(addressFb?.addressLine, [
        Validators.required as any,
        Validators.minLength(this.minAddressLength) as any,
        Validators.maxLength(this.maxAddressLength) as any,
        Validators.pattern(ADDRESS),
      ]),
      pin: new UntypedFormControl(addressFb?.pin, [
        Validators.required as any,
        Validators.pattern(PIN) as any,
        Validators.minLength(this.pinLength) as any,
        Validators.maxLength(this.pinLength) as any,
        normalizePin,
      ]),
      state: new UntypedFormControl(addressFb?.state?.abbr || '', [
        Validators.required as any
      ]),
      cityName: new UntypedFormControl(addressFb?.cityName, [
        Validators.required as any,
        Validators.minLength(this.minLength) as any,
        Validators.maxLength(this.cityLength) as any,
        Validators.pattern(CITY),
      ])
    } as Record<string, any>;
    if (isBilling) {
      groupAddress.gstNumber = new UntypedFormControl((addressFb as BillingAddress)?.gstNumber || '', [
        Validators.required as any,
        Validators.pattern(this.gstPattern) as any,
        Validators.maxLength(this.gstLength) as any,
        Validators.minLength(this.gstLength) as any,
        controlToUpperCase
      ]);
    }
    return new UntypedFormGroup(groupAddress);
    // return new FormGroup({
  }
}
