import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-inline-loader',
  templateUrl: './inline-loader.component.html',
  styleUrls: ['./inline-loader.component.scss']
})
export class InlineLoaderComponent {

  /**
   * @loaderType 'circle', 'satellite', 'spin-dash', 'pixel', 'atom'
   */
  @Input() loaderType: 'circle' | 'satellite' | 'spin-dash' | 'pixel' | 'atom' = 'atom';
  @Input() loaderClasses = '';

  getClasses(): string
  {
    return `${this.loaderClasses} ${this.getLoaderClass()}`;
  }

  private getLoaderClass(): string
  {
    return `loader-${this.loaderType}`;
  }

}
