import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'indianCurrency'
})
export class IndianCurrencyPipe implements PipeTransform {

  transform(value: number, args?: configInterface): any
  {
    const {hidePennies, useRupee} = args || {};
    if (!useRupee) {
      value /=100;
    }
    return value.toLocaleString('en-IN', {
      style: 'currency',
      currency: 'INR',
      minimumFractionDigits: hidePennies ? 0 : 2,
      maximumFractionDigits: hidePennies ? 0 : 2
    });

  }
}

interface configInterface {
  hidePennies?: boolean;
  useRupee?: boolean;
}
