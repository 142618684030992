import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PackedShipmentFacade} from '../../../../facades/packed-shipment/packed-shipment.facade';

@Component({
  selector: 'app-packed-item-details',
  templateUrl: './packed-item-details.component.html',
  styleUrls: ['./packed-item-details.component.scss']
})
export class PackedItemDetailsComponent {

  @Input() facade: PackedShipmentFacade ;
  public constructor(public activeModal: NgbActiveModal) {}

}
