import {ShippingAddress} from '../../../domain/vo/shipping-address/shipping-address.vo';
import {SalesItem} from '../../../domain/entity/sales-item/sales-item.entity';

export class ProcessingOrderFacade {
  toggled: boolean;

  private constructor(
    private _id: string,
    private _createdAt: Date,
    private _orderNumber: string,
    private _customerInfo: ShippingAddress,
    private _qty: number,
    private _subTotal: number,
    private _tax: number,
    private _total: number,
    private _items: SalesItem[]
  ) {
  }

  get id(): string {
    return this._id;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get orderNumber(): string {
    return this._orderNumber;
  }

  get customerInfo(): ShippingAddress {
    return this._customerInfo;
  }

  get shipBy(): Date{
    return new Date(); // TODO add real value
  }

  get qty(): number {
    return this._qty;
  }

  get subTotal(): number {
    return this._subTotal;
  }

  get tax(): number {
    return this._tax;
  }

  get total(): number {
    return this._total;
  }

  get items(): SalesItem[] {
    return this._items;
  }

  get canPack(): boolean {
    return this.items.some((item) => item.canPack);
  }

  get canCancel(): boolean {
    return this.items.every((item) => item.status === SalesItem.STATUS_PROCESSING);
  }

  static build = (order: {
    orderId: string;
    createdAt: Date;
    orderNumber: string;
    address: ShippingAddress;
    qty: number;
    subTotal: number;
    tax: number;
    total: number;
    items: SalesItem[];
  }): ProcessingOrderFacade => new ProcessingOrderFacade(
      order.orderId,
      order.createdAt,
      order.orderNumber,
      order.address,
      order.qty,
      order.subTotal,
      order.tax,
      order.total,
      order.items
  );

  blockMassCancel(salesItems: SalesItem[]): boolean {
    return salesItems.some((item) => item.status === SalesItem.STATUS_CANCEL_REQUESTED);
  }
}
