<div class="modal-header">
    <h5 class="modal-title">Import details</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="importItem">
    <p class="row">
        <b class="col-5">Number:</b>
        <span class="col-7">{{ importItem.batchNumber }}</span>
    </p>
    <p class="row">
        <b class="col-5">Filename:</b>
        <span class="col-7">{{ importItem.filename }}</span>
    </p>
    <p class="row">
        <b class="col-5">Step:</b>
        <span class="col-7">{{ importItem.step }}</span>
    </p>
    <p class="row">
        <b class="col-5">Error Filename:</b>
        <span class="col-7">{{ importItem.errorFilename }}</span>
    </p>
    <p class="row">
        <b class="col-5">Created at:</b>
        <span class="col-7">{{ importItem.createdAt | bdate:'dateTime' }}</span>
    </p>
</div>
<div class="modal-footer btn-modal-wrapp">
    <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
