import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractRepository} from './abstract.repository';
import {SalesItemResponse} from '../response/sales-item.response';
import {StatusMessageInterface} from '../../domain/models/status-message.interface';
import {SalesItemConfirmRequest} from '../requests/sales-item-confirm.request';

@Injectable({
  providedIn: 'root'
})
export class SalesItemRepository extends AbstractRepository {
  fetchNewOrderList = (warehouseId: string): Observable<{data: SalesItemResponse[]}> =>
    this.httpClient.get<{data: SalesItemResponse[]}>(this.buildUrl(`sales/sales-item/new-items/${warehouseId}`));

  fetchProcessingOrderList = (warehouseId: string): Observable<{data: SalesItemResponse[]}> =>
    this.httpClient.get<{data: SalesItemResponse[]}>(this.buildUrl(`sales/sales-item/processing-items/${warehouseId}`));

  confirm = (items: SalesItemConfirmRequest[]): Observable<StatusMessageInterface> =>
    this.httpClient.post<StatusMessageInterface>(this.buildUrl('sales/sales-item/confirm'), {items});

  cancel = (items: string[]): Observable<StatusMessageInterface> =>
    this.httpClient.post<StatusMessageInterface>(this.buildUrl('sales/sales-item/mass-cancel'), {items});

  cancelApprove = (sales_item_id: string, qty: number): Observable<StatusMessageInterface> =>
    this.httpClient.post<StatusMessageInterface>(this.buildUrl('sales/sales-item/cancel-approve'), {
      sales_item_id,
      qty
    });
}
