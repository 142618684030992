import { AddressInterface } from '../../models/address.interface';
import {StateInterface} from '../../models/state.interface';

export class AbstractAddress {
  protected constructor(
    private _contactFirstName: string,
    private _contactLastName: string,
    private _contactPhone: string,
    private _pin: number,
    private _cityName: string,
    private _addressLine: string,
    private _state: StateInterface,
    private _title?: string,
  ) {
  }

  get convertAddressToLine(): string {
    return [
      this.title,
      this.addressLine,
      this.state?.name,
      this.cityName,
      String(this.pin),
    ].filter((i) => i?.length).join(',<br/>');
  }

  get contactFirstName(): string {
    return this._contactFirstName;
  }

  get contactLastName(): string {
    return this._contactLastName;
  }

  get contactPhone(): string {
    return this._contactPhone;
  }

  get pin(): number {
    return this._pin;
  }

  get cityName(): string {
    return this._cityName;
  }

  get addressLine(): string {
    return this._addressLine;
  }

  get state(): StateInterface {
    return this._state;
  }

  get title(): string {
    return this._title;
  }

  static build = (address: AddressInterface): AbstractAddress => new AbstractAddress(
      address.contactFirstName,
      address.contactLastName,
      address.contactPhone,
      address.pin,
      address.cityName,
      address.addressLine,
      address.state,
      address.title,
  );
}
