import {ShipmentBoxItemFacade} from './shipment-box-item.facade';

export class ShipmentBoxFacade {
  private constructor(
    private _boxId: string,
    private _number: string,
    private _qty: number,
    private _items: ShipmentBoxItemFacade[],
  ) {
  }

  get id(): string {
    return this._boxId;
  }

  get number(): string {
    return this._number;
  }

  get qty(): number {
    return this._qty;
  }

  get items(): ShipmentBoxItemFacade[] {
    return this._items;
  }

  static build = (
      {
        box_id,
        number,
        qty,
        items
      }) => new ShipmentBoxFacade(
      box_id,
      number,
      qty,
      items.map(ShipmentBoxItemFacade.build),
  );
}
