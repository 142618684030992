import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AbstractRepository } from './abstract.repository';
import {CreateStatusMessageInterface} from '../../domain/models/create-status-message.interface';
import {AddressInterface} from '../../domain/models/address.interface';
import {AddressRequest} from '../requests/address.request';

@Injectable({
  providedIn: 'root',
})
export class ResidenceRepository extends AbstractRepository {
  create = (name: string, billingAddress: AddressRequest, shippingAddress: AddressRequest): Observable<CreateStatusMessageInterface> =>
    this.httpClient.post<CreateStatusMessageInterface>(this.buildUrl(`srm/residence`), {
      name,
      billing_address: {
        contact_first_name: '',
        contact_last_name: '',
        contact_phone: '',
        pin: +billingAddress.pin,
        city_name: billingAddress.cityName,
        address_line: billingAddress.addressLine,
        state: billingAddress.state,
        gst_number: billingAddress.gstNumber,
        company_name: billingAddress.companyName
      },
      shipping_address: {
        contact_first_name: '',
        contact_last_name: '',
        contact_phone: '',
        pin: +shippingAddress.pin,
        city_name: shippingAddress.cityName,
        address_line: shippingAddress.addressLine,
        state: shippingAddress.state,
      }
    });

  update = (id: string, name: string, billingAddress: AddressRequest, shippingAddress: AddressRequest): Observable<CreateStatusMessageInterface> =>
    this.httpClient.put<CreateStatusMessageInterface>(this.buildUrl(`srm/residence/update/${id}`), {
      name,
      billing_address: {
        title: billingAddress.title || null,
        contact_first_name: billingAddress.contactFirstName || '',
        contact_last_name: billingAddress.contactLastName || '',
        contact_phone: billingAddress.contactPhone || '',
        pin: +billingAddress.pin,
        city_name: billingAddress.cityName,
        address_line: billingAddress.addressLine,
        state: billingAddress.state,
        gst_number: billingAddress.gstNumber,
        company_name: billingAddress.companyName
      },
      shipping_address: {
        title: shippingAddress.title || null,
        contact_first_name: shippingAddress.contactFirstName || '',
        contact_last_name: shippingAddress.contactLastName || '',
        contact_phone: shippingAddress.contactPhone || '',
        pin: +shippingAddress.pin,
        city_name: shippingAddress.cityName,
        address_line: shippingAddress.addressLine,
        state: shippingAddress.state,
      }
    });
}
