import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {AbstractForm} from './abstract.form';
import {MerchantAccount} from '../../domain/entity/merchant-account/merchant-account.entity';

export class BankDetailsForm extends AbstractForm {

    constructor(private merchantAccount: MerchantAccount) {
        super();

        this.form = new UntypedFormGroup({
            name: new UntypedFormControl(this.merchantAccount.bankName, [
                Validators.required,
            ]),
            ifsc: new UntypedFormControl(this.merchantAccount.bankIfsc, [
                Validators.required,
            ]),
            accountNumber: new UntypedFormControl(this.merchantAccount.bankAccountNumber, [
                Validators.required,
            ]),
            branchName: new UntypedFormControl(this.merchantAccount.bankBranchName, [
                Validators.required,
            ]),
            cancelledCheque: new UntypedFormControl(null, [
                Validators.required,
            ]),
            panCard: new UntypedFormControl(null, [
                Validators.required,
            ]),
            gstRegistrationCertificate: new UntypedFormControl(null, [
                Validators.required,
            ]),
            signatureAuthorizedPerson: new UntypedFormControl(null, [
                Validators.required,
            ]),
        });
    }

    setFiles() {
        this.form.patchValue({
            cancelledCheque: this.merchantAccount.cancelledCheque,
            panCard: this.merchantAccount.panCard,
            gstRegistrationCertificate: this.merchantAccount.gstCert,
            signatureAuthorizedPerson: this.merchantAccount.sellerSignature,
        }, {emitEvent: true});
    }
}
