import {Component} from '@angular/core';
import {ImportBatch} from '../../../../domain/entity/import-batch/import-batch.entity';
import {CoreService} from '../../../services/core.service';
import {CurrentSeller} from '../../../../domain/entity/current-seller/current-seller.entity';

@Component({
  selector: 'app-step3',
  templateUrl: './step3.component.html',
  styleUrls: ['./step3.component.scss'],
})
export class Step3Component {
  currentSeller: CurrentSeller;
  loading: any;
  batch: ImportBatch;
  stepId: number;

  constructor(
    public coreService: CoreService,
  ) {
  }
}
