import {DateUtil} from '../../../application/utils/date/date.util';

export class InvoiceInfo {
  private constructor(
    private _number?: string,
    private _date?: Date,
    private _value?: number
  ) {
  }

  get number(): string {
    return this._number;
  }

  get date(): Date {
    return this._date;
  }

  get value(): number {
    return this._value;
  }

  static build = ({invoice_number, invoice_date, invoice_value}) => new InvoiceInfo(
      invoice_number,
      DateUtil.convertTimestampToDate(invoice_date),
      invoice_value,
  );
}
