import {AbstractControl, UntypedFormGroup, ValidatorFn} from '@angular/forms';

export const EMAIL = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
export const NAME = /^[a-zA-Z-'\s]+$/;
export const NUMBER = /^[0-9]*$/;
export const PHONE = /^[0-9]{10}$/;
export const ADDRESS = /^[a-zA-Z0-9#&+()"',.;:\-/\s]*$/;
export const PIN = /^[0-9]{6}$/;
export const VIN = /^[A-HJ-NPR-Z0-9]{17}$/;
export const GST = /^[A-Z0-9]+$/;
export const CITY = /^[a-zA-Z0-9()"',.;:\-/\s]+$/;

export class AbstractForm
{
    moneyFormat = '1.23';
    minLength = 2;
    maxLength = 255;
    nameMinLength = 3;
    nameMaxLength = 100;
    phoneLength = 10;
    minAddressLength = 10;
    maxAddressLength = 110;
    minCompanyNameLength = 3;
    maxCompanyNameLength = 150;
    pinLength = 6;
    cityLength = 50;
    gstLength = 15;
    minEmail = 5;
    maxEmail = 50;
    intPattern = '^[0-9]*$';
    gstPattern = '^[A-Z0-9]{15}$';
    // gstPattern = '^[a-zA-Z0-9_.]{15}$';
    namePattern= /^[a-zA-Z-'\s]+$/;

    errorMsg = {
        general: 'Please enter a valid %path%!',
        required: 'This field is required!',
        email: 'Please enter a valid email address.',
        min: 'Minimum required %path% is %value%!',
        max: 'Maximum %path% can be %value%!',
        minLength: 'The %path% should be at least %value% characters!',
        maxLength: 'The %path% should be no more than %value% characters!',
        length: 'The %path% should consist of %value% numbers',
        pattern: 'The %path% must be in the correct format, like: "%value%"',
        pinInvalid: 'Wrong PIN.',
        gstPattern: 'Please enter valid GST. Please enter at least 15 characters',
        gstInvalid: 'Wrong GST.',
        gstNotUnique: 'GST number is not unique.',
        gstNotBelongState: 'GST number does not match the state.',
        pinNotBelongState: 'The PIN should be valid and match the state from your address and GST number',
        namePattern: 'Please, enter only Latin characters, spaces, \' or - symbols.',
        intPattern: 'The %path% must be integer numbers',
        // gstPattern: 'Please enter at least 15 characters.',
    };

    protected form: UntypedFormGroup;
    protected submitted: boolean;

    getForm = (): UntypedFormGroup => this.form;

    getPattern = (
        pattern: 'EMAIL' | 'NAME' | 'NUMBER' | 'PHONE' | 'ADDRESS' | 'PIN' | 'VIN' | 'GST' | 'CITY' | RegExp
    ): RegExp | string=> {
        switch (pattern) {
            case 'CITY':
                return CITY;
                // return String(CITY);
            case 'ADDRESS':
                return ADDRESS;
                // return String(ADDRESS);
            case 'EMAIL':
                return EMAIL;
                // return String(EMAIL);
            case 'GST':
                return GST;
                // return String(GST);
            case 'NAME':
                // return NAME;
                return String(NAME).replace(/\//g, '');
            case 'NUMBER':
                return NUMBER;
                // return String(NUMBER);
            case 'PHONE':
                return PHONE;
                // return String(PHONE);
            case 'PIN':
                return PIN;
                // return String(PIN);
            case 'VIN':
                return VIN;
                // return String(VIN);
            default:
                return pattern;
                // return String(pattern);
        }
    };

    markSubmitted = (): void => {
        this.submitted = true;
    };

    clearSubmitted = (): void => {
        this.submitted = false;
    };

    getErrorMsg = (field: string, path?: string, value?: number | string): string => {
        let result = this.errorMsg[field];
        if (value !== undefined) {
            result = result.replace(/%value%/, String(value));
        }
        if (path !== undefined) {
            result = result.replace(/%path%/, path);
        }
        return result;
    };

    isSubmitted = (): boolean => this.submitted;

    getMoneyValue(valueKey: string): number
    {
        if (!this.form.value[valueKey]) {
            throw new Error('Value key: ' + valueKey + ' doesn\'t present in form');
        }

        return this.form.value[valueKey] * 100;
    }
}

export const controlToUpperCase: ValidatorFn = (control: AbstractControl) => {
    if (control && control.value && /[a-z]/.test(control.value)) {
        control.setValue(control.value.toUpperCase());
    }

    return null;
};

export const removeSpaces: ValidatorFn = (control: AbstractControl) => {
    if (control && control.value) {
        if (!control.value.replace(/\s/g, '').length) {
            control.setValue('');
        }

        if (control.value.endsWith('  ')) {
            control.setValue(control.value.replace('  ', ' '));
        }
    }

    return null;
};

export const normalizePin: ValidatorFn = (control: AbstractControl) => {
    if (control?.value) {
        if (!String(control.value).replace(/^0+/, '').length) {
            control.setValue('');
        }
    }

    return null;
};
