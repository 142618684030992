<div class="modal-header">
    <h5 class="modal-title">Link Offer</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    <form novalidate #form="ngForm"
          [ngClass]="{'was-validated' : form.submitted}"
          class="form-inline d-block d-sm-flex mb-2 justify-content-between"
          (ngSubmit)="findParts(form)">
        <div class="d-sm-flex">
            <div class="d-sm-flex">
                <label for="partNumber" class="form-label me-2">Part Number*:</label>
                <!--        <div class="me-2">-->
                <input type="text"
                       [appAutofocus]="true"
                       id="partNumber"
                       name="partNumber"
                       required
                       class="form-control form-control-sm me-2"
                       [(ngModel)]="partNumber"
                >
                <!--        </div>-->

                <div class="invalid-feedback me-2 w-auto" *ngIf="form.getError('required', 'partNumber')"> This field is
                    required!
                </div>

            </div>
            <div class="d-sm-flex align-items-center">
                <input type="checkbox"
                       id="altNumber"
                       name="altNumber"
                       class="form-check-input"
                       [(ngModel)]="needAltNumber"
                >
                <label class="form-check-label ms-2" for="altNumber">
                    Add {{offerFacade.offer.partNumber}} as an alternative to the linked product
                </label>

            </div>
        </div>
        <div class="btn-modal-wrapp">
            <button type="submit" class="btn btn-success btn-modal mt-2 mt-sm-0">Submit</button>
        </div>
    </form>

    <div class="table-wrapper overflow-auto">
        <app-loader *ngIf="loader"></app-loader>
        <table class="table table-bordered table-hover">
            <thead>
            <tr>
                <th>
                    <app-th [colKey]="'brandName'"
                            [config]="cols.brandName"
                            [queryBuilder]="queryBuilder"
                            [name]="'Brand'"
                            [placeholder]="'Brand'"
                            (sort)="sort($event)"
                            (update)="findParts(form)"
                    ></app-th>
                </th>
                <th>
                    <app-th [colKey]="'number'"
                            [config]="cols.number"
                            [name]="'Part Number'"
                            [placeholder]="'Part Number'"
                            (update)="findParts(form)"
                            [queryBuilder]="queryBuilder"
                            (sort)="sort($event)"
                    ></app-th></th>
                <th>
                    <app-th [colKey]="'name'"
                            [config]="cols.name"
                            [name]="'Name'"
                            [placeholder]="'Name'"
                            (update)="findParts(form)"
                            [queryBuilder]="queryBuilder"
                            (sort)="sort($event)"
                    ></app-th></th>
                <th class="d-none d-sm-table-cell">
                    <app-th [colKey]="'className'"
                            [config]="cols.className"
                            [name]="'Class'"
                            [placeholder]="'Class'"
                            (update)="findParts(form)"
                            [queryBuilder]="queryBuilder"
                            (sort)="sort($event)"
                    ></app-th></th>
                <th class="p-0"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let part of parts; let i = index">
                <td>{{ part.brandName }}</td>
                <td>{{ part.number }}</td>
                <td>{{ part.name }}</td>
                <td class="d-none d-sm-table-cell">{{ part.className }}</td>
                <td style="width:30px" class="position-relative row-toggler-wrap--right p-0">
                    <div class="row-toggler w-100 row-toggler--primary"
                         (click)="link($event, part)">
                        <svg-icon [src]="iconUrls.link"></svg-icon>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        <div *ngIf="!parts?.length" class="table-info {{loader ? 'loading' : 'no-data'}}"></div>
    </div>

</div>

<div class="modal-footer btn-modal-wrapp justify-content-between">

    <div class="btn-modal btn-group ">
        <button class="btn"
                [ngClass]="option === queryBuilder.getLimit() ? 'btn-secondary' : 'btn-outline-secondary'"
                *ngFor="let option of [100, 500, 1000, 5000]"
                (click)="queryBuilder.changeLimit(option);findParts(form)"
        >
            {{option}}
        </button>
    </div>

    <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
