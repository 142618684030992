import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { CurrentSeller } from '../domain/entity/current-seller/current-seller.entity';
import { AuthService } from './services/auth.service';
import {SellerService} from './services/seller.service';

@Injectable({
  providedIn: 'root',
})

export class CurrentResolver  {
  constructor(
    private authService: AuthService,
    private sellerService: SellerService,
  ) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<CurrentSeller> {
    return this.authService.currentUser().pipe(
      map((response: any) => {
        const currentSeller = response instanceof CurrentSeller ? response : CurrentSeller.build(response);
        this.sellerService.currentSeller = currentSeller;
        this.sellerService.$currentSeller.next(currentSeller);
        return currentSeller;
      }),
      catchError((error: any) => {
        this.authService.logout();

        return EMPTY;
      })
    );
  }
}
