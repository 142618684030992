import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {finalize} from 'rxjs/operators';
import {ImportService} from '../../../services/import.service';
import {ImportBatch} from '../../../../domain/entity/import-batch/import-batch.entity';
import {ToastrService} from '../../../services/toastr.service';
import {StatusMessageInterface} from '../../../../domain/models/status-message.interface';
import {FileImportForm} from '../../../forms/file-import.form';
import {UploadFileResponse} from '../../../../infrastructure/response/upload-file.response';
import {CoreService} from '../../../services/core.service';
import {CurrentSeller} from '../../../../domain/entity/current-seller/current-seller.entity';
import {CreateStatusMessageInterface} from '../../../../domain/models/create-status-message.interface';
import {Subscription} from 'rxjs';
import {WarehouseService} from '../../../services/warehouse.service';
import {ExportExcelService} from '../../../services/export-excel.service';
import {Warehouse} from '../../../../domain/entity/warehouse/warehouse.entity';

@Component({
    selector: 'app-step1',
    templateUrl: './step1.component.html',
    styleUrls: ['./step1.component.scss']
})
export class Step1Component implements OnInit, OnDestroy {
    loader: any;
    currentSeller: CurrentSeller;
    warehouses: Warehouse[];
    batch: ImportBatch;
    stepId: number;

    fb: FileImportForm;
    form: UntypedFormGroup;
    file: File;
    isCsv = false;

    private uploadResponse: UploadFileResponse;
    private holdUpload = false;
    private warehouseSelectedSubscription: Subscription;
    private passedWarehouse: string;

    constructor(
        private coreService: CoreService,
        private importService: ImportService,
        private toastrService: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private cd: ChangeDetectorRef,
        private warehouseService: WarehouseService,
        private exportExcelService: ExportExcelService,
    ) {
        this.fb = new FileImportForm(this.passedWarehouse);
        this.form = this.fb.getForm();
        this.warehouseSelectedSubscription = this.warehouseService.warehouseChanged$.subscribe(value => {
            this.passedWarehouse = value;
            this.form?.controls.warehouse.patchValue(this.passedWarehouse);
        });
    }

    get disabledUpload(): boolean {
        // TODO check batch
        return this.holdUpload;
        // && this.batch && typeof this.batch.isFullConfigured === undefined;
    }

    get importType(): 'vendor' | 'warehouse' {
        const warehouse: Warehouse = this.currentSeller?.warehouses.find((w) => w.id === this.form.value.warehouse);
        return !warehouse ? null : warehouse?.fulfilledStock ? 'warehouse' : 'vendor';
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.warehouses = this.currentSeller?.warehouses.sort((a, b) => a.name > b.name ? 1 : -1);
        });
    }

    ngOnDestroy(): void {
        this.warehouseSelectedSubscription?.unsubscribe();
    }

    onFileChange(event): void {
        this.form.markAllAsTouched();
        const reader = new FileReader();

        if (event.target.files && event.target.files.length) {
            const [file] = event.target.files;
            this.file = file;
            this.isCsv = /.csv/.test(file.name);
            this.fb.updateDelimiterValidationControl(this.isCsv);
            this.holdUpload = true;
            reader.readAsDataURL(file);
            this.form.patchValue({
                filename: 'filename'
            });
            this.cd.markForCheck();

            reader.onload = (progressEvent: ProgressEvent) => {
                // prepare response.result
                this.holdUpload = false;
            };
        } else {
            this.file = null;
            this.form.patchValue({
                filename: null
            });
        }
    }

    upload(): void {
        this.fb.markSubmitted();

        // 1. check form values
        if (this.form.invalid) {
            return;
        }

        // 2. if file, upload to server
        // TODO check batch
        this.loader = this.coreService.uploadFile(this.file, ImportBatch.folderPath(this.currentSeller.id, this.form.value.warehouse)).pipe(
            finalize(() => {
                this.loader = false;
            })
        ).subscribe((response: UploadFileResponse) => {
            this.uploadResponse = response;

            // 3. if 1. & 2. - success, add path from response (from 2.) as form.filename value
            // this.form.patchValue({
            //     filename: response.filename
            // });

            // need to run CD since file load runs outside of zone
            // this.cd.markForCheck();

            // 4. process batch
            if (this.uploadResponse && this.uploadResponse?.filename && this.uploadResponse?.original_filename) {
                this.processBatch(this.uploadResponse.filename, this.uploadResponse.original_filename);
            }
        });
    }

    saveSample = (type: 'vendor' | 'warehouse') => {
        const importSample = [{
            number: '79756',
            brand: 'MAHINDRA',
            price: 338,
            purchase_price: 204.48,
            mrp: 355,
            qty: 1,
            name: 'REAR SPRING SHACKLE BUSHING',
            shippingDiscount: 1,
            serviceCharge: 10,
            priceTier1: 290,
            shippingDiscountTier1: 10,
            serviceChargeTier1: 15,
            priceTier2: 280,
            shippingDiscountTier2: 15,
            serviceChargeTier2: 20,
            priceTier3: 270,
            shippingDiscountTier3: 20,
            serviceChargeTier3: 25,
            dispatch_days: 9
        }, {
            number: '1703AAA04131N',
            brand: 'MAHINDRA',
            price: 380,
            purchase_price: 230.4,
            mrp: 400,
            qty: 1,
            name: 'HIGH MOUNTED STOP LAMP',
            shippingDiscount: 1,
            serviceCharge: 10,
            priceTier1: 290,
            shippingDiscountTier1: 10,
            serviceChargeTier1: 15,
            priceTier2: 280,
            shippingDiscountTier2: 15,
            serviceChargeTier2: 20,
            priceTier3: 270,
            shippingDiscountTier3: 20,
            serviceChargeTier3: 25,
        }];
        if (type === 'warehouse') {
            this.exportExcelService.exportAsExcelFile(importSample, 'import-sample');
            return;
        }
        const importSampleFC = importSample.map(({ qty, purchase_price, ...fsImportObj}) => fsImportObj);
        this.exportExcelService.exportAsExcelFile(importSampleFC, 'import-sample-fc');
    };

    private processBatch(filename, originalFilename): void {
        const {warehouse, delimiter, flushOffers} = this.form.value;
        const action = this.batch?.isFullConfigured
            ? this.importService.updateBatch(
                warehouse,
                this.batch.id,
                filename,
                originalFilename,
                ImportBatch.getStepName(this.stepId + 1),
                this.importType,
                delimiter
            )
            : this.importService.createBatch(
                warehouse,
                filename,
                originalFilename,
                this.importType,
                flushOffers,
                delimiter
            );
        this.loader = action.pipe(
            finalize(() => {
                this.loader = false;
            })
        ).subscribe((resp: StatusMessageInterface | CreateStatusMessageInterface) => {
            if (!this.batch && 'id' in resp) {
                this.batch = ImportBatch.build({
                    warehouse,
                    id: resp.id,
                    step: ImportBatch.getStepName(1),
                    status: ImportBatch.STATUS_NEW,
                    filename: this.uploadResponse.filename,
                    original_filename: this.uploadResponse.original_filename,
                    valid_filename: null,
                    error_filename: null,
                });
            }
            this.route.parent.snapshot.data.batch = this.batch
                .changeFilename(filename, originalFilename)
                .validateImport()
                .changeStep(ImportBatch.getStepName(this.stepId + 1));
            this.router.navigate([ImportBatch.getLinkByStep(this.stepId + 1)], {queryParams: {batchID: this.batch.id}});
        });
    }
}
