import {Component, Input} from '@angular/core';
import {ImportService} from '../../../../services/import.service';
import {MappedBrandInterface} from '../../../../../domain/models/mapped-brand.interface';
import {BoodmoBrandInterface} from '../../../../../domain/models/boodmo-brand.interface';
import {StatusMessageInterface} from '../../../../../domain/models/status-message.interface';
import {CreateStatusMessageInterface} from '../../../../../domain/models/create-status-message.interface';


@Component({
  selector: 'app-mapping',
  templateUrl: './mapping.component.html',
  styleUrls: ['./mapping.component.scss']
})
export class MappingComponent {
  @Input() title: string;
  @Input() brands: MappedBrandInterface[];
  @Input() boodmoBrands: BoodmoBrandInterface[];
  @Input() tableTitleFirst = 'Brand names in file';
  @Input() tableTitleSecond = 'Brand name in boodmo.com';

  constructor(private importService: ImportService) {
  }

  get show(): boolean {
    return Boolean(this.brands && this.brands.length);
  }

  identify = (index, item: any): any => item;

  saveMapping(brand: MappedBrandInterface, value: BoodmoBrandInterface): void {
    if (brand.brand_id === value.id) {
      // choose previous boodmoBrand
      return;
    }

    if (value) {
      brand.brand_id = value.id;
    }

    const action = brand.id
      ? this.importService.updateMapping(brand.brand_id, brand.id, true)
      : this.importService.createMapping(brand.brand_name, brand.brand_id);

    brand.loading = action.toPromise().then((response: StatusMessageInterface | CreateStatusMessageInterface) => {
      if ('id' in response) {
        brand.id = response.id;
      }
      brand.mapped_brand = value.name;
      brand.loading = false;
      brand.saved = true;
      brand.fail = false;
    }, err => {
      brand.loading = false;
      brand.saved = false;
      brand.fail = true;
      brand.brand_id = null;
    });
  }
}
