<div class="card shadow">
    <div class="card-body p-0" style="position: relative;">
        <div class="table-wrapper overflow-auto">
            <app-loader *ngIf="gridLoader"></app-loader>

            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>
                        <app-th [colKey]="'batch_number'"
                                [config]="cols.batchNumber"
                                [queryBuilder]="queryBuilder"
                                [name]="'Number'"
                                [placeholder]="'Number'"
                                (sort)="sort($event)"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th>
                        <app-th [colKey]="'original_filename'"
                                [config]="cols.filename"
                                [queryBuilder]="queryBuilder"
                                [name]="'Filename'"
                                [placeholder]="'Filename'"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th>Status</th>
                    <th>Step</th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'warehouse'"
                                [config]="cols.warehouse"
                                [queryBuilder]="queryBuilder"
                                [options]="warehouseList"
                                [name]="'Warehouse'"
                                [showAllOption]="true"
                                (update)="applyFilters()"
                                [inputWidth]="'120px'"
                        ></app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">Error Filename</th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'created'"
                                [config]="cols.createdAt"
                                [queryBuilder]="queryBuilder"
                                [name]="'Created at'"
                                [dateWithTime]="true"
                                (sort)="sort($event)"
                                (update)="applyFilters()"
                                [dropdownPosition]="'bottom-right'"
                        ></app-th>
                    </th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let importItem of imports | paginate: { itemsPerPage: queryBuilder.getLimit(), currentPage: queryBuilder.getPage(), totalItems: total }; let i = index">
                    <tr (click)="openDetail(importItem.batch)">
                        <td>
                          <a [routerLink]="'/products/import'" [queryParams]="{batchID: importItem.id}">{{ importItem.batchNumber }}</a>
                        </td>
                        <td class="">
                          <a href="javascript:void(0);"
                             (click)="coreService.downloadByPath(ImportBatch.folderPath(currentSeller.id, importItem.warehouse) + '/' + importItem.filename)">{{ importItem.originalFilename }}</a>
                        </td>
                        <td>
                            <span [ngClass]="importItem.statusBadge">{{ importItem.status }}</span>
                        </td>
                        <td>
                            {{ importItem.step }}
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <a href="javascript:void(0);"
                               (click)="goToPriceList(importItem.warehouse)">
                                {{ importItem.warehouseName }}
                            </a>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <a href="javascript:void(0);"
                               (click)="coreService.downloadByPath(ImportBatch.folderPath(currentSeller.id, importItem.warehouse) + '/' + importItem.errorFilename)">{{ importItem.errorFilename }}</a>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            {{ importItem.createdAt | bdate:'dateTime' }}
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>

        <app-tfoot [queryBuilder]="queryBuilder"
                   [dataList]="imports"
                   [loader]="gridLoader"
                   (changePageNum)="onChangePagination($event)"
                   [total]="total"
        ></app-tfoot>

    </div>
</div>
