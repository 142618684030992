import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {ShipmentRepository} from '../../infrastructure/repositories/shipment.repository';
import {PackedShipmentFacade} from '../facades/packed-shipment/packed-shipment.facade';
import {Shipment} from '../../domain/entity/shipment/shipment.entity';
import {BoxDimensionsInterface} from '../../domain/models/box-dimensions.interface';
import {CreateStatusMessageInterface} from '../../domain/models/create-status-message.interface';
import {ShipmentInfoFacade} from '../facades/shipment-info/shipment-info.facade';

@Injectable({
  providedIn: 'root'
})
export class ShipmentService {

  constructor(public shipmentRepository: ShipmentRepository) {
  }

  createBox = (residenceId: string, boxDimensions: BoxDimensionsInterface, qtyMap: Record<string, number>): Observable<CreateStatusMessageInterface> => {
    const items = [];
    for (const item_id in qtyMap) {
      if (item_id in qtyMap) {
        items.push({
          item_id,
          qty: qtyMap[item_id]
        });
      }
    }
    return this.shipmentRepository.createBox(residenceId, boxDimensions, items);
  };

  fetchPackedBoxList = (): Observable<PackedShipmentFacade[]> =>
    this.shipmentRepository.fetchPackedBoxList().pipe(
      map((response) => response?.data?.map((item) => {
        const shipment: Shipment = Shipment.build(item);
        return PackedShipmentFacade.build(shipment, item.box_count);
      })),
    );

  fetchShipmentInfo = (shipmentId: string): Observable<ShipmentInfoFacade> => this.shipmentRepository.fetchShipmentInfo(shipmentId).pipe(
    map(({ result}) => ShipmentInfoFacade.build(result)
    )
  );
}
