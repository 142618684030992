import {Injectable} from '@angular/core';
import {Observable, of, throwError} from 'rxjs';

import {AbstractRepository} from './abstract.repository';
import {FilterQueryBuilder} from '../../application/utils/query-builder/filter-query-builder';
import {StatusMessageInterface} from '../../domain/models/status-message.interface';
import {LinkImportResponse} from '../response/link-import.response';
import {ImportBatch} from '../../domain/entity/import-batch/import-batch.entity';
import {FetchImportResponse} from '../response/fetch-import.response';
import {CreateStatusMessageInterface} from '../../domain/models/create-status-message.interface';


@Injectable({
    providedIn: 'root',
})

export class ImportRepository extends AbstractRepository {
    fetch = (queryBuilder: FilterQueryBuilder): Observable<FetchImportResponse> =>
        this.httpClient.post<FetchImportResponse>(this.buildUrl(`srm/import-batch/grid`), queryBuilder.getRequestObject(),
            {headers: this.httpHeaders});

    loadBatch = (id: string): Observable<ImportBatch> => this.httpClient.get<ImportBatch>(this.buildUrl(`srm/import-batch/${id}`), {headers: this.httpHeaders});

    createBatch = (
        warehouse: string,
        filename: string,
        originalFilename: string,
        type: 'vendor' | 'warehouse',
        flushOffers: boolean,
        delimiter?: string
    ): Observable<CreateStatusMessageInterface> =>
        this.httpClient.post<CreateStatusMessageInterface>(this.buildUrl(`srm/import-batch`), {
            warehouse,
            filename,
            type,
            original_filename: originalFilename,
            settings: {delimiter},
            flush_offers: flushOffers
        }, {headers: this.httpHeaders});

    updateBatch = (
        warehouse: string,
        id: string,
        filename: string,
        originalFilename: string,
        step: string,
        type: 'vendor' | 'warehouse',
        delimiter?: string,
    ): Observable<StatusMessageInterface> => this.httpClient.put<StatusMessageInterface>(this.buildUrl(`srm/import-batch/${id}/update`), {
        step,
        filename,
        warehouse,
        type,
        original_filename: originalFilename,
        settings: {delimiter}
    }, {headers: this.httpHeaders});

    loadMapping = (): Observable<any> => this.httpClient.get<any>(this.buildUrl(`srm/import-mapping/list`), {headers: this.httpHeaders});

    createMapping = (fileBrand: string, boodmoBrand: number, additional?: string): Observable<CreateStatusMessageInterface> =>
        this.httpClient.post<CreateStatusMessageInterface>(this.buildUrl(`srm/import-mapping`), {
            brand_name: fileBrand,
            brand_id: boodmoBrand,
            additional,
        }, {headers: this.httpHeaders});

    updateMapping = (boodmoBrand: number, id: string, active: boolean, additional?: string): Observable<StatusMessageInterface> =>
        this.httpClient.put<StatusMessageInterface>(this.buildUrl(`srm/import-mapping/${id}/update`), {
            brand_id: boodmoBrand,
            active,
            additional,
        }, {headers: this.httpHeaders});

    deleteMapping = (id: string): Observable<StatusMessageInterface> =>
        this.httpClient.delete<StatusMessageInterface>(this.buildUrl(`srm/import-mapping/${id}`), {headers: this.httpHeaders});

    loadFileBrandList = (id: string): Observable<any> => id
        ? this.httpClient.get<any>(this.buildUrl(`srm/file-brands/${id}`), {headers: this.httpHeaders})
        : throwError('no batch id provided');

    startFullImport = (id: string, sendEmail?: boolean): Observable<StatusMessageInterface> =>
        this.httpClient.post<StatusMessageInterface>(this.buildUrl(`srm/import-batch/full-run`), {
                batch_id: id,
                send_email: sendEmail
            },
            {headers: this.httpHeaders});

    linkImport = (id: string): Observable<LinkImportResponse> =>
        this.httpClient.post<LinkImportResponse>(this.buildUrl(`srm/import-batch/link`), {batch_id: id},
            {headers: this.httpHeaders});
}
