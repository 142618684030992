import {inject} from '@angular/core';
import {HttpEvent, HttpRequest, HttpHandlerFn} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthService} from '../services/auth.service';
import md5 from 'md5';
import {BUILD, VERSION} from '../../../environments/version';
import {FingerPrintService} from '../services/finger-print.service';

export const TokenInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const authService = inject(AuthService);
    const fingerPrintService = inject(FingerPrintService);

    const addCustomHeaders = (req: HttpRequest<any>): HttpRequest<any> => {
        const now = new Date();
        const timestamp = Math.floor(now.getTime() / 1000);
        const customHeaders = {
            'X-Client-App': 'seller_cabinet',
            'X-Client-Build': BUILD,
            'X-Client-Version': VERSION,
            'X-Date': now.toISOString(),
            'X-Boo-Sign': md5(
                fingerPrintService.getVisitorId()
                    ? timestamp + ' ' + fingerPrintService.getVisitorId()
                    : (1000 * Math.floor(timestamp / 1000)).toString()
            ),
        };

        if (fingerPrintService.getVisitorId())
            customHeaders['X-Client-Id'] = fingerPrintService.getVisitorId();

        if (getApiVersion(req.url))
            customHeaders['Accept-Version'] = getApiVersion(req.url);

        if (getApiModule(req.url))
            customHeaders['X-Api'] = getApiModule(req.url);


        return req.clone({
            setHeaders: customHeaders,
        });
    }

    const getApiVersion = (url: string): string => {
        const apiVersionPath = url.match(/\/v\d\//);
        if (!apiVersionPath?.length) return '';

        return apiVersionPath[0].match(/v\d/)[0];
    }

    const getApiModule = (url: string): string => {
        let apiModule = '';

        switch (true) {
            case url.includes('/seller/api/'):
                apiModule = 'SellerAPI';
                break;
        }

        return apiModule;
    }

    request = addCustomHeaders(request);

    if (authService.hasLocalToken()) {
        const token = authService.getToken();
        request = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`),
        });
    }
    return next(request);
}
