import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SalesItem} from '../../../../../domain/entity/sales-item/sales-item.entity';

@Component({
  selector: 'app-cancel-confirmation',
  templateUrl: './cancel-confirmation.component.html',
  styleUrls: ['./cancel-confirmation.component.scss']
})
export class CancelConfirmationComponent {

  @Input() items: SalesItem[];

  constructor(
    public activeModal: NgbActiveModal
  ) {
  }
}
