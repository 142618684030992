import {SalesItemResponse} from '../../../infrastructure/response/sales-item.response';
import {SalesItemStatus} from '../../models/sales-item-status.enum';
import {ShippingAddress} from '../../vo/shipping-address/shipping-address.vo';
import {Box} from '../../vo/box/box.vo';
import { DateUtil } from 'src/app/application/utils/date/date.util';

export class SalesItem {
  static readonly STATUS_NEW = SalesItemStatus[SalesItemStatus.New] as 'New';
  static readonly STATUS_PROCESSING = SalesItemStatus[SalesItemStatus.Processing] as 'Processing';
  static readonly STATUS_CANCEL_REQUESTED = SalesItemStatus[SalesItemStatus['Cancel Requested']] as 'Cancel Requested';
  static readonly STATUS_PENDING = SalesItemStatus[SalesItemStatus.Pending] as 'Pending';
  static readonly STATUS_CLOSED = SalesItemStatus[SalesItemStatus.Closed] as 'Closed';
  static readonly STATUS_CANCELLED = SalesItemStatus[SalesItemStatus.Cancelled] as 'Cancelled';
  static readonly STATUS_COMPLETED = SalesItemStatus[SalesItemStatus.Completed] as 'Completed';

  protected constructor(
    private _id: string,
    private _orderId: string,
    private _orderNumber: string,
    private _partId: number,
    private _partNumber: string,
    private _brand: string,
    private _partName: string,
    private _qty: number,
    private _price: number,
    private _deliveryPrice: number,
    private _createdAt: Date,
    private _confirmationDays: number,
    private _dispatchDays: number,
    private _status: keyof typeof SalesItemStatus,
    private _gst: number,
    private _hsn: string,
    private _residenceId: string,
    private _residenceAddress: ShippingAddress,
    private _boxes: Box[],
    private _address?: ShippingAddress | null
  ) {
  }

  get id(): string {
    return this._id;
  }

  get orderId(): string {
    return this._orderId;
  }

  get orderNumber(): string {
    return this._orderNumber;
  }

  get partId(): number {
    return this._partId;
  }

  get partNumber(): string {
    return this._partNumber;
  }

  get brand(): string {
    return this._brand;
  }

  get partName(): string {
    return this._partName;
  }

  get qty(): number {
    return this._qty;
  }

  get price(): number {
    return this._price;
  }

  get deliveryPrice(): number {
    return this._deliveryPrice;
  }

  get total(): number {
    return (this.price + this.deliveryPrice) * this.qty;
  }

  // TODO hide pice
  get tax(): number {
    return this.total * this.gst / 100;
  }

  get subTotal(): number {
    return this.total - this.tax;
  }

  get createdAt(): Date {
    return this._createdAt;
  }

  get address(): ShippingAddress | null {
    return this._address;
  }

  get dispatchDays(): number {
    return this._dispatchDays;
  }

  get confirmationDays(): number {
    return this._confirmationDays;
  }

  get status(): keyof typeof SalesItemStatus {
    return this._status;
  }

  get gst(): number {
    return this._gst;
  }

  get hsn(): string {
    return this._hsn;
  }

  get residenceId(): string {
    return this._residenceId;
  }

  get residenceAddress(): ShippingAddress {
    return this._residenceAddress;
  }

  get boxes(): Box[] {
    return this._boxes;
  }

  // TODO show info alert
  get canCancel(): boolean {
    return this.status === SalesItem.STATUS_NEW || (this.status === SalesItem.STATUS_PROCESSING && this.qty !== this.packedQty);
  }

  // TODO show info alert
  get canPack(): boolean {
    return this.status === SalesItem.STATUS_PROCESSING && ((this.qty - this.packedQty) > 0);
  }

  // TODO show info alert
  get canConfirmCancel(): boolean {
    return this.status === SalesItem.STATUS_CANCEL_REQUESTED;
  }

  get packedQty(): number {
    return this.boxes?.reduce((qty, currentBox) => qty + currentBox.qty, 0) || 0;
  }

  static build = (seller: SalesItemResponse ): SalesItem => new SalesItem(
      seller.id,
      seller.seller_order_id,
      seller.seller_order_number,
      seller.part_id,
      seller.number,
      seller.brand,
      seller.name,
      seller.qty,
      seller.price,
      seller.delivery_price,
      DateUtil.convertTimestampToDate(seller.createdat_tmstp),
      seller.confirmation_days,
      seller.dispatch_days,
      seller.status,
      seller.gst,
      seller.hsn,
      seller.residence_id,
      JSON.parse(seller.residence_shipping_address),
      JSON.parse(seller.boxes),
      seller?.address ? JSON.parse(seller.address) : null,
  );
}
