<app-loader *ngIf="loader"></app-loader>

<form novalidate [formGroup]="form" class="card-body" *ngIf="fb">
  <!--    TODO add tutorial   -->
  <!--    <p class="mb-2"><b class="text-brand-dark">Before import:</b> see our <a href="">tutorial</a> for file generation.</p>-->

  <div class="mb-3">
    <label for="warehouse" class="form-label d-block">Warehouse:</label>
    <select class="form-select form-select-sm w-auto" required formControlName="warehouse"
            id="warehouse"
            [class.is-invalid]="fb.isSubmitted() && form.controls.warehouse.invalid"
            [class.is-valid]="fb.isSubmitted() && !form.controls.warehouse.invalid">
      <!--      [disabled]="disabledUpload"-->
      <option [value]="null" disabled>Choose Warehouse</option>
      <option [value]="option.id" *ngFor="let option of warehouses">
        {{ option.name }}
        <ng-container *ngIf="option.fulfilledStock"> (FC Stock)</ng-container>
      </option>
    </select>
    <div class="invalid-feedback"
         *ngIf="form.getError('required', 'warehouse')">{{fb.getErrorMsg('required')}}</div>
    <p class="mt-1" *ngIf="importType">Import type : {{importType}}</p>
  </div>
  <div class="row">
    <div class="mb-3 mxw-400px col-sm-6">
      <input id="filename" type="file"
             accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
             required hidden
             (change)="onFileChange($event)"
             [disabled]="disabledUpload" #fileInput/>

      <label class="form-label" for="filename">Choose import file <span
        class="text-brand-dark">(<b>.xlsx</b>, <b>.xls</b>, <b>.csv</b>)</span>:</label>

      <div class="input-group input-group-sm" (click)="fileInput.click()">
        <input type="text" class="form-control" aria-label="Sizing example input" readonly [value]="file?.name"
               aria-describedby="inputGroup-sizing-sm"
               [class.is-invalid]="fb.isSubmitted() && form.controls.filename.invalid"
               [class.is-valid]="fb.isSubmitted() && !form.controls.filename.invalid">
        <button class="btn btn-primary rounded-end" id="inputGroup-sizing-sm">Upload</button>
      </div>

    </div>

    <div *ngIf="isCsv" class="mb-3 col-sm-6 mxw-400px">
      <label class="form-label" for="delimiter">Enter delimiter for CSV file:</label><br/>
      <input type="text" name="delimiter" class="form-control form-control-sm"
             id="delimiter" placeholder=","
             formControlName="delimiter"
             required
             [class.is-invalid]="fb.isSubmitted() && form.controls.delimiter.invalid"
             [class.is-valid]="fb.isSubmitted() && !form.controls.delimiter.invalid">
      <div class="invalid-feedback"
           *ngIf="form.getError('required', 'delimiter')">{{fb.getErrorMsg('required')}}</div>
    </div>

    <div class="col-12">
      <div class="form-check form-switch d-inline-block"
           ngbTooltip="Note: All offers that are not included in file will be disable"
           *ngIf="!batch?.isFullConfigured">
        <input type="checkbox" class="form-check-input" id="flushOffers" name="flushOffers"
               formControlName="flushOffers"
        >
        <label class="form-check-label" for="flushOffers">Disable all supplier products before import</label>
      </div>

      <p class="mt-3">Download sample files: <a type="button" class="btn-link" (click)="saveSample('warehouse')">WH sample</a>, <a type="button" class="btn-link" (click)="saveSample('vendor')">FC stock sample</a>.</p>

      <div class="invalid-feedback d-block" *ngIf="fb.isSubmitted()  && form.controls.filename.invalid">
        {{fb.getErrorMsg('general', 'file')}} You can upload products using XLSX(XLS) or CSV file.
      </div>
    </div>
  </div>


  <!--  <div class="btn-modal-wrapp text-start">-->
<!--    &lt;!&ndash;      TODO add sample-import.csv&ndash;&gt;-->
<!--    &lt;!&ndash;        <a href="/assets/files/sample-import.csv" class="btn btn-sm btn-secondary btn-modal me-sm-1">Download sample file</a>&ndash;&gt;-->
<!--    <button class="btn btn-sm btn-outline-primary btn-modal"-->
<!--            *ngIf="batch && batch?.filename && (batch.stepId !== stepId)"-->
<!--            [routerLink]="batch?.getLinkByStep(2)"-->
<!--            [queryParams]="{batchID: batch?.id}">-->
<!--      Use previous uploaded file-->
<!--    </button>-->
<!--  </div>-->

</form>

<!--footer-->
<div class="card-footer justify-content-between d-flex align-items-center">
  <span class="text-muted">Step 1 of 3</span>
  <div class="d-inline-block">
    <button class="btn ms-1" (click)="upload()"
            [ngClass]="disabledUpload ? 'btn-outline-primary' : 'btn-primary'"
            [disabled]="disabledUpload">Upload
    </button>
  </div>
</div>
