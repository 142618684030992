<div *ngIf="sellerService.$currentSeller | async as currentSeller" class="overflow-auto">
  <div class="card-body p-0">

    <div class="text-center">
      <button type="button" class="profile-panel-close" aria-label="Close" (click)="hideProfilePanel.emit(false)">  </button>

      <img [src]="currentSeller?.avatarPath" [alt]="currentSeller?.companyName || currentSeller?.name" class="profile-image">
      <div class="text-truncate">{{currentSeller.companyName || currentSeller?.name}}</div>
      <div class="text-secondary">
        <p>User ID: {{currentSeller.accountId}}</p>
        <p class="text-truncate">{{currentSeller.email}}</p>
      </div>

      <ul class="nav justify-content-center" (click)="hideProfilePanel.emit(false)">
        <li class="nav-item"><a class="nav-link" [routerLink]="'/settings'">My Account</a></li>
        <li class="nav-item"><a class="nav-link text-danger" [routerLink]="'/login'">Sign Out</a></li>
      </ul>
    </div>

    <div class="card-hdr">
      <div class="text-secondary text-uppercase">My Warehouses</div>
      <div class="">
        <a [routerLink]="'/settings/warehouses'" (click)="hideProfilePanel.emit(false)">
        <svg-icon [src]="iconUrls.cog" class="me-1"></svg-icon>Manage</a>
      </div>
    </div>
    <ul class="list-group list-group-flush">
      <li class="list-group-item warehouse-search">
        <svg-icon [src]="iconUrls.search" class="warehouse-search-icon"></svg-icon>
        <button type="button" class="btn btn-sm warehouse-search-icon warehouse-search-icon--right" aria-label="Close" (click)="warehouseName.value=''">
          <svg-icon [src]="iconUrls.timesLight"></svg-icon>
        </button>
        <input type="text" class="form-control form-control-sm rounded-pill"
               [(ngModel)]="warehouseName.value" #warehouseName
               placeholder="Search Warehouse"
        >
      </li>
      <li class="list-group-item" *ngFor="let warehouse of currentSeller.warehouses | filterBy: warehouseName.value: 'name'">
        <div>{{warehouse.name}} {{warehouse.fulfilledStock ? '(Fulfilment center)' : ''}}</div>
        <div [ngClass]="warehouse.online ? 'text-success' : 'text-danger'">{{warehouse.online | booleanLabel:['online', 'offline']}}</div>
        <div>
          <a (click)="goToPriceList(warehouse.id, warehouse.fulfilledStock)" class="badge bg-info">List</a>
          <a (click)="goToImports(warehouse.id)"  class="badge bg-primary ms-2">Imports</a>
        </div>
      </li>
    </ul>

  </div>

</div>
