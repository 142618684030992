import { UiFilterConfigInterface } from './ui-filter-config.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { FilterQueryBuilder } from '../../../utils/query-builder/filter-query-builder';
import {ConfigInterface} from '../../../components/th/th.component';
import {DateUtil} from '../../../utils/date/date.util';
import _cloneDeep from 'lodash-es/cloneDeep';
import {OrderByPipe} from "../../../pipes/order-by/order-by.pipe";

export class UiFilter<T>
{

    public filterChanged$: Observable<T[]>;

    private readonly filterChangedSource$ = new BehaviorSubject<T[]>(null);

    private initialItems: T[] = [];

    constructor(private items: T[] = [], private filterConfig: UiFilterConfigInterface[],  private queryBuilder: FilterQueryBuilder)
    {
        this.initialItems = _cloneDeep(this.items);
        this.filterChanged$ = this.filterChangedSource$.asObservable().pipe(filter((v) => !!v));
    }

    public filter(items?: T[]): void
    {
        if (items) {
            this.initialItems = _cloneDeep(items);
        }

        this.items = this.initialItems.filter((item) => (this.filterConfig.every((c) => {
            const value =  this.queryBuilder.getFilters()[c.filterField]?.[0];
            let itemValue =  item[c.fieldName];
            const values = this.queryBuilder.getFilters()[c.filterField] as string[];

            switch (c.type) {
                case 'multipleSelect':
                    return values === undefined || values === null || values.includes(itemValue);
                case 'select':
                    return  value === undefined || value === '' || (typeof item[c.selectKey] === 'object' && item[c.selectKey].id === value)
                        || itemValue === value;
                case 'text':
                    return value == null || value === '' ||
                        String(itemValue).toLowerCase().includes(value.toLowerCase());
                case 'dateFrom': {
                    if (typeof itemValue === 'number' ) {
                        itemValue = DateUtil.convertTimestampToDate(itemValue);
                    }
                    return value == null || value === '' ||
                        DateUtil.datesComparison('sameOrAfter', itemValue as unknown as Date, new Date(value));
                }
                case 'dateTo': {
                    if (typeof itemValue === 'number' ) {
                        itemValue = DateUtil.convertTimestampToDate(itemValue);
                    }
                    return value == null || value === '' ||
                        DateUtil.datesComparison('sameOrBefore', itemValue as unknown as Date, new Date(value));
                }
                case 'lessThan': {
                    const normalizedValue = c.usePice ? Number(itemValue) / 100 : Number(itemValue);

                    return value == null || value === '' || +value <= normalizedValue;
                }
                case 'moreThan': {
                    const normalizedValue = c.usePice ? Number(itemValue) / 100 : Number(itemValue);

                    return value == null || value === '' || +value >= normalizedValue;
                }
                case 'stringEqual':
                    return value == null || value === '' ||
                        (value as unknown as string).toLowerCase() === (itemValue as unknown as string).toLowerCase();
                case 'boolean':
                    return value == null || value === '' || value === itemValue;
            }
        })));
        this.filterChangedSource$.next(this.items);
    }

    public sort(sortCol: ConfigInterface): void
    {
        this.items = new OrderByPipe().transform(this.items, sortCol.name, !sortCol.value);
        // if (sortCol.value) {
        //     this.sortDesc(sortCol.name);
        // } else {
        //     this.sortAsc(sortCol.name);
        // }
        this.filterChangedSource$.next(this.items);
    }

    // private sortAsc(field: string): void
    // {
    //     this.items = this.items.sort((a,b) => {
    //         const firstField = this.prepareValue(a[field]);
    //         const secondField = this.prepareValue(b[field]);
    //
    //         return (firstField > secondField) ? 1 : ((secondField > firstField) ? -1 : 0);
    //     });
    // }
    //
    // private sortDesc(field: string): void
    // {
    //     this.items = this.items.sort((a,b) => {
    //         const firstField = this.prepareValue(a[field]);
    //         const secondField = this.prepareValue(b[field]);
    //
    //         return (firstField < secondField) ? 1 : ((secondField < firstField) ? -1 : 0);
    //     });
    // }
    //
    // private prepareValue(value: any): any
    // {
    //     return !Number.isNaN(+value) ? +value : typeof value === 'string' ? value.toLowerCase() : value;
    // }

}
