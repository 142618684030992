import { Component, OnInit } from '@angular/core';
import { Favicons } from '../../services/browser-favicons.service';
import { CopyrightNotice } from '../../utils/copyright-notice';
import { ActivationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { IconUrls } from '../../../constants/icon-urls';
import { SellerService } from '../../services/seller.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  showMenu = true;
  showMobileMenu = false;
  showProfilePanel = false;
  sidebarUnfoldable = false;
  copyrightNotice: string = CopyrightNotice.fetch('text-primary');
  logged = false;

  constructor(
    public iconUrls: IconUrls,
    private router: Router,
    private favicons: Favicons,
    public sellerService: SellerService,
  ) {
    favicons?.activate();
  }

  ngOnInit(): void
  {
    this.router.events.pipe(
      filter(event => event instanceof ActivationEnd && event.snapshot.children.length === 0)
    ).subscribe((event: ActivationEnd) => {
      this.logged = !event.snapshot.data.unLogged;
    });
  }

  toggleMenu(): void {
    this.showMenu = !this.showMenu;
  }

  toggleMobileMenu(): void {
    this.showMobileMenu = !this.showMobileMenu;
  }

  toggleSidebar() {
    this.sidebarUnfoldable = !this.sidebarUnfoldable;
  }

  toggleProfilePanel(visible?: boolean) {
    if (visible === undefined) {
      this.showProfilePanel = !this.showProfilePanel;
    } else {
      this.showProfilePanel = visible;
    }
  }
}
