<div class="modal-header">
  <h5 class="modal-title">Warehouse details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="warehouse">
  <p class="row">
    <b class="col-5">Name:</b>
    <span class="col-7">{{ warehouse.name }}</span>
  </p>
  <p class="row">
    <b class="col-5">Residence:</b>
    <span class="col-7">{{ warehouse.residenceName }}</span>
  </p>
  <p class="row">
    <b class="col-5">Price active:</b>
    <span class="col-7">{{ warehouse.priceActive }}</span>
  </p>
  <p class="row">
    <b class="col-5">Last import:</b>
    <span class="col-7">{{ warehouse.lastImport | bdate }}</span>
  </p>
  <p class="row">
    <b class="col-5">Dispatch days:</b>
    <span class="col-7">{{ warehouse.dispatchDays }}</span>
  </p>
  <p class="row">
    <b class="col-5">Confirmation days:</b>
    <span class="col-7">{{ warehouse.confirmationDays }}</span>
  </p>
  <p class="row">
    <b class="col-5">Status:</b>
    <span class="col-7">
      <span class="badge fw-normal" [ngClass]="warehouse.enabled?'bg-success':'bg-danger'">{{warehouse.enabled ? 'Enabled' : 'Disabled'}}</span>
    </span>
  </p>

</div>
<div class="modal-footer btn-modal-wrapp">
  <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
