<div class="row">
    <div class="col-md-8">
        <div class="d-flex align-items-center justify-content-end py-2 px-1">
            <button class="btn btn-primary btn-sm" (click)="openProcessMapping()">Add Mapping</button>
        </div>

        <div class="card shadow">
            <div class="card-body p-0" style="position: relative;">
                <div class="table-wrapper overflow-auto">
                    <app-loader *ngIf="gridLoader"></app-loader>

                    <table class="table table-bordered table-hover">
                        <thead>
                        <tr>
                            <th style="width: 35%">
                                <app-th [colKey]="'brand_name'"
                                        [config]="cols.brandName"
                                        [queryBuilder]="queryBuilder"
                                        [name]="'Brand Name'"
                                        [placeholder]="'Brand Name'"
                                        (sort)="sort($event)"
                                        (update)="applyFilters()"
                                ></app-th>
                            </th>
                            <th style="width: 35%">
                                <app-th [colKey]="'boodmo_name'"
                                        [config]="cols.boodmoName"
                                        [queryBuilder]="queryBuilder"
                                        [name]="isSmallDisplay ? 'Boodmo Name' : 'Brand Name in boodmo.com'"
                                        [placeholder]="'Boodmo Name'"
                                        (sort)="sort($event)"
                                        (update)="applyFilters()"
                                ></app-th>
                            </th>
                            <th>
                                <app-th [colKey]="'active'"
                                        [config]="cols.active"
                                        [queryBuilder]="queryBuilder"
                                        [name]="'Active'"
                                        [showAllOption]="true"
                                        [inputWidth]="'90px'"
                                        (sort)="sort($event)"
                                        (update)="applyFilters()"
                                        [dropdownPosition]="isSmallDisplay ? 'bottom-right' : 'bottom-left'"
                                >
                                    <ng-container class="options">
                                        <option [value]="true">Yes</option>
                                        <option [value]="false">No</option>
                                    </ng-container>
                                </app-th>
                            </th>
                            <th>Info</th>
                            <th style="width: 100px" class="px-1">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <ng-container *ngFor="let mapping of mappings | orderBy: sortOrder?.field : sortOrder?.reverse
                                      | paginate: { itemsPerPage: queryBuilder.getLimit(), currentPage: queryBuilder.getPage() }">
                            <tr>
                                <td>
                                    {{ mapping.brandName }}
                                </td>
                                <td>
                                    {{ mapping.boodmoName }}
                                </td>
                                <td>
                                    <span [ngClass]="mapping.active ? 'text-success' : 'text-danger'" class="text-nowrap">
                                        <svg-icon [src]="iconUrls.circle"></svg-icon>
                                        {{ mapping.active | booleanLabel }}
                                    </span>
                                </td>
                                <td>
                                    {{ mapping.additional }}
                                </td>
                                <td class="p-1">
                                    <div class="d-flex flex-nowrap btn-group-sm">
                                        <button type="button" class="btn btn-danger me-1" (click)="deleteMapping(mapping)">Delete</button>
                                        <button type="button" class="btn btn-primary" (click)="openProcessMapping(mapping)">Edit</button>
                                    </div>
                                </td>
                            </tr>
                        </ng-container>
                        </tbody>
                    </table>
                </div>

              <app-tfoot [queryBuilder]="queryBuilder"
                         [dataList]="mappings"
                         (changePageNum)="queryBuilder.changePageNum($event)"
                         [loader]="gridLoader"
              ></app-tfoot>
            </div>
        </div>
    </div>
</div>
