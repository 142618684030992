import { AbstractAddress } from '../abstract-address/abstract-address.vo';
import { AddressInterface } from '../../models/address.interface';
import {StateInterface} from '../../models/state.interface';

export class ShippingAddress extends AbstractAddress {
  private constructor(
    _contactFirstName: string,
    _contactLastName: string,
    _contactPhone: string,
    _pin: number,
    _cityName: string,
    _addressLine: string,
    _state: StateInterface,
    _title?: string,
  ) {
    super(
      _contactFirstName,
      _contactLastName,
      _contactPhone,
      _pin,
      _cityName,
      _addressLine,
      _state,
      _title,
    );
  }

  static build = (address: AddressInterface): ShippingAddress => new ShippingAddress(
    address.contactFirstName,
    address.contactLastName,
    address.contactPhone,
    address.pin,
    address.cityName,
    address.addressLine,
    address.state,
    address.title,
  );
}
