import {inject} from '@angular/core';
import {HttpEvent, HttpRequest, HttpErrorResponse, HttpHandlerFn} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastrService} from '../services/toastr.service';
import {AbstractRepository} from '../../infrastructure/repositories/abstract.repository';

export const ErrorInterceptor = (request: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
    const toastrService = inject(ToastrService);
    return next(request).pipe(
        catchError((error: HttpErrorResponse) => {
            let errorMessage = '';
            console.log(error);
            if (error.error instanceof ErrorEvent) {
                // client-side error
                errorMessage = `Error: ${error.error.message}`;
            } else {
                // server-side error
                errorMessage = `Error Status: ${error.status}\nMessage: ${error?.error?.detail ?? error?.message}`;
                if (error.status === 503) {
                    toastrService.errorNotify(
                        'Please, try after few minutes',
                        {header: 'Website is temporary unavailable'}
                    );
                    return throwError('');
                }
                if (request.url === AbstractRepository.buildUrl('seller/login') && error.status === 500) {
                    toastrService.errorNotify('Incorrect username or password. Please, check entered data');
                    return throwError('');
                }
            }
            toastrService.errorNotify(errorMessage);
            return throwError(error);
        })
    );
}
