import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import {
  AbstractForm,
  ADDRESS, CITY,
  controlToUpperCase,
  EMAIL,
  NAME,
  normalizePin,
  PHONE, PIN,
  removeSpaces
} from './abstract.form';
import { Seller } from '../../domain/entity/seller/seller.entity';

export class SellerInfoForm extends AbstractForm {

  constructor(private sellerInfo: Seller) {
    super();

    this.form = new UntypedFormGroup({
      login: new UntypedFormControl(this.sellerInfo.email || '', [
        Validators.required as any,
        Validators.pattern(EMAIL),
        Validators.minLength(this.minEmail) as any,
        Validators.maxLength(this.maxEmail) as any
      ]),
      displayName: new UntypedFormControl(this.sellerInfo.name || '', [
        Validators.required as any,
        Validators.minLength(this.minLength) as any,
        Validators.maxLength(this.nameMaxLength) as any,
        Validators.pattern(NAME),
        removeSpaces,
      ]),
      companyName: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress?.companyName || '', [
        Validators.required as any,
        Validators.minLength(this.nameMinLength) as any,
        Validators.maxLength(this.nameMaxLength) as any,
        Validators.pattern(NAME),
        removeSpaces,
      ]),
      contactEmail: new UntypedFormControl(this.sellerInfo.merchantAccount.contactEmail || '', [
        Validators.required as any,
        Validators.pattern(EMAIL),
        Validators.minLength(this.minEmail) as any,
        Validators.maxLength(this.maxEmail) as any,
      ]),
      cinNumber: new UntypedFormControl(this.sellerInfo.merchantAccount.cinNumber || '', [
        Validators.required as any,
        removeSpaces,
      ]),
      firstName: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress?.contactFirstName || '', [
        Validators.required as any,
        Validators.minLength(this.nameMinLength) as any,
        Validators.maxLength(this.nameMaxLength) as any,
        Validators.pattern(NAME),
        removeSpaces,
      ]),
      lastName: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress.contactLastName, [
        Validators.required as any,
        Validators.minLength(this.minLength) as any,
        Validators.maxLength(this.nameMaxLength) as any,
        Validators.pattern(NAME),
        removeSpaces,
      ]),
      billingAddress: new UntypedFormGroup({
        gstNumber: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress?.gstNumber, [
          Validators.required as any,
          Validators.pattern(this.gstPattern) as any,
          Validators.maxLength(this.gstLength) as any,
          Validators.minLength(this.gstLength) as any,
          controlToUpperCase
        ]),
        contactPhone: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress?.contactPhone, [
          Validators.required as any,
          Validators.minLength(this.phoneLength) as any,
          Validators.maxLength(this.phoneLength) as any,
          Validators.pattern(PHONE) as any,
        ]),
        addressLine: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress?.addressLine, [
          Validators.required as any,
          Validators.minLength(this.minAddressLength) as any,
          Validators.maxLength(this.maxAddressLength) as any,
          Validators.pattern(ADDRESS),
        ]),
        pin: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress?.pin, [
          Validators.required as any,
          Validators.pattern(PIN) as any,
          Validators.minLength(this.pinLength) as any,
          Validators.maxLength(this.pinLength) as any,
          normalizePin,
        ]),
        state: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress?.state?.abbr, [
          Validators.required,
        ]),
        city: new UntypedFormControl(this.sellerInfo?.merchantAccount?.billingAddress?.cityName, [
          Validators.required,
          Validators.minLength(this.minLength) as any,
          Validators.maxLength(this.cityLength) as any,
          Validators.pattern(CITY),
        ]),
      }),
    });
  }

}
