import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {PartService} from '../../../services/part.service';
import {firstValueFrom, Subscription} from 'rxjs';
import {FilterQueryBuilder} from '../../../utils/query-builder/filter-query-builder';
import {NgForm} from '@angular/forms';
import {PartResponse} from '../../../../infrastructure/response/part-response';
import {IconUrls} from '../../../../constants/icon-urls';
import {OfferFacade} from '../../../facades/offer/offer.facade';
import {finalize} from 'rxjs/operators';
import {PriceListService} from '../../../services/price-list.service';
import {ToastrService} from '../../../services/toastr.service';
import {ColumnInterface, ConfigInterface} from "../../../components/th/th.component";
import {GridHelper} from "../../../utils/grid-helper/grid-helper";

@Component({
    selector: 'app-link-offer',
    templateUrl: './link-offer.component.html',
    styleUrls: ['./link-offer.component.scss']
})
export class LinkOfferComponent implements OnInit, OnDestroy {
    @ViewChild('form') form: NgForm;
    @Input() offerFacade: OfferFacade;
    partNumber: string;
    needAltNumber: boolean;
    pendingRequest: Subscription;
    loader: Subscription | boolean;
    parts: PartResponse[];
    readonly queryBuilder: FilterQueryBuilder;
    readonly cols: {[key: string]: ColumnInterface} = {
        brandName: {
            sort: { name: 'brandName', value: null, applied: false },
        },
        number: {
            sort: { name: 'number', value: null, applied: false },
        },
        name: {
            sort: { name: 'name', value: null, applied: false },
        },
        className: {
            sort: { name: 'className', value: null, applied: false },
        },
    };

    constructor(
        public activeModal: NgbActiveModal,
        private partService: PartService,
        private priceListService: PriceListService,
        private toastrService: ToastrService,
        public iconUrls: IconUrls,
    ) {
        this.queryBuilder = new FilterQueryBuilder(
            {
                filterSet: [
                    'number',
                    'is_enabled'
                ],
                limit: 100
            },
        );
        // this.queryBuilder.handleSort(this.cols.number.sort.name, this.cols.number.sort.value ? 'desc' : 'asc');
    }

    ngOnInit(): void {
        setTimeout(() => {
            this.form.form.get('partNumber').patchValue(this.offerFacade.offer.partNumber);
        });
    }

    ngOnDestroy(): void {
        this.pendingRequest?.unsubscribe();
    }

    findParts(form: NgForm) {
        if (form.invalid) {
            return;
        }
        this.queryBuilder.handleFilter('is_enabled', true, {multiple: false});
        this.queryBuilder.handleFilter('number', this.partNumber, {multiple: false});
        this.loader = this.partService.loadPrices(this.queryBuilder).pipe(
            finalize(() => {
                this.loader = false;
            })
        ).subscribe((parts) => {
            this.parts = parts;
        });
    }

    sort(sortCol: ConfigInterface): void {
        GridHelper.ClearSorting(this.cols, sortCol);
    }

    link($event: MouseEvent, part: PartResponse) {
        this.pendingRequest?.unsubscribe();
        $event.stopPropagation();
        if (this.needAltNumber) {
            this.savePartAltNumber(part);
        }

        this.loader = true;
        this.pendingRequest = this.priceListService.link(this.offerFacade.id, part.id).pipe(
            finalize(() => {
                this.loader = false;
            })
        ).subscribe((data) => {
            this.toastrService.successNotify(data);
            this.activeModal.close();
        });
    }

    private async savePartAltNumber(part: PartResponse)
    {
        await firstValueFrom(this.priceListService.addPartAltNumber(part.id, this.offerFacade.offer.partNumber));
    }
}
