import { AbstractForm } from './abstract.form';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Seller } from '../../domain/entity/seller/seller.entity';
import {IntegrationInterface} from '../../domain/models/integration.interface';

export class IntegrationForm extends AbstractForm
{

  constructor(private seller: Seller) {
    super();

    this.form = new UntypedFormGroup({});
    for (const item of seller.integration) {
      this.form.setControl(item.key, IntegrationForm.createFG(item));
    }
  }

  private static createFG(integration: IntegrationInterface): UntypedFormGroup
  {
    return new UntypedFormGroup({
      key: new UntypedFormControl(integration.key, [Validators.required]),
      action: new UntypedFormControl(integration.action, []), // TODO check Validators.required
      method: new UntypedFormControl(integration.method, [Validators.required]),
    });
  }
}
