import { Injectable } from '@angular/core';
import { AbstractRepository } from './abstract.repository';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})

export class AuthRepository extends AbstractRepository {
  currentUser(): Observable<any> {
    return this.httpClient.post(this.buildUrl('srm/seller/current'), {headers: this.httpHeaders});
  }

  loginUser = ( email: string, password: string): Observable<{ token: string }> =>
    this.httpClient.post<{ token: string }>(this.buildUrl('srm/seller/login'), {email, password}, {headers: this.httpHeaders});

  registerUser = ( email: string, password: string): Observable<{ token: string }> =>
    this.httpClient.post<{ token: string }>(this.buildUrl('srm/seller/register'), {email, password}, {headers: this.httpHeaders});

  // oauth(username: string, password: string): Observable<any> {
  //   return this.httpClient.post<>(this.buildUrl(''), {
  //     password,
  //     username,
  //     grant_type: 'password',
  //     client_id: 'admin'
  //   });
  // }
}
