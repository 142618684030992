<app-loader *ngIf="loading || initLoading"></app-loader>

<div class="" *ngIf="batch && !initLoading">

    <div class="card-body">
        <app-mapping [boodmoBrands]="boodmoBrands"
                     [brands]="fileBrands"
                     [title]="'Brand name mapping'"
        ></app-mapping>
        <app-mapping [boodmoBrands]="boodmoBrands"
                     [brands]="importMapping"
                     [title]="'Saved mapping'"
        ></app-mapping>
    </div>

    <!--footer-->
    <div class="card-footer justify-content-between d-flex align-items-center">
        <span class="text-muted">Step 2 of 3</span>
        <div class="d-inline-block">
            <button class="btn btn-secondary me-1"
                    [routerLink]="batch?.getLinkByStep(stepId - 1)"
                    [queryParams]="{batchID: batch?.id}">Previous
            </button>
            <button class="btn btn-primary ms-1"
                    (click)="run()"
                    [ngClass]="!batch ? 'btn-outline-primary' : 'btn-primary'"
                    [disabled]="!batch">Run Import
            </button>
        </div>
    </div>
</div>
