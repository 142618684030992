import {SalesItem} from '../../domain/entity/sales-item/sales-item.entity';
import {ProcessingOrderFacade} from '../facades/processing-order/processing-order.facade';
import {SalesItemResponse} from '../../infrastructure/response/sales-item.response';

export class SalesItemGroupToOrderService {
  private _orders: ProcessingOrderFacade[];

  private constructor(private bucket: SalesItem[]) {
    this._orders = new Array<ProcessingOrderFacade>();
    this.reduce();
  }

  private get orders(): ProcessingOrderFacade[] {
    return this._orders;
  }
  static fetch = (bucket: SalesItemResponse[]): ProcessingOrderFacade[] => new SalesItemGroupToOrderService(bucket.map(SalesItem.build)).orders;

  private reduce(): void {
    const orderMap: Record<any, any> = {};

    for (const item of this.bucket) {
      const key = item.orderId;
      const preGroup = orderMap[key];
      orderMap[key] = ProcessingOrderFacade.build({
        orderId: key,
        createdAt: preGroup?.createdat || item.createdAt,
        orderNumber: preGroup?.orderNumber || item.orderNumber,
        address: preGroup?.address || item.address,
        qty: (preGroup?.qty || 0) + item.qty,
        total: (preGroup?.total || 0) + item.total,
        tax: (preGroup?.tax || 0) + item.tax,
        subTotal: (preGroup?.subTotal || 0) + item.subTotal,
        items: [...(preGroup?.items || []), item],
      });
    }
    this._orders = [...Object.values(orderMap)];
  }
}
