import {ShipmentInfoResponse} from '../../../infrastructure/response/shipment-info.response';
import {AddressInterface} from '../../../domain/models/address.interface';
import {BoxDimensionsInterface} from '../../../domain/models/box-dimensions.interface';
import {ShipmentBoxFacade} from './shipment-box.facade';
import {ShippingAddress} from '../../../domain/vo/shipping-address/shipping-address.vo';
import {DateUtil} from '../../utils/date/date.util';

export class ShipmentInfoFacade {
  private constructor(
    private _id: string,
    private _reference: string,
    private _sellerId: string,
    private _residenceId: string,
    private _orderNumber: string,
    private _pickupAddress: ShippingAddress,
    private _destAddress: AddressInterface,
    private _invoiceInfo,
    private _dimensions: BoxDimensionsInterface,
    private _shipmentType: string, // TODO enum
    private _boxes: ShipmentBoxFacade[],
    private _provider: string,
    private _carrierName: string,
    private _status: string,
    private _trackNumber: string | null,
    private _trackStatus: string | null,
    private _shipmentEta: Date | null,
    private _additional?: any,
  ) {
  }

  get id(): string {
    return this._id;
  }

  get reference(): string {
    return this._reference;
  }

  get sellerId(): string {
    return this._sellerId;
  }

  get residenceId(): string {
    return this._residenceId;
  }

  get orderNumber(): string {
    return this._orderNumber;
  }

  get pickupAddress(): ShippingAddress {
    return this._pickupAddress;
  }

  get destAddress(): AddressInterface {
    return this._destAddress;
  }

  get invoiceInfo() {
    return this._invoiceInfo;
  }

  get dimensions(): BoxDimensionsInterface {
    return this._dimensions;
  }

  get shipmentType(): string {
    return this._shipmentType;
  }

  get boxes(): ShipmentBoxFacade[] {
    return this._boxes;
  }

  get provider(): string {
    return this._provider;
  }

  get carrierName(): string {
    return this._carrierName;
  }

  get status(): string {
    return this._status;
  }

  get trackNumber(): string | null {
    return this._trackNumber;
  }

  get trackStatus(): string | null {
    return this._trackStatus;
  }

  get shipmentEta(): Date | null {
    return this._shipmentEta;
  }

  get additional(): any {
    return this._additional;
  }

  static build = (obj: ShipmentInfoResponse) => new ShipmentInfoFacade(
      obj.id,
      obj.reference,
      obj.seller_id,
      obj.residence_id,
      obj.order_number,
      obj.pickup_address,
      obj.dest_address,
      obj.invoice_info,
      obj.dimensions,
      obj.shipment_type,
      obj.boxes.map(ShipmentBoxFacade.build),
      obj.provider,
      obj.carrier_name,
      obj.status,
      obj.track_number,
      obj.track_status,
      obj.shipment_eta ? DateUtil.convertTimestampToDate( obj.shipment_eta) : null,
      obj.additional
  );
}
