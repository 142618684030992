<div class="modal-header">
  <h5 class="modal-title">Processing order details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <p class="row">
    <b class="col-5">Order Date:</b>
    <span class="col-7">{{ facade.createdAt | bdate: 'dateTime' }}</span>
  </p>
  <p class="row">
    <b class="col-5">Order ID:</b>
    <span class="col-7">{{ facade.orderNumber }}</span>
  </p>
  <p class="row">
    <b class="col-5">Customer:</b>
    <span class="col-7">
        Name: {{ facade.customerInfo.contactFirstName }} {{ facade.customerInfo.contactLastName }}<br>
        PIN: {{ facade.customerInfo.pin }}<br>
        State: {{ facade.customerInfo.state.name }}<br>
        City: {{ facade.customerInfo.cityName }}
      </span>
  </p>
  <p class="row">
    <b class="col-5">Ship by:</b>
    <span class="col-7">{{ facade?.shipBy | bdate: 'dateTime' }}</span>
  </p>
  <p class="row">
    <b class="col-5">Order Qty:</b>
    <span class="col-7">{{ facade?.qty }}</span>
  </p>
  <p class="row">
    <b class="col-5">Order Total:</b>
    <span class="col-7">{{ facade?.total | indianCurrency }}</span>
  </p>

</div>
<div class="modal-footer btn-modal-wrapp">
  <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
