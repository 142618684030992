import {Residence} from '../../../domain/entity/residence/residence.entity';

export class ResidenceFacade {
  private constructor(
    private _residence: Residence
  ) {
  }

  get residence(): Residence {
    return this._residence;
  }

  get status(): boolean {
    return false;
  }

  get type(): 'Seller' | string {
    return this.residence.isFC ? `FC (${this.residence.fulfilmentCenter})` : 'Seller';
  }

  get shipping(): 'boodmo' {
    return 'boodmo';
  }

  static build = (residence: Residence) => new ResidenceFacade(residence);
}
