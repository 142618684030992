import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AbstractForm } from './abstract.form';

export class FileImportForm extends AbstractForm {
  updateDelimiterValidationControl: (isCsv: boolean) => void;

  public constructor(warehouse) {
    super();
    this.form = new UntypedFormGroup({
      warehouse: new UntypedFormControl(warehouse, [Validators.required as any]),
      filename: new UntypedFormControl(null, [Validators.required as any]),
      delimiter: new UntypedFormControl(null, []),
      flushOffers: new UntypedFormControl(null, [])
    });

    this.updateDelimiterValidationControl = (isCsv: boolean): void => {
      const delimiter = this.form.get('delimiter');
      if (isCsv) {
        delimiter.setValue(',');
        delimiter.setValidators([Validators.required as any]);
      } else {
        delimiter.setValue(null);
        delimiter.clearValidators();
      }
      delimiter.updateValueAndValidity({onlySelf: false, emitEvent: true});
    };
  }
}
