<div class=""
     ngbDropdown
     #exportResultsDropdown="ngbDropdown"
     container="body"
     placement="bottom-end"
     autoClose="outside"
>
    <button class="btn btn-link p-0 fs-3 header-icon fa-3x fa-layers fa-fw dropdown-non-toggle-arrow"
          ngbTooltip="Click to open My Export"
          id="exportResults"
          container="body"
          ngbDropdownToggle
    >
        <svg-icon [src]="iconUrls.fileExport"></svg-icon>
        <span class="fa-layers-counter"
              *ngIf="exportResultsService.itemsCount > 0"
              [ngStyle]="{ 'background-color': exportResultsService.hasDone?'green':'Tomato' }"
        >
            {{exportResultsService.itemsCount}}
        </span>
    </button>
  <ul class="export-results p-0 rounded-0"
      ngbDropdownMenu
      aria-labelledby="exportResults"
  >
    <li
      *ngFor="let item of exportResultsService.items | orderBy:['status', 'createdAt']: false; trackBy: trackByFunction"
      class="border-1 border-bottom ps-2 pe-1"
      ngbDropdownItem
    >
      <div class="d-flex justify-content-between align-items-start">
        <div class="fw-bold">
          <div class="text-truncate"
               [ngbTooltip]="item.title?.length > 26 ? item.title : null"
               container="body"
          >
              {{item.title}}
          </div>
          <div class="text-success d-inline-flex align-items-center"
               *ngIf="item.status === ExportStatusEnum.done; else processingTmpl">
            Ready
            <button class="btn btn-sm btn-light text-success ms-1"
                    (click)="download(item)"
            >
              <svg-icon [src]="iconUrls.download"></svg-icon>
            </button>
          </div>
          <ng-template #processingTmpl>
            <div class="text-brand d-inline-flex align-items-center overflow-hidden">
              Processing
              <svg-icon [src]="iconUrls.spinner" class="px-2 py-1 fa-spin"></svg-icon>
            </div>
          </ng-template>
        </div>
        <div class="">
          <span class="fs-xs">
              {{item.createdAt | bdate:'time'}}
          </span>
          <button class="btn btn-sm btn-light"
                  (click)="exportResultsService.remove(item.key)">
            <svg-icon [src]="iconUrls.xmark"></svg-icon>
          </button>
        </div>
      </div>
    </li>
    <li class="ngbDropdownItem text-center p-2 fs-lg"
        *ngIf="!exportResultsService.itemsCount"
    >
      No exports yet
    </li>
  </ul>
</div>
