import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DictionaryRepository } from '../../infrastructure/repositories/dictionary.repository';
import { StateInterface } from '../../domain/models/state.interface';
import { CityInterface } from '../../domain/models/city.interface';
import { CheckAddressResponseInterface } from '../../infrastructure/response/check-address-response.interface';
import { CheckAddressInterface } from '../../domain/models/check-address.interface';

@Injectable({
    providedIn: 'root'
})
export class DictionaryService
{
    constructor(private dictionaryRepository: DictionaryRepository)
    {}

    loadStateList = (): Observable<StateInterface[]> => this.dictionaryRepository.loadStateList().pipe(map((response) => response?.items));

    loadCityList = (stateAbbr: string): Observable<CityInterface[]> =>
      this.dictionaryRepository.loadCityList(stateAbbr).pipe(map((items) => items));

    addressCheck = (payload: CheckAddressInterface): Observable<CheckAddressResponseInterface> => this.dictionaryRepository.addressCheck(payload);
}
