import { Injectable } from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import { map } from 'rxjs/operators';
import { SellerRepository } from '../../infrastructure/repositories/seller.repository';
import { Seller } from '../../domain/entity/seller/seller.entity';
import { CurrentSeller } from '../../domain/entity/current-seller/current-seller.entity';
import { StatusMessageInterface } from '../../domain/models/status-message.interface';
import {UpdateSellerRequest} from '../../infrastructure/requests/update-seller.request';
import {OrderByPipe} from "../pipes/order-by/order-by.pipe";
import {Warehouse} from "../../domain/entity/warehouse/warehouse.entity";
import {FindInListWithGroupsInterface} from "../components/th/th.component";

@Injectable({
  providedIn: 'root'
})
export class SellerService {
  currentSeller: CurrentSeller;
  $currentSeller: BehaviorSubject<CurrentSeller> = new BehaviorSubject<CurrentSeller>(null);

  constructor(private sellerRepository: SellerRepository) {}

  loadSellerInfo = (sellerId?: string): Observable<Seller> => this.sellerRepository.fetchSellerInfo(sellerId || this.currentSeller?.id).pipe(
    map(Seller.build)
  );

  updateSellerInfo = (sellerId: string, obj: UpdateSellerRequest): Observable<StatusMessageInterface> =>
    this.sellerRepository.updateSellerInfo(sellerId, obj);

  groupWarehouses = (warehouses: Warehouse[]): FindInListWithGroupsInterface[] => {
      const warehouseMapByEnabled = {};
      for (const warehouse of new OrderByPipe().transform(warehouses || [], 'name', 'asc')) {
          const name = warehouse.enabled ? 'Enabled' : 'Disabled';
          if (!warehouseMapByEnabled[name]) {
              warehouseMapByEnabled[name] = {name, group: [],};
          }
          warehouseMapByEnabled[name].group.push(warehouse);
      }
      return Object.values(warehouseMapByEnabled);
  }
}
