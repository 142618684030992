import {ChangeDetectorRef, Component, DoCheck, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import { FilterQueryBuilder } from '../../utils/query-builder/filter-query-builder';
import {NgbDateStruct, NgbDropdown} from '@ng-bootstrap/ng-bootstrap';
import { IconUrls } from '../../../constants/icon-urls';
import {CustomDateParserFormatter} from '../../utils/date/date.parser';
import { DateUtil } from '../../utils/date/date.util';

export interface FindInListInterface
{
    id: string | number;
    name: string;
    disabled?: boolean;
}

export interface FindInListWithGroupsInterface extends FindInListInterface
{
    group: FindInListInterface[];
}

export interface ConfigInterface
{
    value: any;
    applied: boolean;
    name: string;
    usePice?: boolean;
    title?: string;
}

export interface FilterConfigInterface extends ConfigInterface
{
    type: 'text' | 'dateRange' | 'select' | 'numberRange' | 'ngSelect' | 'positiveNumber';
}

export interface ColumnInterface
{
    filter?: FilterConfigInterface;
    sort?: ConfigInterface;
}

@Component({
    selector: 'app-th',
    templateUrl: './th.component.html',
    styleUrls: ['./th.component.scss'],
    providers: [
        CustomDateParserFormatter
    ]
})
export class ThComponent implements OnInit, DoCheck
{
    @Input() colKey: string;
    @Input() config: ColumnInterface;
    @Input() dropdownPosition: 'bottom-left' | 'bottom-right' = 'bottom-left';
    @Input() dateInSeconds: boolean;
    @Input() dateWithTime: boolean;
    @Input() toStartDay: boolean;
    // @Input() readonly toEndDay: boolean;
    @Input() moneyInPennies: boolean;
    @Input() queryBuilder: FilterQueryBuilder;
    @Input() inputWidth: string;
    @Input() maxWidthTruncate?: number;
    @Input() wrapOptions?: string;
    @Input() multiselect?: boolean;
    @Input() name?: string;
    @Input() options?: (FindInListInterface | string)[];
    @Input() rangeSuffix?: string[] = ['From', 'To'];
    @Input() placeholder?: string = '';
    @Input() useMaxDate?: Date | null = null;
    @Input() showAllOption: boolean;

    @Output() readonly update = new EventEmitter<null>();
    @Output() readonly sort = new EventEmitter<ConfigInterface>();
    @ViewChild('dRef', {read: NgbDropdown, static: true}) dRef: NgbDropdown;

    public minDate: NgbDateStruct;
    public minToDate: NgbDateStruct;
    public maxDate: NgbDateStruct;
    public maxFromDate: NgbDateStruct;
    public currentDate: string;

    public valueFrom: string;
    public valueTo: string;
    public canApplyFilter: boolean;
    public showAllOptionValue: any;
    constructor(
        public iconUrls: IconUrls,
        public formatter: CustomDateParserFormatter,
        private cd: ChangeDetectorRef,
        )
    { }

    static filterIsRange(config: ColumnInterface): boolean
    {
        return config?.filter?.type === 'dateRange' || config?.filter?.type === 'numberRange';
    }

    static fixDateFilterValue(value, rangeSuffix?: string, dateWithTime?: boolean, dateInSeconds?: boolean){
        let fixedValue;
        fixedValue = new Date(value);
        if (dateWithTime) {
            if (/from/.test(rangeSuffix.toLowerCase())) {
                fixedValue = DateUtil.setTimeToDate(fixedValue);
            }
            if (/to/.test(rangeSuffix.toLowerCase())) {
                fixedValue = DateUtil.setTimeToDate(fixedValue, true);
            }
        }
        fixedValue = fixedValue.toISOString().slice(0, (dateWithTime === undefined || dateWithTime) ? 19 : 10).replace(/T/, ' ');

        if (dateInSeconds) {
            fixedValue = new Date(DateUtil.convertStringToDbFormat(fixedValue)).getTime() / 1000;
        }
        return fixedValue;
    }

    get filterIsRange(): boolean
    {
        return ThComponent.filterIsRange(this.config);
    }

    ngOnInit(): void
    {
        this.setShowAllOptionValue();
        this.setDefaultRangeDate();
        this.name = this.name || this.config.filter.title;
    }

    ngDoCheck(): void {
        this.setCanApplyFilter();
    }

    setShowAllOptionValue(): void {
        this.showAllOptionValue = this.config?.filter?.value;
    }

    setDefaultRangeDate(): void {
        if (this.config?.filter?.type === 'dateRange') {
            this.minDate = this.formatter.parse('1970-01-01', true);
            this.minToDate = this.minDate;
            const date = new Date();
            this.maxDate = this.formatter.parse((this.useMaxDate
                    ? this.useMaxDate
                    : this.useMaxDate !== null
                        ? date
                        : new Date(date.setFullYear(2999))
            ).toISOString().slice(0, 10), true);
            this.maxFromDate = this.maxDate;
            this.currentDate = new Date().toISOString().slice(0, 10);
        }
    }

    toggleSort(item: ConfigInterface, value: boolean, dropdown: NgbDropdown): void
    {
        item.applied = item.value === value ? !item.applied : true;

        if (item.applied) {
            item.value = value;
            this.queryBuilder.handleSort(item.name, item.value ? 'desc' : 'asc');
        } else {
            item.value = null;
            this.queryBuilder.clearSort();
        }

        this.update.emit();
        this.sort.emit(item);
        dropdown.toggle();
    }

    setCanApplyFilter(): void
    {
        this.canApplyFilter = (!this.filterIsRange ||
            !this.valueFrom ||
            !this.valueTo ||
            (this.valueFrom && this.valueTo && this.valueFrom <= this.valueTo));
        this.cd.detectChanges();
    }

    applyFilter(dropdown: NgbDropdown): void
    {
        dropdown.toggle();
        this.setAppliedFilter();
        this.config.filter.applied = this.filterIsRange ? (!!this.valueFrom || !!this.valueTo) : !!this.config?.filter.value;
        this.update.emit();
        this.queryBuilder.applyFilters.next(true);
    }

    clearFilter(dropdown: NgbDropdown): void
    {
        // const updateNeeded = this.config?.filter?.applied;

        dropdown.toggle();
        if (this.config.filter) {
            this.setDefaultRangeDate();

            if (this.filterIsRange) {
                this.valueFrom = '';
                this.valueTo = '';
                this.onChangeFilter(this.config?.filter.name, '', {rangeSuffix: this.rangeSuffix[0]});
                this.onChangeFilter(this.config?.filter.name, '', {rangeSuffix: this.rangeSuffix[1]});
            } else {
                this.config.filter.value = this.showAllOptionValue;
                this.onChangeFilter(this.config?.filter.name, this.config.filter.value);
            }
            this.config.filter.applied = false;
        }

        // if (updateNeeded) {
        this.update.emit();
        this.queryBuilder.applyFilters.next(true);
        // }
    }

    onChangeFilter(filterKey: string, value: any, options?: {isDate?: boolean; rangeSuffix?: string; multiple?: boolean}): void
    {
        if(this.config?.filter?.type === 'text' && this.config?.filter?.value) {
            this.config.filter.value = value.trim();
        }

        let fixedValue;
        if (value && options?.isDate) {
            fixedValue = ThComponent.fixDateFilterValue(value, options?.rangeSuffix, this.dateWithTime, this.dateInSeconds);
        }

        if (this.moneyInPennies) {
            fixedValue = value || value === 0 ? (value * 100) : value;
        }

        this.queryBuilder.handleFilter(filterKey, fixedValue ?? (typeof value === 'string' ? value?.trim() : value), {
            moneyInPennies: this.moneyInPennies,
            dateInSeconds: this.dateInSeconds,
            dateWithTime: this.dateWithTime,
            isDate: options?.isDate,
            rangeSuffix: options?.rangeSuffix,
            multiple: options?.multiple,
        });
        if (this.queryBuilder.getPage()) {
            this.queryBuilder.changePageNum(1);
        }

        this.cd.detectChanges();
    }

    setRangeFilter(isDate = false): void {
        this.onChangeFilter(this.config.filter.name, this.valueFrom, {isDate, rangeSuffix: this.rangeSuffix[0]});
        this.onChangeFilter(this.config.filter.name, this.valueTo, {isDate, rangeSuffix: this.rangeSuffix[1]});
    }

    setAppliedFilter(): void {
        switch (this.config.filter.type) {
            case 'dateRange':
                this.setRangeFilter(true);
                break;
            case 'numberRange':
                this.setRangeFilter();
                break;
            default:
                this.onChangeFilter(this.config.filter.name, this.config.filter.value, { multiple: !this.multiselect });
        }
    }

    dateSelectChange(type: string) {
        switch (type) {
            case 'from':
                this.minToDate = this.formatter.parse(this.valueFrom, true);
                break;
            case 'to':
                this.maxFromDate = this.formatter.parse(this.valueTo, true);
                break;
        }
    }
}
