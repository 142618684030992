import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SellerInfoForm } from '../../forms/seller-info.form';
import { UntypedFormGroup } from '@angular/forms';
import { SellerService } from '../../services/seller.service';
import { Seller } from '../../../domain/entity/seller/seller.entity';
import { ToastrService } from '../../services/toastr.service';
import { Subscription } from 'rxjs';
import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';
import { finalize } from 'rxjs/operators';
import {StateInterface} from '../../../domain/models/state.interface';
import {DictionaryService} from '../../services/dictionary.service';


@Component({
  selector: 'app-seller-info',
  templateUrl: './seller-info.component.html',
  styleUrls: ['./seller-info.component.scss']
})
export class SellerInfoComponent implements OnInit {

  loader: Subscription | boolean;
  fb: SellerInfoForm;
  form: UntypedFormGroup;
  states: StateInterface[];
  statesLoading: Subscription | boolean;
  private pendingRequest: Subscription;
  private seller: Seller;

  constructor(
    private titleService: Title,
    private sellerService: SellerService,
    private toastrService: ToastrService,
    private breadcrumbsService: BreadcrumbsService,
    private dictionaryService: DictionaryService,
  ) {
    const title = 'Settings - Seller info';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
  }

  ngOnInit(): void {
    this.getSellerInfo();
    this.getStates();
  }

  resetPassword(): void {
    // TODO add
  }

  save(): void {
    this.fb.markSubmitted();
    if (!this.form.valid) {
      return;
    }
    const {
      billingAddress,
      cinNumber,
      companyName,
      displayName,
      contactEmail,
      firstName,
      lastName,
      login,
    } = this.form.value;

    this.loader = this.sellerService.updateSellerInfo(this.seller.id, {
      email: login,
      name: displayName,
      merchant_account: {
        bank_name: this.seller.merchantAccount.bankName,
        bank_ifsc: this.seller.merchantAccount.bankIfsc,
        bank_account_number: this.seller.merchantAccount.bankAccountNumber,
        bank_branch_name: this.seller.merchantAccount.bankBranchName,
        cancelled_cheque: this.seller.merchantAccount.cancelledCheque,
        pan_card: this.seller.merchantAccount.panCard,
        gst_cert: this.seller.merchantAccount.gstCert,
        seller_signature: this.seller.merchantAccount.sellerSignature,
        contact_email: contactEmail,
        cin_number: cinNumber,
        billing_address: {
          title: '',
          companyName,
          contactFirstName: firstName,
          contactLastName: lastName,
          contactPhone: billingAddress.contactPhone,
          pin: billingAddress.pin,
          cityName: billingAddress.city,
          addressLine: billingAddress.addressLine,
          state: billingAddress.state,
          gstNumber: billingAddress.gstNumber,
        },
      }
    }).pipe(finalize(() => {
      this.loader = false;
    })).subscribe((res) => {
      this.toastrService.successNotify(res.message ?? 'Seller info was successfully saved');
    });
  }

  private getSellerInfo(): void {
    this.loader = this.sellerService.loadSellerInfo().pipe(finalize(() => {
      this.loader = false;
    })).subscribe((seller: Seller) => {
      this.seller = seller;
      this.fb = new SellerInfoForm(seller);
      this.form = this.fb.getForm();
    });
  }

  private getStates(): void {
    this.pendingRequest = this.statesLoading = this.dictionaryService.loadStateList().pipe(
      finalize(() => {
        this.statesLoading = false;
      })
    ).subscribe((resp) => {
      this.states = resp;
    });
  }
}
