import { Component, HostBinding, TemplateRef } from '@angular/core';
import { ToastrService } from '../../services/toastr.service';

@Component({
    selector: 'app-toastr-container',
    templateUrl: './toastr-container.component.html',
    styleUrls: ['./toastr-container.component.scss']
})
export class ToastrContainerComponent
{
    constructor(public toastrService: ToastrService)
    {}

    @HostBinding('class.ngb-toasts') get initClass(): boolean
    {
        return true;
    }

    public isTemplate = (toast: any) => toast.textOrTpl instanceof TemplateRef;

    public isAutoHide = (toast: any): boolean => toast.autohide !== undefined ? toast.autohide : true;
}
