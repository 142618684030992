import { Injectable } from '@angular/core';
import { MassActionReducer } from '../../domain/models/reducer/mass-action.reducer';
import { MassActionItemInterface } from '../../domain/models/mass-action-item.interface';
import { MassActionGroupInterface } from '../../domain/models/mass-action-group.interface';

// import { IconProp } from '@fortawesome/fontawesome-svg-core';

@Injectable({
  providedIn: 'root',
})

export class MassActionService {
  private massActionCore: MassActionReducer;

  constructor() {
    this.init();
  }

  get getResult(): string[] {
    return this.massActionCore.getResult;
  }

  get getItems(): MassActionItemInterface[] {
    return this.massActionCore.getItems;
  }

  get getItem(): MassActionItemInterface | boolean {
    const items = this.massActionCore.getItems;
    if (!items.length || items.length > 1) {
      return false;
    }
    return items[0];
  }

  init = (): void => {
    this.massActionCore = new MassActionReducer();
  };

  entry = (items: MassActionItemInterface[], event?: MouseEvent): void => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
    }
    this.massActionCore.handleItems(items);
  };

  update = (items: MassActionItemInterface[]): void => {
    for (const item of items) {
      this.massActionCore.addItem(item);
    }
  };

  checkEntry = (items: MassActionItemInterface[], strict?: boolean): boolean =>
    this.massActionCore.checkItems(items, strict);

  checkMassButton = (items: MassActionItemInterface[]): boolean => this.checkEntry(items, false);

  getClass = (item: MassActionItemInterface): string =>
    `row-toggler--${this.checkEntry([item]) ? 'success' :
      this.checkEntry([item], false) ? 'warning' : 'primary'}`;

  getIcon = (item: MassActionItemInterface): string =>
    this.checkEntry([item]) ? 'checkSquare' : 'square';

  checkMassButtonByGroups = (groups: MassActionGroupInterface[]): boolean =>
    this.checkEntry(groups
      .reduce((result: MassActionItemInterface[], group: MassActionGroupInterface): MassActionItemInterface[] =>
        [...result, ...group.items], new Array<MassActionItemInterface>()), false);

  getClassByGroup = (group: MassActionGroupInterface): string =>
    `row-toggler--${this.checkEntry(group.items) ? 'success' :
      this.checkEntry(group.items, false) ? 'warning' : 'primary'}`;

  getIconByGroup = (group: MassActionGroupInterface): string => this.checkEntry(group.items, false) ? 'checkSquare' : 'square';
}
