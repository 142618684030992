import { Injectable } from '@angular/core';
import { ImportBatch } from '../../domain/entity/import-batch/import-batch.entity';
import {combineLatest, forkJoin, Observable} from 'rxjs';
import { PrepareMappingResponse } from '../../infrastructure/response/prepare-mapping.response';
import { ImportRepository } from '../../infrastructure/repositories/import.repository';
import { FilterQueryBuilder } from '../utils/query-builder/filter-query-builder';
import { map } from 'rxjs/operators';
import { StatusMessageInterface } from '../../domain/models/status-message.interface';
import { MappedBrandInterface } from '../../domain/models/mapped-brand.interface';
import { BoodmoBrandInterface } from '../../domain/models/boodmo-brand.interface';
import { LinkImportResponse } from '../../infrastructure/response/link-import.response';
import { CreateStatusMessageInterface } from '../../domain/models/create-status-message.interface';
import { BrandMappingFacade } from '../facades/brand-mapping/brand-mapping.facade';
import {CatalogService} from './catalog.service';
import {ImportBatchFacade} from '../facades/import-batch/import-batch.facade';

@Injectable({
  providedIn: 'root'
})
export class ImportService {

  constructor(
    private importRepository: ImportRepository,
    private catalogService: CatalogService,
  ) {
  }

  loadList = (queryBuilder: FilterQueryBuilder): Observable<{ data: ImportBatchFacade[]; total: number }> =>
      this.importRepository.fetch(queryBuilder).pipe(map((response: any) => ({
        total: response?.total,
        data: response?.data?.map((item) => new ImportBatchFacade(ImportBatch.build(item), item.warehouseName))
      })
    ));

  createBatch = (
      warehouse: string,
      filename: string,
      originalFilename: string,
      type: 'vendor' | 'warehouse',
      flushOffers: boolean,
      delimiter?: string
  ): Observable<CreateStatusMessageInterface> =>
    this.importRepository.createBatch(warehouse, filename, originalFilename, type, flushOffers, delimiter);

  loadBatch = (id: string): Observable<ImportBatch> => this.importRepository.loadBatch(id).pipe(map(ImportBatch.build));

  updateBatch = (
    warehouse: string,
    id: string,
    filename: string,
    originalFilename: string,
    step: string,
    type: 'vendor' | 'warehouse',
    delimiter?: string
  ): Observable<StatusMessageInterface> =>
    this.importRepository.updateBatch(warehouse, id, filename, originalFilename, step, type, delimiter);

  loadFileBrandList = (id: string): Observable<Record<any, any>> => this.importRepository.loadFileBrandList(id);

  loadMapping = (): Observable<MappedBrandInterface[]> => this.importRepository.loadMapping();

  createMapping = (fileBrand: string, boodmoBrand: number, additional?: string): Observable<CreateStatusMessageInterface> =>
    this.importRepository.createMapping(fileBrand, boodmoBrand, additional);

  updateMapping = (boodmoBrand: number, id: string, active: boolean, additional?: string): Observable<StatusMessageInterface> =>
    this.importRepository.updateMapping(boodmoBrand, id, active, additional);

  deleteMapping = (id: string): Observable<StatusMessageInterface> =>
    this.importRepository.deleteMapping(id);

  prepareMappingDataWithFileBrands = (batch: ImportBatch): Promise<PrepareMappingResponse> => new Promise<PrepareMappingResponse>((resolve, reject) => {
    combineLatest([
      this.loadMapping(),
      this.catalogService.fetchBrandsList(),
      this.loadFileBrandList(batch.id)]
    ).toPromise().then(([importMapping, boodmoBrands, fileBrands]) => resolve({
      boodmoBrands,
      fileBrands: Object.keys(fileBrands)
        .filter((fileBrand: string): boolean => !importMapping.some(brand => brand.brand_name === fileBrand))
        .map((fileBrand: string): MappedBrandInterface => (
          {
            id: null,
            brand_name: fileBrand,
            mapped_brand: 'No selected brand',
            brand_id: null,
            additional: null,
          }
        )),
      importMapping: importMapping
        .filter(brand => Object.keys(fileBrands).includes(brand.brand_name))
        .map(brand => {
          brand.mapped_brand = boodmoBrands.find((bBrand: BoodmoBrandInterface) => bBrand.id === brand.brand_id)?.name ?? null;
          return brand;
        })
    }), err => {
      reject(err);
    });
  });

  prepareMappingData = (): Promise<{ brands: BoodmoBrandInterface[]; mappings: BrandMappingFacade[] }> =>
    new Promise<{ brands: BoodmoBrandInterface[]; mappings: BrandMappingFacade[] }>((resolve, reject) => {
      combineLatest([
        this.loadMapping(),
        this.catalogService.fetchBrandsList()]
      ).toPromise().then(([mappings, brands]) => resolve({
        brands,
        mappings: mappings.map((m) => BrandMappingFacade.build(m, brands))
      }), err => {
        reject(err);
      });
    });

  startFullImport = (id: string, sendEmail?: boolean): Observable<StatusMessageInterface> => this.importRepository.startFullImport(id, sendEmail);

  linkImport = (id: string): Observable<LinkImportResponse> =>
    this.importRepository.linkImport(id).pipe(map((response: LinkImportResponse) => {
      response.notLinked = response.total - response.imported;
      return response;
    }));
}
