import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ProcessingOrderFacade} from '../../../../facades/processing-order/processing-order.facade';

@Component({
  selector: 'app-processing-order-details',
  templateUrl: './processing-order-details.component.html',
  styleUrls: ['./processing-order-details.component.scss']
})
export class ProcessingOrderDetailsComponent {

  @Input() facade: ProcessingOrderFacade ;
  public constructor(public activeModal: NgbActiveModal) {}
}
