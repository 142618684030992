import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ResidenceFacade} from '../../../facades/residence/residence.facade';

@Component({
  selector: 'app-residence-details',
  templateUrl: './residence-details.component.html',
  styleUrls: ['./residence-details.component.scss']
})
export class ResidenceDetailsComponent {

  @Input() facade: ResidenceFacade;

  constructor(public activeModal: NgbActiveModal) {}
}
