import {Shipment} from '../../../domain/entity/shipment/shipment.entity';
import {CarrierInterface} from '../../../domain/models/carrier.interface';

export class PackedShipmentFacade {
  private constructor(
    private _shipment: Shipment,
    private _boxCount: number
  ) {
  }

  get shipment(): Shipment {
    return this._shipment;
  }

  get carrier(): CarrierInterface {
    return this.shipment.carrier;
  }

  get invoiceNumber(): string {
    return this.shipment.invoiceInfo.number;
  }

  get invoiceTotal(): number {
    return this.shipment.invoiceInfo.value;
  }

  get eta(): Date {
    return new Date();
  }

  get boxCount(): number {
    return this._boxCount;
  }

  static build = (shipment: Shipment, boxCount: number): PackedShipmentFacade => new PackedShipmentFacade(shipment, boxCount);
}
