<div class="modal-header px-3 py-2">
  <h5 class="modal-title text-center flex-grow-1">Cancel confirmation</h5>

  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <p>The following items and quantity will be canceled:</p>
  <ul class="list-group">
    <li *ngFor="let item of items" class="list-group-item">
      <b>PN:</b> {{item.partNumber}}, <b>Qty:</b> {{item.qty - item.packedQty}}
    </li>
  </ul>
</div>

<div class="modal-footer btn-modal-wrapp">
  <button type="button" class="btn btn-outline-secondary btn-modal" (click)="activeModal.dismiss()">Close</button>
  <button type="submit" class="btn btn-success btn-modal" (click)="activeModal.close('success')">Confirm</button>
</div>
