import {ImportBatch} from '../../../domain/entity/import-batch/import-batch.entity';
import {ImportBatchStatus} from '../../../domain/models/import-batch-status.enum';

export class ImportBatchFacade {
    constructor(private _batch: ImportBatch, private _warehouseName: string) {
    }

    get batch(): ImportBatch {
        return this._batch;
    }

    get id(): string {
        return this._batch.id;
    }

    get batchNumber(): number {
        return this._batch.batchNumber;
    }

    get step(): string {
        return this._batch.step;
    }

    get status(): keyof typeof ImportBatchStatus {
        return this._batch.status;
    }

    get filename(): string {
        return this._batch.filename;
    }

    get originalFilename(): string {
        return this._batch.originalFilename;
    }

    get validFilename(): string | null {
        return this._batch.validFilename;
    }

    get errorFilename(): string | null {
        return this._batch.errorFilename;
    }

    get createdAt(): Date {
        return this._batch.createdAt;
    }

    get warehouse(): string {
        return this._batch.warehouse;
    }

    get warehouseName(): string {
        return this._warehouseName;
    }

    get statusBadge(): string {
        const result = 'badge bg-';
        switch (this.status) {
            case ImportBatch.STATUS_NEW:
                return result+'secondary';
            case ImportBatch.STATUS_QUEUED:
                return result+'warning';
            case ImportBatch.STATUS_PROCESSING:
                return result+'info';
            case ImportBatch.STATUS_DONE:
                return result+'success';
            case ImportBatch.STATUS_FAILED:
                return result+'danger';
            default:
                return '';
        }
    }
}
