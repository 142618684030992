<ngb-toast
    *ngFor="let toast of toastrService.toasts"
    [class]="toast.classname"
    [header]="toast?.header"
    [autohide]="isAutoHide(toast)"
    (mouseenter)="toast.autohide=false"
    (mouseleave)="toast.autohide=true"
    [delay]="toast.delay || 5000"
    (hidden)="toastrService.remove(toast)"
>
    <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="toast.textOrTpl"></ng-template>
    </ng-template>

    <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
