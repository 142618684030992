import { MassActionItemInterface } from '../mass-action-item.interface';

export class MassActionReducer {
  private batch: Map<string, MassActionItemInterface> = new Map();

  get getResult(): string[] {
    return Array.from(this.batch.keys());
  }

  get getItems(): MassActionItemInterface[] {
    return Array.from(this.batch.values());
  }

  addItem = (item: MassActionItemInterface): void => {
    this.batch.set(item.id, item);
  };

  removeItem = (item: MassActionItemInterface): void => {
    this.batch.delete(item.id);
  };

  toggleItem = (item: MassActionItemInterface): void => {
    if (!this.batch.delete(item.id)) {
      this.batch.set(item.id, item);
    }
  };

  handleItems = (items: MassActionItemInterface[]): void => {
    if (items.length === 1) {
      // toggle - MassActionItemInterface
      this.toggleItem(items[0]);
    } else if (this.checkItems(items)) {
      // removeItems - MassActionItemInterface[]
      items.forEach((item: MassActionItemInterface): void => this.removeItem(item));
    } else {
      // addItems - MassActionItemInterface[]
      items.forEach((item: MassActionItemInterface): void => this.addItem(item));
    }
  };

  checkItem = (item: MassActionItemInterface): boolean => this.batch.has(item.id);

  checkItems = (items: MassActionItemInterface[], strict: boolean = true): boolean =>
    items[strict ? 'every' : 'some'](item => this.checkItem(item));
}
