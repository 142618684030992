import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbsService {

  private currentTitle?: string;
  private items: { title: string; route: string }[] = new Array<{ title: string; route: string }>();

  constructor() {}

  get currentName(): string {
    return this.currentTitle;
  }

  get getItems(): { title: string; route: string }[] {
    return this.items;
  }

  setCurrentTitle(title: string): void {
    this.currentTitle = title;
  }

  setItem(param: { route: string; title: string }): void {
    this.items.push(param);
  }

  setItems(items: { route: string; title: string }[]): void {
    items.forEach(item => {
      this.setItem(item);
    });
  }

  clearTitle(): void {
    this.currentTitle = '';
  }

  clearItems(): void {
    this.items = [];
  }

  init(currentTittle?: string, items?: { title: string; route: string }[]): void {
    this.clearTitle();
    this.clearItems();
    if (currentTittle) {
      this.setCurrentTitle(currentTittle);
    }
    if (items && items.length > 0) {
      this.setItems(items);
    }
  }
}
