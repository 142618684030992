import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {BreadcrumbsService} from '../../../services/breadcrumbs/breadcrumbs.service';
import {IconUrls} from '../../../../constants/icon-urls';
import {SalesItemService} from '../../../services/sales-item.service';
import {ToastrService} from '../../../services/toastr.service';
import {ConfirmService} from '../../../services/confirm-modal.service';
import {PackedShipmentFacade} from '../../../facades/packed-shipment/packed-shipment.facade';
import {CONSTANTS} from '../../../../constants/constants';
import {FilterQueryBuilder} from '../../../utils/query-builder/filter-query-builder';
import {ColumnInterface, ConfigInterface} from '../../../components/th/th.component';
import {UiFilter} from '../../mapping/ui-filter/ui-filter';
import {UiFilterConfigInterface} from '../../mapping/ui-filter/ui-filter-config.interface';
import {CarrierInterface} from '../../../../domain/models/carrier.interface';
import {PackedItemDetailsComponent} from './packed-item-details/packed-item-details.component';
import {ShipmentDetailsComponent} from '../popups/shipment-details/shipment-details.component';
import {CreateStatusMessageInterface} from '../../../../domain/models/create-status-message.interface';
import {ShipmentService} from '../../../services/shipment.service';
import {GridHelper} from '../../../utils/grid-helper/grid-helper';

@Component({
  selector: 'app-packed-items',
  templateUrl: './packed-items.component.html',
  styleUrls: ['./packed-items.component.scss']
})
export class PackedItemsComponent implements OnInit, OnDestroy {
  readonly queryBuilder: FilterQueryBuilder;
  readonly cols: {[key: string]: ColumnInterface} = {
    carrier: {
      filter: { value: null, applied: false, name: 'carrier', type: 'ngSelect' },
      sort: { name: 'carrier', value: false, applied: false },
    },
    invoiceNumber: {
      filter: { value: '', applied: false, name: 'invoiceNumber', type: 'text' },
      sort: { name: 'invoiceNumber', value: false, applied: false },
    },
    invoiceTotal: {
      filter: { value: '', applied: false, name: 'invoiceTotal', type: 'numberRange', usePice: true },
      sort: { name: 'invoiceTotal', value: false, applied: true },
    },
    // eta: {
    //   filter: { value: '', applied: false, name: 'confirmationBefore', type: 'dateRange' },
    //   sort: { name: 'confirmationBefore', value: false, applied: true },
    // }
  };

  readonly filterConfig: UiFilterConfigInterface[] = [
    { fieldName: 'carrier', type: 'select', filterField: 'carrier' },
    { fieldName: 'invoiceNumber', type: 'text', filterField: 'invoiceNumber' },
    { fieldName: 'invoiceTotal', type: 'lessThan', filterField: 'invoiceTotalFrom'},
    { fieldName: 'invoiceTotal', type: 'moreThan', filterField: 'invoiceTotalTo'},
    // { fieldName: 'eta', type: 'dateFrom', filterField: 'etaFrom' },
    // { fieldName: 'eta', type: 'dateTo', filterField: 'etaTo' },
  ];
  filter: UiFilter<PackedShipmentFacade>;
  loader: Subscription | boolean;
  shipmentsLoaded: boolean;
  shipments: PackedShipmentFacade[] = [];
  page: number;
  carrierList: CarrierInterface[] = [];
  sortOrder: {
    field: string;
    reverse: boolean;
  };

  private pendingRequest: Subscription;
  private filterChangesSubscription: Subscription;
  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    public iconUrls: IconUrls,
    public salesItemService: SalesItemService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    public confirmService: ConfirmService,
    private shipmentService: ShipmentService,
  ) {
    const title = 'Orders - Packed';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
    this.queryBuilder = new FilterQueryBuilder({
      filterSet: [...Object.values(this.filterConfig).map((c) => c.filterField)],
    });
  }

  ngOnInit(): void {
    this.buildListGrid();
  }

  ngOnDestroy(): void {
    this.pendingRequest?.unsubscribe();
  }

  buildListGrid(): void
  {
    this.shipmentsLoaded = true;
    this.pendingRequest = this.loader = this.shipmentService.fetchPackedBoxList().pipe(
      finalize(() => this.loader = false)
    ).subscribe((dataGrid) => {
      this.shipments = dataGrid;
      const carrierMap: Map<string, CarrierInterface> = new Map<string, CarrierInterface>();
      for (const item of (dataGrid || [])) {
        carrierMap.set(item.carrier.name, item.carrier);
      }
      this.carrierList = Array.from(carrierMap.values()).map(({name}) => ({
        name,
        id: name
      }));
      this.createFilter(this.shipments);
    });
  }

  openDetail(facade: PackedShipmentFacade): void {
    if (window.innerWidth > CONSTANTS.MAX_POPUP_SCREEN_SM) {
      return;
    }
    const modalRef = this.modalService.open(PackedItemDetailsComponent);
    modalRef.componentInstance.facade = facade;
  }

  openShipmentDetails(facade: PackedShipmentFacade) {
    const modalRef = this.modalService.open(ShipmentDetailsComponent, {size: 'lg', scrollable: true});
    modalRef.componentInstance.shipmentId = facade.shipment.id;
    // modalRef.componentInstance.warehouse = this.selectedWarehouse;
    modalRef.result.then((response: CreateStatusMessageInterface) => {
      this.buildListGrid();
    }, () => {});
  }

  filterData(shipments?: PackedShipmentFacade[])
  {
    const sortCol = Object.values(this.cols).find((c) => c.sort?.applied)?.sort;

    this.filter.filter(shipments);

    if (sortCol) {
      this.filter.sort(sortCol);
      this.queryBuilder.changePageNum(1);
    }
  }

  sort(sortCol: ConfigInterface): void
  {
    this.filter.sort(sortCol);
    this.sortOrder = {
      field: sortCol.name,
      reverse: sortCol.value
    };
    this.queryBuilder.changePageNum(1);
    GridHelper.ClearSorting(this.cols, sortCol);
  }

  private createFilter(shipments: PackedShipmentFacade[]): void
  {
    this.filter = new UiFilter(shipments, this.filterConfig, this.queryBuilder);
    this.filterChangesSubscription = this.filter.filterChanged$
      .subscribe((v) => this.shipments = v);
    const appliedSortingKey = Object.keys(this.cols).find((key) => this.cols[key].sort?.applied);
    if (appliedSortingKey) {
      this.filter.sort(this.cols[appliedSortingKey].sort);
    }
  }
}
