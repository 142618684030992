import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { HttpParams } from '@angular/common/http';
import { APP_CONFIG } from 'src/config/app.server.config';

@Injectable()
export class AbstractRepository {
  static filters = {
    QUERY_PARAMS_SETTLEMENT_IDS: 'settlementIds',
    QUERY_PARAMS_SETTLEMENT_UTRS: 'settlementUTRs',
    QUERY_PARAMS_INVOICE_NUMBER: 'invoiceNumber',
  };

  protected httpHeaders: HttpHeaders;
  protected httpParams: HttpParams;

  constructor(protected httpClient: HttpClient) {
    this.httpHeaders = new HttpHeaders();
    this.httpParams = new HttpParams();

    this.addHeader('Content-Type', 'application/json');
  }
  static buildUrl = (apiName: string) =>  APP_CONFIG.API_SERVER + APP_CONFIG.API + apiName;

  protected addHeader = (name: string, value: string): HttpHeaders => this.httpHeaders = this.httpHeaders.set(name, value);

  protected addHttpParam = (name: string, value: any): HttpParams => this.httpParams = this.httpParams.set(name, value);

  // protected removeHttpParam = (name: string, value?): HttpParams => this.httpParams = this.httpParams.delete(name, value);

  protected buildUrl = (apiName: string): string => AbstractRepository.buildUrl(apiName);
}
