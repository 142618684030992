import { AbstractAddress } from '../abstract-address/abstract-address.vo';
import { AddressInterface } from '../../models/address.interface';
import {StateInterface} from '../../models/state.interface';

export class BillingAddress extends AbstractAddress {
  private constructor(
    _contactFirstName: string,
    _contactLastName: string,
    _contactPhone: string,
    _pin: number,
    _cityName: string,
    _addressLine: string,
    _state: StateInterface,
    private _companyName: string,
    private _gstNumber: string,
    _title?: string,
  ) {
    super(
      _contactFirstName,
      _contactLastName,
      _contactPhone,
      _pin,
      _cityName,
      _addressLine,
      _state,
      _title,
    );
  }

  get companyName(): string {
    return this._companyName;
  }

  get gstNumber(): string {
    return this._gstNumber;
  }

  static build = (address: AddressInterface): BillingAddress => new BillingAddress(
      address.contactFirstName,
      address.contactLastName,
      address.contactPhone,
      address.pin,
      address.cityName,
      address.addressLine,
      address.state,
      address.companyName,
      address.gstNumber,
      address.title,
  );
}
