import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

import {Seller} from '../../../domain/entity/seller/seller.entity';
import {IconUrls} from '../../../constants/icon-urls';
import {CONSTANTS} from '../../../constants/constants';
import {BreadcrumbsService} from '../../services/breadcrumbs/breadcrumbs.service';
import {SellerService} from '../../services/seller.service';
import {ResidenceFacade} from '../../facades/residence/residence.facade';
import {ResidenceDetailsComponent} from './residence-details/residence-details.component';
import {ProcessResidenceComponent} from './process-residence/process-residence.component';

@Component({
  selector: 'app-residences',
  templateUrl: './residences.component.html',
  styleUrls: ['./residences.component.scss']
})
export class ResidencesComponent implements OnInit {
  loader: Subscription | boolean;
  residences: ResidenceFacade[] = new Array<ResidenceFacade>();
  p = 1;
  itemsPerPage = 10;

  constructor(
    public iconUrls: IconUrls,
    private sellerService: SellerService,
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private modalService: NgbModal,
  ) {
    const title = 'Settings - Residences';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
  }

  ngOnInit(): void {
    this.fetchList();
  }

  processResidence(event: MouseEvent, facade?: ResidenceFacade) {
    event.stopPropagation();
    const modalRef = this.modalService.open(ProcessResidenceComponent, {size: 'lg'});
    if (facade) {
      modalRef.componentInstance.facade = facade;
    }
    modalRef.result.then((type) => {
      if (type === 'success') {
        this.fetchList();
        this.onChangePagination();
      }
    }, () => {});
  }

  openDetail(facade: ResidenceFacade) {
    if (window.innerWidth > CONSTANTS.MAX_POPUP_SCREEN_SM) {
      return;
    }
    const modalRef = this.modalService.open(ResidenceDetailsComponent);
    modalRef.componentInstance.facade = facade;
  }

  onChangePagination($event?: any): void {
    this.p = $event || 1;
    this.fetchList();
  }

  private fetchList(): void {
    this.loader = this.sellerService.loadSellerInfo().pipe(finalize(() => {
      this.loader = false;
    })).subscribe((seller: Seller) => {
      this.residences = seller.residences.map(ResidenceFacade.build);
    });
  }
}
