import {UntypedFormGroup, UntypedFormControl, Validators, ValidatorFn, AbstractControl, ValidationErrors} from '@angular/forms';
import {AbstractForm} from './abstract.form';

export class LoginForm extends AbstractForm {
  minEmail: number;
  maxEmail: number;
  minPassword: number;
  maxPassword: number;
  updateConfirmPasswordControl;
  useConfirm: boolean;

  constructor(useConfirm: boolean) {
    super();
    this.minEmail = 5;
    this.maxEmail = 30;
    this.minPassword = 5;
    this.maxPassword = 30;
    this.useConfirm = useConfirm;
    this.form = new UntypedFormGroup({
      email: new UntypedFormControl('', [
        Validators.required as any,
        Validators.email as any,
        Validators.minLength(this.minEmail) as any,
        Validators.maxLength(this.maxEmail) as any
      ]),
      password: new UntypedFormControl('', [
        Validators.required as any,
        Validators.minLength(this.minPassword) as any,
        Validators.maxLength(this.maxPassword) as any
      ]),
      confirmPassword: new UntypedFormControl('', [Validators.required as any])
    }, useConfirm ? { validators: confirmPasswordValidator } : {});

    this.updateConfirmPasswordControl = (): void => {
      const confirmPassword = this.form.get('confirmPassword');
      if (useConfirm) {
        confirmPassword.setValidators([Validators.required as any]);
      } else {
        confirmPassword.clearValidators();
      }
      confirmPassword.updateValueAndValidity({onlySelf: true});
    };
  }
}

export const confirmPasswordValidator: ValidatorFn = (control: AbstractControl): ValidationErrors | null => {
  const password = control.get('password');
  const confirmPassword = control.get('confirmPassword');

  return password && confirmPassword && password.value !== confirmPassword.value ? { confirmPassword: true } : null;
};
