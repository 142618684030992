import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {NewOrderItemFacade} from '../../../../facades/new-order-item/new-order-item.facade';

@Component({
  selector: 'app-new-item-details',
  templateUrl: './new-item-details.component.html',
  styleUrls: ['./new-item-details.component.scss']
})
export class NewItemDetailsComponent {

  @Input() facade: NewOrderItemFacade ;
  public constructor(public activeModal: NgbActiveModal) {}
}
