<ng-template #mainActions let-price="price" let-btnClassList="btnClassList">
    <button class="btn btn-info" [ngClass]="btnClassList" ngbDropdownItem (click)="offerAction('enable', price)" disabled>
        <svg-icon [src]="iconUrls.check"></svg-icon>
        Enable
    </button>
    <button class="btn btn-success" [ngClass]="btnClassList" ngbDropdownItem (click)="offerAction('disable', price)" disabled>
        <svg-icon [src]="iconUrls.times"></svg-icon>
        Disable
    </button>
</ng-template>
<ng-template #otherActions let-price="price" let-btnClassList="btnClassList">
    <button class="btn btn-success" [ngClass]="btnClassList" ngbDropdownItem (click)="offerAction('unlink', price)"
            *ngIf="!price || price?.offer?.linked">
        <svg-icon [src]="iconUrls.unlink"></svg-icon>
        Unlink
    </button>
    <button class="btn btn-success" [ngClass]="btnClassList" ngbDropdownItem (click)="offerAction('remove', price)">
        <svg-icon [src]="iconUrls.trash"></svg-icon>
        Remove
    </button>
    <button class="btn btn-success" [ngClass]="btnClassList" ngbDropdownItem (click)="offerAction('ignore', price)">
        <svg-icon [src]="iconUrls.exclamationCircle"></svg-icon>
        Ignore 'Yes'
    </button>
    <button class="btn btn-success" [ngClass]="btnClassList" ngbDropdownItem (click)="offerAction('unignore', price)">
        <svg-icon [src]="iconUrls.circleRegular"></svg-icon>
        Ignore 'No'
    </button>
</ng-template>
<app-loader *ngIf="loader"></app-loader>

<div class="row justify-content-md-between align-items-center w-100 ms-0 me-0 px-1 pb-sm-2">
    <div class="col-sm-7 col-md-5 col-xl-4 d-flex align-items-center justify-content-md-start justify-content-end p-0">
        <label for="warehouseFilter" class="form-label mt-1">Warehouse</label>
        <ng-select
                [ngStyle]="{ width: warehouses?.length ? '245px' : 'auto'}"
                class="ng-select-sm ms-2"
                id="warehouseFilter"
                [clearable]="false"
                [placeholder]="!warehouses?.length ? 'There are no active warehouses' : 'Select Warehouse'"
                [(ngModel)]="selectedWarehouse"
                (ngModelChange)="queryBuilder.handleFilter('warehouse', selectedWarehouse.id)"
                [items]="warehouseList | orderBy: 'name': 'desc'"
                groupBy="group"
                bindLabel="name"
        >
            <ng-template ng-optgroup-tmp let-item="item">
                {{item.name}}
            </ng-template>
            <ng-template ng-label-tmp let-item="item">
                <div class="d-flex justify-content-between">
                    <div class="text-truncate w-155px">{{ item.name }}</div>
                    <div class="fst-italic" *ngIf="!item?.enabled">Disabled</div>
                </div>
            </ng-template>
        </ng-select>
        <button role="button" class="btn ms-1 btn-primary btn-sm" (click)="warehousesChangedHandler()" [disabled]="!selectedWarehouse">
            Choose
        </button>
    </div>

    <div class="col-12 col-md-4">
        <b>Last inventory sync:</b> {{ selectedWarehouse?.stockLastUpdated ? (selectedWarehouse.stockLastUpdated | bdate: 'dateTime') : 'No info' }}
    </div>

    <div class="col-sm-5 col-md-3 col-xl-4 d-flex align-items-center justify-content-end px-0 pt-2 pt-sm-0"
         *ngIf="pricesLoaded && totals">

        <div ngbDropdown class="d-sm-inline-block">
            <button class="btn btn-light btn-sm me-2" ngbDropdownToggle id="dropdownPricesExport">Export</button>
            <div ngbDropdownMenu aria-labelledby="dropdownPricesExport">
                <button class="btn btn-sm" (click)="processExport('all');" ngbDropdownItem>Export All</button>
                <button class="btn btn-sm" (click)="processExport('unlinked');" ngbDropdownItem>Export Unlinked</button>
                <button class="btn btn-sm" (click)="processExport('filtered');" ngbDropdownItem>Export Filtered
                </button>
            </div>
        </div>

        <button class="btn btn-primary btn-sm" (click)="processPrice()">Add product</button>

        <div class="d-none d-sm-inline-block" *ngIf="massActionService.checkMassButton(prices) || useMassActionByFilters"
             ngbDropdown placement="bottom-right">
            <button ngbDropdownToggle id="mass_actions" class="btn btn-brand btn-sm ms-2">Mass Actions</button>
            <div ngbDropdownMenu aria-labelledby="mass_actions">
                <ng-container *ngTemplateOutlet="mainActions"></ng-container>
                <ng-container *ngTemplateOutlet="otherActions"></ng-container>
            </div>
        </div>
        <div class="mass-btn-wrapper d-sm-none" *ngIf="massActionService.checkEntry(prices, false) || useMassActionByFilters">
            <div class="btn-group btn-group-sm inner" ngbDropdown>
                <ng-container *ngTemplateOutlet="mainActions;context:{btnClassList:'text-white'}"></ng-container>
                <button ngbDropdownToggle class="btn btn-brand rounded-0" id="dropdownActions">
                    <svg-icon [src]="iconUrls.ellipsis"></svg-icon>
                    <span class="ms-2">More</span>
                </button>
                <div ngbDropdownMenu aria-labelledby="dropdownActions" class="dropdown-menu text-center w-100 m-0 p-0">
                    <ng-container *ngTemplateOutlet="otherActions;context:{btnClassList:'list-group-item'}"></ng-container>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="flex-column flex-sm-row row justify-content-md-between align-items-center mt-2 mb-sm-0">
    <div class="col mb-2 mb-sm-0" *ngIf="totals?.total">
        <button class="alert alert-warning mb-1 p-2"
                *ngIf="massActionService.getResult?.length as selectedTotals"
                (click)="toggleMassAction()"
        >
            <ng-container *ngIf="!useMassActionByFilters">All ({{selectedTotals}}) items on this page are selected. </ng-container>
            <span class="text-decoration-underline">{{ useMassActionByFilters ? 'Deselect' : 'Select'}} all {{totals?.total}} items matching current filters</span>
        </button>
    </div>

    <div class="col mb-2 mb-sm-0 text-end" *ngIf="pricesLoaded && totals">
        <div class=" mb-1 mb-sm-0" *ngIf="loaderTotals">
            Loading totals:
            <app-inline-loader class="ms-2"></app-inline-loader>
        </div>
        <div class="mb-1 mb-sm-0 d-flex flex-wrap justify-content-end" *ngIf="!loaderTotals && !showErrorOnTotals">
            <div><b>Online:</b> {{ totals?.online }},</div>
            <div><b>Offline:</b> {{ offlineTotals }},</div>
            <div><b>Disabled:</b> {{totals?.disabled}},</div>
            <div><b>To be linked:</b> {{totals?.unlinked}}</div>
        </div>
        <div class=" mb-1 mb-sm-0" *ngIf="showErrorOnTotals && !loaderTotals">
            Totals not calculated
        </div>
    </div>
</div>

<div class="card shadow">
    <div class="card-body p-0" style="position: relative;" *ngIf="pricesLoaded">
        <div class="table-wrapper overflow-auto">
            <app-loader *ngIf="gridLoader"></app-loader>

            <table class="table table-bordered table-hover">
                <thead>
                <tr>
                    <th>
                        <app-th [colKey]="'brand'"
                                [config]="cols.brand"
                                [queryBuilder]="queryBuilder"
                                [name]="'Brand'"
                                [placeholder]="'Brand'"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th>
                        <app-th [colKey]="'number'"
                                [config]="cols.number"
                                [queryBuilder]="queryBuilder"
                                [name]="'PN'"
                                [placeholder]="'PN'"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th>
                        PN Boodmo
                        <!--                        <app-th [colKey]="'partNumber'"-->
                        <!--                                [config]="cols.partNumber"-->
                        <!--                                [queryBuilder]="queryBuilder"-->
                        <!--                                [name]="'PN Boodmo'"-->
                        <!--                                [placeholder]="'PN Boodmo'"-->
                        <!--                                (update)="applyFilters()"-->
                        <!--                        ></app-th>-->
                    </th>
                    <th class="d-none d-sm-table-cell" style="width: 15%">
                        <app-th [colKey]="'name'"
                                [config]="cols.name"
                                [queryBuilder]="queryBuilder"
                                [name]="'Name'"
                                [placeholder]="'Name'"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'mrp'"
                                [config]="cols.mrp"
                                [queryBuilder]="queryBuilder"
                                [name]="'MRP'"
                                (sort)="sort($event)"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th>
                        <app-th [colKey]="'price'"
                                [config]="cols.price"
                                [queryBuilder]="queryBuilder"
                                [name]="'Price'"
                                (sort)="sort($event)"
                                (update)="applyFilters()"
                                [dropdownPosition]="isSmallDisplay ? 'bottom-right' : 'bottom-left'"
                        ></app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'purchasePrice'"
                                [config]="cols.purchasePrice"
                                [queryBuilder]="queryBuilder"
                                [name]="'Stock Val'"
                                (sort)="sort($event)"
                                (update)="applyFilters()"
                                [dropdownPosition]="isSmallDisplay ? 'bottom-right' : 'bottom-left'"
                        ></app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'stock'"
                                [config]="cols.stock"
                                [queryBuilder]="queryBuilder"
                                [name]="'Stock'"
                                (sort)="sort($event)"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'lastStockUpdate'"
                                [config]="cols.lastStockUpdate"
                                [queryBuilder]="queryBuilder"
                                [name]="'Last stock update'"
                                [dateWithTime]="true"
                                (sort)="sort($event)"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'online'"
                                [config]="cols.online"
                                [queryBuilder]="queryBuilder"
                                [name]="'Online'"
                                (update)="applyFilters()"
                                [showAllOption]="true"
                                [inputWidth]="'90px'"
                        >
                            <ng-container class="options">
                                <option [value]="true">Yes</option>
                                <option [value]="false">No</option>
                            </ng-container>
                        </app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'ignored'"
                                [config]="cols.ignored"
                                [queryBuilder]="queryBuilder"
                                [name]="'Ignore'"
                                (update)="applyFilters()"
                                [showAllOption]="true"
                                [inputWidth]="'90px'"
                        >
                            <ng-container class="options">
                                <option [value]="true">Yes</option>
                                <option [value]="false">No</option>
                            </ng-container>
                        </app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'enabled'"
                                [config]="cols.enabled"
                                [queryBuilder]="queryBuilder"
                                [name]="'Enabled'"
                                (update)="applyFilters()"
                                [showAllOption]="true"
                                [inputWidth]="'90px'"
                        >
                            <ng-container class="options">
                                <option [value]="true">Yes</option>
                                <option [value]="false">No</option>
                            </ng-container>
                        </app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'lastImported'"
                                [config]="cols.lastImported"
                                [queryBuilder]="queryBuilder"
                                [name]="'Last imported'"
                                [dateWithTime]="true"
                                (sort)="sort($event)"
                                (update)="applyFilters()"
                        ></app-th>
                    </th>
                    <th class="d-none d-sm-table-cell">
                        <app-th [colKey]="'linked'"
                                [config]="cols.linked"
                                [queryBuilder]="queryBuilder"
                                [name]="'Linked'"
                                [showAllOption]="true"
                                [inputWidth]="'90px'"
                                (update)="applyFilters()"
                        >
                            <ng-container class="options">
                                <option [value]="true">Yes</option>
                                <option [value]="false">No</option>
                            </ng-container>
                        </app-th>
                    </th>
                    <th style="width:30px" class="position-relative row-toggler-wrap--right p-0">
                        <div class="row-toggler w-100"
                             (click)="massActionService.entry(prices, $event)"
                             [ngClass]="massActionService.getClassByGroup(fakeGroup)">
                            <svg-icon [src]="iconUrls[massActionService.getIconByGroup(fakeGroup)]"></svg-icon>
                        </div>
                    </th>
                </tr>
                </thead>
                <tbody>
                <ng-container *ngFor="let price of prices | paginate: { itemsPerPage: queryBuilder.getLimit(), currentPage: queryBuilder.getPage(), totalItems: totals?.total }; let i = index">
                    <tr (click)="openDetail(price)">
                        <td class="position-relative row-toggler-wrap--left">
                            <div class="row-toggler" container="body" ngbDropdown>
                                <div [id]="'dropdownToggleMore' + i"
                                     ngbDropdownToggle
                                     (click)="$event.stopPropagation()">
                                    <svg-icon [src]="iconUrls.ellipsis"></svg-icon>
                                </div>
                                <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownToggleMore' + i">
                                    <button class="btn btn-success"
                                            ngbDropdownItem
                                            (click)="processPrice(price)">
                                        <svg-icon [src]="iconUrls.edit"></svg-icon>
                                        Edit
                                    </button>
                                    <ng-container *ngTemplateOutlet="mainActions;context:{price: price}"></ng-container>
                                    <button class="btn btn-success"
                                            ngbDropdownItem
                                            *ngIf="!price?.offer?.linked"
                                            (click)="link(price)">
                                        <svg-icon [src]="iconUrls.link"></svg-icon>
                                        Link
                                    </button>
                                    <ng-container *ngTemplateOutlet="otherActions;context:{price: price}"></ng-container>
                                </div>
                            </div>
                            {{ price.offer.brand }}
                        </td>
                        <td>
                            {{price.offer.partNumber}}
                        </td>
                        <td>
                            <app-part-link [item]="price.offer" [name]="price.offer.boodmoPartNumber || '-'"></app-part-link>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            {{ price.offer.name }}
                        </td>
                        <td class="d-none d-sm-table-cell">
                            {{ price.offer.mrp | indianCurrency }}
                        </td>
                        <td>
                            {{ price.offer.price | indianCurrency }}
                        </td>
                        <td class="d-none d-sm-table-cell">
                            {{ price.offer.purchasePrice | indianCurrency }}
                        </td>
                        <td class="d-none d-sm-table-cell">
                            {{ price.offer.qty }}
                        </td>
                        <td class="d-none d-sm-table-cell">
                            {{ price.offer.lastStockUpdate | bdate:'dateTime' }}
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <span [ngClass]="price?.offer.online ? 'text-success' : 'text-danger'" class="text-nowrap">
                                <svg-icon [src]="iconUrls.circle"></svg-icon>
                                {{ price?.offer.online | booleanLabel }}
                            </span>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <span [ngClass]="price?.offer.ignored?'text-danger':'text-success'" class="text-nowrap">
                                <svg-icon [src]="iconUrls.circle"></svg-icon>
                                {{ price?.offer.ignored | booleanLabel }}
                            </span>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <span [ngClass]="price?.offer.enabled ? 'text-success' : 'text-danger'" class="text-nowrap">
                                <svg-icon [src]="iconUrls.circle"></svg-icon>
                                {{ price?.offer.enabled | booleanLabel }}
                            </span>
                        </td>
                        <td class="d-none d-sm-table-cell">
                            {{ price.offer.lastImported | bdate:'dateTime' }}
                        </td>
                        <td class="d-none d-sm-table-cell">
                            <span [ngClass]="price?.offer.linked ? 'text-success' : 'text-danger'" class="text-nowrap">
                                <svg-icon [src]="iconUrls.circle"></svg-icon>
                                {{ price?.offer.linked | booleanLabel }}
                            </span>
                        </td>
                        <td style="width:30px" class="position-relative row-toggler-wrap--right p-0">
                            <button class="row-toggler w-100"
                                    [class.row-toggler--secondary]="useMassActionByFilters"
                                    [disabled]="useMassActionByFilters"
                                    (click)="massActionService.entry([price], $event)"
                                    [ngClass]="massActionService.getClass(price)">
                                <svg-icon [src]="iconUrls[useMassActionByFilters ? 'checkSquare' : massActionService.getIcon(price)]"></svg-icon>
                            </button>
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>

        <app-tfoot [queryBuilder]="queryBuilder"
                   [dataList]="prices"
                   [loader]="loader && loaderTotals"
                   (changePageNum)="onChangePagination($event)"
                   [total]="totals?.total"
                   [useMoreItems]="true"
        ></app-tfoot>
    </div>
</div>
