import { DateUtil } from 'src/app/application/utils/date/date.util';
import {ShippingAddress} from '../../vo/shipping-address/shipping-address.vo';

export class Warehouse {
  private constructor(
    private _id: string,
    private _enabled: boolean,
    private _name: string,
    private _confirmationDays: number,
    private _dispatchDays: number,
    private _residenceName: string | null,
    private _priceActive: number,
    private _lastImport: Date,
    private _manageQty: boolean,
    private _fulfilledStock: boolean,
    private _stockLastUpdated: Date | null,
    private _shippingAddress?: ShippingAddress
  ) {
  }

  get id(): string {
    return this._id;
  }

  get enabled(): boolean {
    return this._enabled;
  }

  get name(): string {
    return this._name;
  }

  get confirmationDays(): number {
    return this._confirmationDays;
  }

  get dispatchDays(): number {
    return this._dispatchDays;
  }

  get residenceName(): string | null {
    return this._residenceName;
  }

  get priceActive(): number {
    return this._priceActive;
  }

  get lastImport(): Date {
    return this._lastImport;
  }

  get canEdit(): boolean {
    return true;
  }

  get manageQty(): boolean {
    return this._manageQty;
  }

  get fulfilledStock(): boolean {
    return this._fulfilledStock;
  }

  get stockLastUpdated(): Date | null {
    return this._stockLastUpdated;
  }

  get online(): boolean {
    return this.enabled && this.residenceName !== null;
  }

  get shippingAddress(): ShippingAddress
  {
    return this._shippingAddress;
  }

  // TODO add field 'last_import'
  static build = (obj: any): Warehouse => new Warehouse(
      obj.id,
      obj.enabled,
      obj.name,
      obj.confirmation_days,
      obj.dispatch_days,
      obj.residence_name,
      obj.price_active,
      DateUtil.convertTimestampToDate(obj.price_last_imported),
      obj.manage_qty,
      obj.fulfilled_stock,
      DateUtil.convertTimestampToDate(obj.stockLastUpdated),
      obj.address ? ShippingAddress.build(obj.address) : null,
  );
}
