import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {AbstractRepository} from './abstract.repository';
import {ShipmentInfoResponse} from '../response/shipment-info.response';
import {PackedShipmentResponse} from '../response/packed-shipment.response';
import {BoxDimensionsInterface} from '../../domain/models/box-dimensions.interface';
import {CreateStatusMessageInterface} from '../../domain/models/create-status-message.interface';

@Injectable({
  providedIn: 'root'
})
export class ShipmentRepository extends AbstractRepository {

  createBox = (
    residence: string,
    shipment_params: BoxDimensionsInterface,
    items: {
      item_id: string;
      qty: number;
    }[]
  ): Observable<CreateStatusMessageInterface> => this.httpClient.post<CreateStatusMessageInterface>(this.buildUrl('sales/shipment'), {
      residence,
      shipping_box: `BOX/${Date.now()}`,
      shipment_params,
      items
    });

  fetchPackedBoxList = (): Observable<{data: PackedShipmentResponse[]}> =>
    this.httpClient.get<{ data: PackedShipmentResponse[] }>(this.buildUrl('sales/shipment-items'));

  fetchShipmentInfo = (id: string): Observable<{ result: ShipmentInfoResponse; code: number; message: string }> =>
    this.httpClient.get<{ result: ShipmentInfoResponse; code: number; message: string }>(this.buildUrl(`sales/shipment/${id}/info`));
}
