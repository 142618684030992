import { ColumnInterface, ConfigInterface } from '../../components/th/th.component';

export class GridHelper
{
    static ClearSorting(cols: Record<string, ColumnInterface>, sortCol?: ConfigInterface): void
    {
        Object.values(cols).forEach((col) => {
            if (col.sort && (!sortCol || sortCol && sortCol !== col.sort)) {
                col.sort.applied = false;
                col.sort.value = null;
            }
        });
    }

    static ClearFilters(cols: Record<string, ColumnInterface>, sort?: boolean): void
    {
        Object.values(cols).forEach((col) => {
            if (sort && col.sort) {
                col.sort.applied = false;
                col.sort.value = null;
            }

            if (col.filter) {
                col.filter.applied = false;
                col.filter.value = null;
            }
        });
    }
}
