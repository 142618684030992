<div class="modal-header px-3 py-2">
  <h5 class="modal-title text-center flex-grow-1">Shipment details</h5>

  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body">
  <div class="row row-cols-1 row-cols-lg-2">
    <div class="col mb-3">
      <h6>Warehouse</h6>
      {{warehouse?.name || 'HARD-CODE!!!'}}
    </div>
    <div class="col mb-3">
      <h6>Shipping service</h6>
      Boodmo shipping
      <div *ngIf="!isPacked">(Allocation will be done automatically after package confirmation)</div>
    </div>
  </div>
  <div class="row row-cols-1 row-cols-lg-2">
    <div class="col mb-3" *ngIf="pickupAddress">
      <h6>Pickup address</h6>
      <div>{{pickupAddress.addressLine}}</div>
      <div>{{pickupAddress.cityName}}</div>
      <div>{{pickupAddress.state.name}}</div>
      <div>{{pickupAddress.pin}}</div>
    </div>

    <div class="col mb-3" *ngIf="isPacked">
      <h6>Carrier</h6>
      <p>{{shipmentInfo?.carrierName || 'No data'}}</p>

      <h6>Tracking number</h6>
      <p>{{shipmentInfo?.trackNumber || 'No data'}} (ETA: {{shipmentInfo?.shipmentEta | bdate}})</p>
    </div>
  </div>

  <ng-container *ngIf="!isPacked; else BoxesTemplateRef">
  <!-- TODO where get box.number?-->
    <ng-container *ngTemplateOutlet="BoxTemplateRef; context{boxNumber: '1', items: items}"></ng-container>
  </ng-container>

  <ng-template #BoxesTemplateRef>
    <div *ngFor="let box of shipmentInfo?.boxes">
      <ng-container *ngTemplateOutlet="BoxTemplateRef; context{boxNumber: box.number, items: box.items}"></ng-container>
    </div>
  </ng-template>

  <ng-template #BoxTemplateRef let-boxNumber="boxNumber" let-items="items">
    <h5>Box {{boxNumber}}</h5>
    <ul class="list-group">
      <li class="list-group-item" *ngFor="let item of items">

        <button *ngIf="!isPacked" type="button" class="btn-close close" aria-label="Close" (click)="removeItem(item)"></button>

        <div>PN: {{item.partNumber}}</div>
        <div>Name: {{item.partName}}</div>
        <div>Brand: {{item?.brandName || item.brand}}</div>
        <div *ngIf="isPacked && item?.status" class="text-danger">{{item?.status}}</div>
        <div>
          <label [for]="item.id">Qty</label>
          <ng-template #qtyRef>: {{item.qty}}</ng-template>
          <input *ngIf="!isPacked; else qtyRef"
            type="number" step="1" min="1" width="50"
                 class="form-control form-control-sm"
                 required
                 [max]="item.qty - item.packedQty"
                 [id]="item.id"
                 [(ngModel)]="qtyMap[item.id]"
                 [appNumeric]
                 [appMaxNumber]="item.qty - item.packedQty">
        </div>
      </li>
    </ul>

    <div class="my-3 dimensions-form list-group-item rounded" *ngIf="isPacked">
      <div class="d-sm-inline">
        <b>Box Dimensions:</b>
      </div>
      <div class="d-sm-inline d-flex text-start" style="flex-wrap: wrap">
        <div class="d-inline-block ps-2" *ngFor="let key of ['height', 'width', 'length', 'weight']">
          {{key | titlecase}}: {{shipmentInfo?.dimensions[key]}} {{key === 'weight' ? 'kg' : 'cm'}}
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="modal-footer btn-modal-wrapp">
  <form class="mb-3 dimensions-form"
        *ngIf="!isPacked"
        [formGroup]="form"
        [ngClass]="{'was-validated' : fb.isSubmitted()}"
        (ngSubmit)="tryPack()"
  >
    <div class="g-1 row mb-3 align-items-baseline text-start">
      <div class="col-12 col-lg-auto">
        <b>Box Dimensions:</b>
      </div>

      <div class="col-6 col-lg-auto" *ngFor="let key of ['height', 'width', 'length', 'weight']">
        <label [for]="key">{{key | titlecase}}:</label>
        <input type="number" [step]="key==='weight'?fb.stepWeight:fb.step"
               [min]="key==='weight'?fb.minWeight:fb.min"
               [id]="key" [name]="key" [appNumeric]="key==='weight'?2:0"
               [placeholder]="key==='weight' ? 'kg' : 'cm'"
               required class="form-control form-control-sm mb-2 me-sm-2"
               [formControlName]="key">
        <app-form-control-error [control]="form.get(key)"
                                [formSubmit]="fb.isSubmitted()"
        ></app-form-control-error>
        <div class="invalid-feedback" *ngIf="form.getError('step', key) && key!=='weight'">{{fb.getErrorMsg('step')}}</div>
      </div>
    </div>
    <button type="submit" hidden #submitButton>submit</button>
  </form>

  <button type="submit" class="btn btn-danger btn-modal"
          *ngIf="isPacked"
          (click)="tryCancelOrder()"
  >Cancel Order</button>
  <button type="button" class="btn btn-outline-secondary btn-modal" (click)="activeModal.dismiss()">Close</button>
  <button type="submit" class="btn btn-success btn-modal"
          *ngIf="!isPacked"
          (click)="tryPack()"
  >Confirm pack</button>
</div>
