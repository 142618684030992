import { OfferResponse } from '../../../infrastructure/response/offer/offer.response';
import { Warehouse } from '../warehouse/warehouse.entity';
import {DateUtil} from '../../../application/utils/date/date.util';

export class Offer
{
    private constructor(
        private _id: string,
        private _brand: string,
        private _partId: number | null,
        private _name: string,
        private _partNumber: string,
        private _price: number,
        private _purchasePrice: number,
        private _mrp: number,
        private _qty: number,
        private _ignored: boolean,
        private _enabled: boolean,
        private _lastImported: Date,
        private _freezeTime: Date | null,
        private _dispatchDays: number,
        private _lastStockUpdate: Date | null,
        private _warehouse: Warehouse,
        private _boodmoPartNumber: string | null,
    )
    {}

    get id(): string
    {
        return this._id;
    }

    get brand(): string
    {
        return this._brand;
    }

    get partId(): number | null
    {
        return this._partId;
    }

    get name(): string
    {
        return this._name;
    }

    get partNumber(): string
    {
        return this._partNumber;
    }

    get price(): number
    {
        return this._price;
    }

    get purchasePrice(): number
    {
        return this._purchasePrice;
    }

    get mrp(): number
    {
        return this._mrp;
    }

    get qty(): number
    {
        return this._qty;
    }

    get lastImported(): Date
    {
        return this._lastImported;
    }

    get freezeTime(): Date | null
    {
        return this._freezeTime;
    }

    get dispatchDays(): number
    {
        return this._dispatchDays;
    }

    get lastStockUpdate(): Date | null
    {
        return this._lastStockUpdate;
    }

    get inStock(): string | number
    {
        return this._warehouse.manageQty ? this.qty : 'Not managed';
    }

    get online(): boolean
    {
        return this._warehouse.online
            && this.linked
            && this.enabled
            && DateUtil.addDays(this.lastImported, this._warehouse.priceActive).getTime() > Date.now();
    }

    get stockValidTill(): Date
    {
        return DateUtil.addDays(this.lastImported, this._warehouse.priceActive);
    }

    get linked(): boolean
    {
        return this.partId !== null;
    }

    get ignored(): boolean
    {
        return this._ignored;
    }

    set ignored(ignored: boolean)
    {
        this._ignored = ignored;
    }

    get enabled(): boolean
    {
        return this._enabled;
    }

    set enabled(enabled: boolean)
    {
        this._enabled = enabled;
    }

    get boodmoPartNumber(): string | null
    {
        return this._boodmoPartNumber;
    }

    static build = (object: OfferResponse, warehouse: Warehouse): Offer => new Offer(
        object.id,
        object.brand,
        object.part_id,
        object.name,
        object.number,
        object.price,
        object.purchase_price,
        object.mrp,
        object.qty,
        object.ignored,
        object.enabled,
        DateUtil.convertStringToDate(object.last_imported_at),
        DateUtil.convertStringToDate(object.freeze_time),
        object.dispatch_days,
        DateUtil.convertStringToDate(object.last_stock_update),
        warehouse,
        object.part_number
    );
}
