import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CatalogRepository } from '../../infrastructure/repositories/catalog.repository';
import {BrandResponse} from '../../infrastructure/response/brand-response';
import {ClassResponse} from '../../infrastructure/response/class-response';

export const ANY_BRAND = {id: null, name: 'Any brand', sort: ''};
export const ANY_CLASS = {id: null, name: 'Any class', sort: ''};

@Injectable({
    providedIn: 'root'
})
export class CatalogService
{

    constructor(private catalogRepository: CatalogRepository)
    {}

    fetchBrandsList = (): Observable<BrandResponse[]> => this.catalogRepository.fetchBrandsList().pipe(map(({items}) =>
        this.sortBrandsOrClassesLists(items)));

    // fetchClassesList = (): Observable<ClassResponse[]> => this.catalogRepository.fetchClassesList().pipe(map(({items}) =>
    //     this.sortBrandsOrClassesLists(items)));

    private sortBrandsOrClassesLists = (list: (BrandResponse | ClassResponse)[]): (BrandResponse | ClassResponse)[] =>
        list.map((item: BrandResponse | ClassResponse) => {
            item.sort = item.name.toLowerCase();
            return item;
        });
}
