import {Component, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

import {ProcessPriceForm} from '../../../forms/process-price.form';
import {ToastrService} from '../../../services/toastr.service';
import {PriceListService} from '../../../services/price-list.service';
import {SellerService} from '../../../services/seller.service';
import {StatusMessageInterface} from '../../../../domain/models/status-message.interface';
import {CurrentSeller} from '../../../../domain/entity/current-seller/current-seller.entity';
import {Warehouse} from '../../../../domain/entity/warehouse/warehouse.entity';
import {OfferFacade} from '../../../facades/offer/offer.facade';
import {ImportService} from '../../../services/import.service';

@Component({
  selector: 'app-process-price',
  templateUrl: './process-price.component.html',
  styleUrls: ['./process-price.component.scss']
})
export class ProcessPriceComponent implements OnInit {
  @Input() offerFacade: OfferFacade;
  @Input() warehouse: Warehouse;
  @Input() disableChangeEnabled?: boolean;
  currentSeller: CurrentSeller;
  loader: Subscription | boolean;
  fb: ProcessPriceForm;
  form: UntypedFormGroup;
  isNewOffer: boolean;
  title: string;
  brandList: string[];
  brandListLoader: Subscription | boolean;

  constructor(
    private priceListService: PriceListService,
    private sellerService: SellerService,
    private toastrService: ToastrService,
    private importService: ImportService,
    public activeModal: NgbActiveModal
  ) {
  }

  ngOnInit(): void {
    this.isNewOffer = !this.offerFacade;
    this.title = `${this.isNewOffer ? 'Add new ' : ' Edit'} price`;

    this.fb = new ProcessPriceForm(this.warehouse?.manageQty, this.offerFacade?.offer, this.disableChangeEnabled);
    this.form = this.fb.getForm();
    this.currentSeller = this.sellerService.currentSeller;
    this.brandListLoader = this.importService.loadMapping().pipe(finalize(() => {
      this.brandListLoader = false;
    })).subscribe((brands) => {
      this.brandList = brands?.map(brand => brand.brand_name);
    });
  }

  selectBrand(brand: string): void
  {
    this.form.patchValue({brand});
  }

  trySubmit(): void {
    this.fb.markSubmitted();
    if (!this.form.valid) {
      return;
    }
    const action = this.offerFacade
      ? this.priceListService.updateOffer(this.form.value, this.offerFacade.id)
      : this.priceListService.createOffer(Object.assign(this.form.value, {warehouse: this.warehouse.id}));
    this.loader = action.pipe(
      finalize(() => {
        this.loader = false;
      })
    ).subscribe((response: StatusMessageInterface) => {
      this.toastrService.successNotify(response);
      this.activeModal.close('success');
    });
  }

  changePrice(type?: 'price' | 'purchasePrice'): void {
    for (const control of type ? [type] : ['price', 'purchasePrice']) {
      this.fb.setPriceControl(control as 'price' | 'purchasePrice');
    }
  }

  changeQty(qty: string) {
    this.form.patchValue({
      enabled: !!qty
    });
    this.form.controls.enabled[+qty? 'enable': 'disable']();
  }
}
