<div class="modal-header">
  <h5 class="modal-title">residence details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="facade">
  <p class="row">
    <b class="col-5">Display name:</b>
    <span class="col-7">{{ facade.residence.name }}</span>
  </p>
  <p class="row">
    <b class="col-5">GST:</b>
    <span class="col-7">{{ facade.residence.billingAddress.gstNumber }}</span>
  </p>
  <p class="row">
    <b class="col-5">Billing address:</b>
    <span class="col-7" [innerHTML]="facade.residence.billingAddress.convertAddressToLine | safeHtml"></span>
  </p>
  <p class="row">
    <b class="col-5">Shipping address:</b>
    <span class="col-7" [innerHTML]="facade.residence.shippingAddress.convertAddressToLine | safeHtml"></span>
  </p>
  <p class="row">
    <b class="col-5">Type:</b>
    <span class="col-7">{{ facade.type }}</span>
  </p>
  <p class="row">
    <b class="col-5">Shipping:</b>
    <span class="col-7">{{ facade.shipping }}</span>
  </p>

</div>
<div class="modal-footer btn-modal-wrapp">
  <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
