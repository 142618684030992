import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {SalesItem} from '../../../../../domain/entity/sales-item/sales-item.entity';

@Component({
  selector: 'app-processing-item-details',
  templateUrl: './processing-item-details.component.html',
  styleUrls: ['./processing-item-details.component.scss']
})
export class ProcessingItemDetailsComponent {

  @Input() facade: SalesItem;
  public constructor(public activeModal: NgbActiveModal) {}
}
