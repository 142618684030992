<div class="modal-header">
  <h5 class="modal-title">Packed item details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="facade">
  <p class="row">
    <b class="col-5">Shipment ID:</b>
    <span class="col-7">{{ facade.shipment.id }}</span>
  </p>
  <p class="row">
    <b class="col-5">Carrier:</b>
    <span class="col-7">{{ facade.carrier.id }}</span>
  </p>
  <p class="row">
    <b class="col-5">Invoice total:</b>
    <span class="col-7">{{ facade?.invoiceTotal | indianCurrency}}</span>
  </p>
  <p class="row">
    <b class="col-5">Box count:</b>
    <span class="col-7">{{ facade.boxCount }}</span>
  </p>
  <p class="row">
    <b class="col-5">Weight:</b>
    <span class="col-7">{{ facade.shipment.dimensions.weight }}kg</span>
  </p>
  <p class="row">
    <b class="col-5">Invoice:</b>
<!--    TODO Add link-->
    <span class="col-7">{{ facade.invoiceNumber }}</span>
  </p>
  <p class="row">
    <b class="col-5">AWB:</b>
    <span class="col-7">{{ facade.shipment.trackNumber }}</span>
  </p>
  <p class="row">
    <b class="col-5">ETA:</b>
    <span class="col-7">{{ facade?.eta | bdate: 'date' }}</span>
  </p>
  <p class="row">
    <b class="col-5">Track status:</b>
    <span class="col-7">{{ facade.shipment.trackStatus }}</span>
  </p>

</div>
<div class="modal-footer btn-modal-wrapp">
  <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
