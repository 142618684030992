import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

import { AbstractRepository } from './abstract.repository';
import { SellerInfoResponse } from '../response/seller-info.response';
import { StatusMessageInterface } from '../../domain/models/status-message.interface';
import {UpdateSellerRequest} from '../requests/update-seller.request';

@Injectable({
  providedIn: 'root',
})
export class SellerRepository extends AbstractRepository {
  fetchSellerInfo = (id: string): Observable<SellerInfoResponse> => this.httpClient.get<SellerInfoResponse>(this.buildUrl(`srm/seller/${id}/info`));

  updateSellerInfo = (id, obj: UpdateSellerRequest): Observable<StatusMessageInterface> =>
    this.httpClient.put<StatusMessageInterface>(this.buildUrl(`srm/seller/${id}/update`), obj);
}
