<div class="modal-header">
    <h5 class="modal-title">Price details</h5>
    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body" *ngIf="price">
    <p class="row">
        <b class="col-5">Brand:</b>
        <span class="col-7">{{ price.offer.brand }}</span>
    </p>
    <p class="row">
        <b class="col-5">Part number:</b>
        {{price.offer.partNumber}}
    </p>
    <p class="row">
        <b class="col-5">Part number boodmo:</b>
        <app-part-link [item]="price.offer" [name]="price.offer.boodmoPartNumber || '-'" class="col-7"></app-part-link>
    </p>
    <p class="row">
        <b class="col-5">Price:</b>
        <span class="col-7">{{ price.offer.price | indianCurrency  }}</span>
    </p>
    <p class="row">
        <b class="col-5">MRP:</b>
        <span class="col-7">{{ price.offer.mrp | indianCurrency }}</span>
    </p>
    <p class="row">
        <b class="col-5">In Stock:</b>
        <span class="col-7">{{ price.offer.inStock }}</span>
    </p>
    <p class="row">
        <b class="col-5">Part name:</b>
        <span class="col-7">{{ price.offer.partId }}</span>
    </p>
    <p class="row">
        <b class="col-5">Online:</b>
        <span class="col-7">{{ price.offer.online | booleanLabel }}</span>
    </p>
    <p class="row">
        <b class="col-5">Enabled:</b>
        <span class="col-7">{{ price.offer.enabled | booleanLabel }}</span>
    </p>
    <p class="row">
        <b class="col-5">Linked:</b>
        <span class="col-7">{{ price.offer.partId | booleanLabel  }}</span>
    </p>
    <p class="row">
        <b class="col-5">Last imported:</b>
        <span class="col-7">{{ price.offer.lastImported | bdate:'dateTime' }}</span>
    </p>
    <p class="row">
        <b class="col-5">Stock Valid Till:</b>
        <span class="col-7">{{ price.offer.stockValidTill | bdate }}</span>
    </p>

</div>
<div class="modal-footer btn-modal-wrapp">
    <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
