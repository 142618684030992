<div class="modal-header px-3 py-2">
    <h5 class="modal-title text-center flex-grow-1">{{ mapping ? 'Edit' : 'Create' }} Brand Mapping</h5>

    <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
    <form novalidate [formGroup]="form" [ngClass]="{'was-validated' : fb.isSubmitted()}">
        <div class="mb-3 row">
            <label class="form-label col-sm-4" for="brandName">Brand Name:</label>
            <div class="col-sm-8">
                <input type="text" id="brandName" class="form-control form-control-sm"
                       formControlName="brandName" [readOnly]="!!mapping">
                <div class="invalid-feedback"
                     *ngIf="form.getError('required', 'brandName')">{{fb.getErrorMsg('required')}}</div>
            </div>
        </div>

        <div class="mb-3 row">
            <label class="form-label col-sm-4">Brand Name in boodmo.com:</label>
            <div class="col-sm-8">
                <div ngbDropdown #brandDrop="ngbDropdown" autoClose="outside">
                    <button class="form-control form-control-sm d-flex align-items-center justify-content-between"
                            [class.is-valid]="form.get('brandId').valid && fb.isSubmitted()"
                            [class.is-invalid]="!form.get('brandId').valid && fb.isSubmitted()"
                            id="brandDropdown"
                            (click)="$event.preventDefault()"
                            ngbDropdownToggle
                    >
                        {{ form.get('boodmoName').value || 'No selected brand' }}
                    </button>

                    <div ngbDropdownMenu aria-labelledby="brandDropdown" class="w-100">
                        <div class="p-1">
                            <input type="text" class="form-control form-control-sm" [(ngModel)]="filter.value" [ngModelOptions]="{standalone: true}"
                                   #filter>
                        </div>
                        <div class="dropdown-inner" *ngIf="brandDrop.isOpen()">
                            <button *ngFor="let brand of brands | filterBy: filter.value: 'name'"
                                    (click)="selectBrand(brand); brandDrop.close()"
                                    ngbDropdownItem
                            >
                                {{brand.name}}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="invalid-feedback"
                     *ngIf="form.getError('required', 'brandId')">{{fb.getErrorMsg('required')}}</div>
            </div>
        </div>

        <div class="mb-3 row">
          <label class="form-label col-sm-4" for="additional">Info:</label>
          <div class="col-sm-8">
            <input type="text" id="additional" class="form-control form-control-sm"
                   formControlName="additional">
          </div>
        </div>

        <div class="mb-3 row" *ngIf="mapping">
            <label class="form-label col-2 col-sm-4" for="active">Active:</label>
            <div class="col-10 col-sm-8">
                <input type="checkbox"
                       class="form-check"
                       id="active"
                       formControlName="active">
            </div>
        </div>
    </form>

</div>
<div class="modal-footer p-3 border-0">
    <div class="m-0 mt-2 text-center">
        <button type="button" class="btn btn-secondary btn-sm me-2" (click)="activeModal.dismiss()">Close</button>
        <button type="submit" class="btn btn-primary btn-sm" [disabled]="form.invalid" (click)="processMapping()">
            Save
            <app-inline-loader class="ms-2" *ngIf="inlineLoader"></app-inline-loader>
        </button>
    </div>
</div>
