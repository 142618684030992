import {Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Subscription, zip} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {SalesItemService} from '../../../services/sales-item.service';
import {ColumnInterface, ConfigInterface, FindInListInterface} from '../../../components/th/th.component';
import {UiFilterConfigInterface} from '../../mapping/ui-filter/ui-filter-config.interface';
import {NewOrderItemFacade} from '../../../facades/new-order-item/new-order-item.facade';
import {UiFilter} from '../../mapping/ui-filter/ui-filter';
import {FilterQueryBuilder} from '../../../utils/query-builder/filter-query-builder';
import {Warehouse} from '../../../../domain/entity/warehouse/warehouse.entity';
import {BreadcrumbsService} from '../../../services/breadcrumbs/breadcrumbs.service';
import {CatalogService} from '../../../services/catalog.service';
import {IconUrls} from '../../../../constants/icon-urls';
import {MassActionService} from '../../../services/mass-action.service';
import {SellerService} from '../../../services/seller.service';
import {ToastrService} from '../../../services/toastr.service';
import {CONSTANTS} from '../../../../constants/constants';
import {CancelConfirmationComponent} from '../popups/cancel-confirmation/cancel-confirmation.component';
import {NewItemDetailsComponent} from './new-item-details/new-item-details.component';
import {ConfirmService} from '../../../services/confirm-modal.service';
import {SalesItem} from '../../../../domain/entity/sales-item/sales-item.entity';
import {SalesItemConfirmRequest} from '../../../../infrastructure/requests/sales-item-confirm.request';
import {GridHelper} from '../../../utils/grid-helper/grid-helper';

@Component({
  selector: 'app-new-items',
  templateUrl: './new-items.component.html',
  styleUrls: ['./new-items.component.scss']
})
export class NewItemsComponent implements OnInit, OnDestroy {

  readonly cols: {[key: string]: ColumnInterface} = {
    orderNumber: {
      filter: { value: '', applied: false, name: 'orderNumber', type: 'text' },
    },
    partNumber: {
      filter: { value: '', applied: false, name: 'partNumber', type: 'text' },
    },
    brand: {
      filter: { value: null, applied: false, name: 'brand', type: 'ngSelect' },
    },
    price: {
      filter: { value: '', applied: false, name: 'price', type: 'numberRange', usePice: true },
    },
    total: {
      filter: { value: '', applied: false, name: 'total', type: 'numberRange', usePice: true },
    },
    confirmationBefore: {
      filter: { value: '', applied: false, name: 'confirmationBefore', type: 'dateRange' },
      sort: { name: 'confirmationBefore', value: false, applied: true },
    }
  };

  readonly filterConfig: UiFilterConfigInterface[] = [
    { fieldName: 'orderNumber', type: 'text', filterField: 'orderNumber' },
    { fieldName: 'partNumber', type: 'text', filterField: 'partNumber' },
    { fieldName: 'brand', type: 'select', filterField: 'brand' },
    { fieldName: 'price', type: 'lessThan', filterField: 'priceFrom'},
    { fieldName: 'price', type: 'moreThan', filterField: 'priceTo'},
    { fieldName: 'total', type: 'lessThan', filterField: 'totalFrom'},
    { fieldName: 'total', type: 'moreThan', filterField: 'totalTo'},
    { fieldName: 'confirmationBefore', type: 'dateFrom', filterField: 'confirmationBeforeFrom' },
    { fieldName: 'confirmationBefore', type: 'dateTo', filterField: 'confirmationBeforeTo' },
  ];

  filter: UiFilter<NewOrderItemFacade>;
  readonly queryBuilder: FilterQueryBuilder;
  loader: Subscription | boolean;
  ordersLoaded: boolean;
  orders: NewOrderItemFacade[] = [];
  warehouses: Warehouse[];
  brandList: FindInListInterface[];
  selectedWarehouse: Warehouse = null;
  sortOrder: {
    field: string;
    reverse: boolean;
  };

  private preparePendingRequest: Subscription;
  private pendingRequest: Subscription;

  private filterChangesSubscription: Subscription;
  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private catalogService: CatalogService,
    public iconUrls: IconUrls,
    public salesItemService: SalesItemService,
    public massActionService: MassActionService,
    private sellerService: SellerService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    public confirmService: ConfirmService,
  ) {
    const title = 'Orders - New';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
    this.queryBuilder = new FilterQueryBuilder({
      filterSet: [...Object.values(this.filterConfig).map((c) => c.filterField)],
    });
  }

  get items(): SalesItem[] {
    return this.orders.map((item)=>item.salesItem);
  }

  ngOnInit(): void {
    this.loader = this.preparePendingRequest = zip(
      this.sellerService.$currentSeller,
      this.catalogService.fetchBrandsList(),
    ).subscribe(([seller, brands]) => {
      this.loader = false;
      this.brandList = brands.map(({name}) => ({
        name,
        id: name
      }));
      this.warehouses = seller.warehouses;
      if (this.warehouses?.length) {
        this.selectedWarehouse = this.warehouses[0];
        this.buildOrderListGrid();
      }
      this.massActionService.init();
    }, err => {
      this.loader = false;
    });
  }

  ngOnDestroy(): void {
    this.pendingRequest?.unsubscribe();
    this.preparePendingRequest?.unsubscribe();
  }

  buildOrderListGrid(): void
  {
    this.ordersLoaded = true;
    this.pendingRequest = this.loader = this.salesItemService.fetchNewOrderList(this.selectedWarehouse.id).pipe(
      finalize(() => this.loader = false)
    ).subscribe((dataGrid: NewOrderItemFacade[]) => {
      this.orders = dataGrid;
      this.createFilter(this.orders);
    });
  }

  filterData(items?: NewOrderItemFacade[])
  {
    const sortCol = Object.values(this.cols).find((c) => c.sort?.applied)?.sort;

    this.filter.filter(items);

    if (sortCol) {
      this.filter.sort(sortCol);
      this.queryBuilder.changePageNum(1);
    }
  }

  sort(sortCol: ConfigInterface): void
  {
    this.filter.sort(sortCol);
    this.sortOrder = {
      field: sortCol.name,
      reverse: sortCol.value
    };
    this.queryBuilder.changePageNum(1);
    GridHelper.ClearSorting(this.cols, sortCol);
  }

  confirm(facade?: NewOrderItemFacade): void {
    this.confirmService.confirm({
      title: 'Accept confirmation',
      message: 'Are you sure you want to confirm this item?',
    }).then(() => {
      const itemIds: SalesItemConfirmRequest[] = facade
          ? [{id: facade.id}]
          : this.massActionService.getItems.map(({id}): SalesItemConfirmRequest  => ({id}));
      this.loader = this.salesItemService.confirm(itemIds).pipe(finalize(() => {
        this.loader = false;
      })).subscribe((data) => {
        this.toastrService.successNotify(data);
        this.queryBuilder.changePageNum(1);
        this.buildOrderListGrid();
      });
    }, () => {});
  }

  cancel(facade?: NewOrderItemFacade): void {
    const modalRef = this.modalService.open(CancelConfirmationComponent, {size: 'sm'});
    modalRef.componentInstance.items = facade ? [facade] : this.massActionService.getItems;
    modalRef.result.then((type) => {
      if (type === 'success') {
        const itemIds: string[] = facade ? [facade.id] : this.massActionService.getResult;
        this.loader = this.salesItemService.cancel(itemIds).pipe(finalize(() => {
          this.loader = false;
        })).subscribe((data) => {
          this.toastrService.successNotify(data);
          this.queryBuilder.changePageNum(1);
          this.buildOrderListGrid();
        });
      }
    }, () => {});
  }

  openDetail(facade: NewOrderItemFacade): void {
    if (window.innerWidth > CONSTANTS.MAX_POPUP_SCREEN_SM) {
      return;
    }
    const modalRef = this.modalService.open(NewItemDetailsComponent);
    modalRef.componentInstance.facade = facade;
  }

  private createFilter(orders: NewOrderItemFacade[]): void
  {
    this.filter = new UiFilter(orders, this.filterConfig, this.queryBuilder);
    this.filterChangesSubscription = this.filter.filterChanged$
      .subscribe((v) => this.orders = v);
    const appliedSortingKey = Object.keys(this.cols).find((key) => this.cols[key].sort?.applied);
    if (appliedSortingKey) {
      this.filter.sort(this.cols[appliedSortingKey].sort);
    }
  }
}
