import { Component, Input } from '@angular/core';
import { ImportBatch } from '../../../../domain/entity/import-batch/import-batch.entity';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-import-details',
  templateUrl: './import-details.component.html',
  styleUrls: ['./import-details.component.scss']
})
export class ImportDetailsComponent
{

  @Input() importItem: ImportBatch;

  public constructor(public activeModal: NgbActiveModal)
  { }

}
