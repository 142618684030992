import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {Subscription} from 'rxjs';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {ResidenceFacade} from '../../../facades/residence/residence.facade';
import {ConfirmService} from '../../../services/confirm-modal.service';
import {ToastrService} from '../../../services/toastr.service';
import {ProcessResidenceForm} from '../../../forms/process-residence.form';
import {ResidenceService} from '../../../services/residence.service';
import {StateInterface} from '../../../../domain/models/state.interface';
import {finalize} from 'rxjs/operators';
import {StatusMessageInterface} from '../../../../domain/models/status-message.interface';
import {AddressRequest} from '../../../../infrastructure/requests/address.request';
import {DictionaryService} from '../../../services/dictionary.service';

@Component({
  selector: 'app-process-residence',
  templateUrl: './process-residence.component.html',
  styleUrls: ['./process-residence.component.scss']
})
export class ProcessResidenceComponent implements OnInit, OnDestroy {

  @Input() facade: ResidenceFacade;
  isUpdate: boolean;
  loader: Subscription | boolean;
  form: UntypedFormGroup;
  fb: ProcessResidenceForm;
  states: StateInterface[];
  statesLoading: Subscription | boolean;
  private pendingRequest: Subscription;

  constructor(
    public activeModal: NgbActiveModal,
    public residenceService: ResidenceService,
    public confirmService: ConfirmService,
    private toastrService: ToastrService,
    private dictionaryService: DictionaryService,
  ) { }

  get canEditAddress(): boolean {
    return !this.facade?.residence.isFC;
  }

  ngOnInit(): void {
    this.isUpdate = Boolean(this.facade);
    this.fb = new ProcessResidenceForm(this.isUpdate, this.facade);
    this.form = this.fb.getForm();
    this.pendingRequest = this.statesLoading = this.dictionaryService.loadStateList().pipe(
      finalize(() => {
        this.statesLoading = false;
      })
    ).subscribe((resp) => {
      this.states = resp;
    });
  }

  ngOnDestroy() {
    this.pendingRequest?.unsubscribe();
  }

  trySubmit(): void {
    this.fb.markSubmitted();
    if (!this.form.valid) {
      return;
    }
    const {name, companyName, billingAddress, shippingAddress} = this.form.value;
    let billing_address = billingAddress;
    let shipping_address = shippingAddress;
    if (this.isUpdate) {
      const fillAddress = (type: 'billingAddress' | 'shippingAddress') => {
        const obj: AddressRequest = {
          addressLine: '',
          cityName: '',
          contactFirstName: '',
          contactLastName: '',
          contactPhone: '',
          pin: 0,
          state: '',
          gstNumber: '',
        };
        for (const key of Object.keys(obj)) {
          obj[key] = key !== 'state' ? this.facade.residence[type][key] : this.facade.residence[type][key].abbr;
        }
        return obj;
      };
      billing_address = fillAddress('billingAddress');
      shipping_address = fillAddress('shippingAddress');
    }
    billing_address = Object.assign(billing_address, {
      name,
      companyName
    });
    const action = this.isUpdate
      ? this.residenceService.update(this.facade.residence.id,  name, billing_address, shipping_address)
      : this.residenceService.create(name, billing_address, shipping_address);
    this.loader = action.pipe(
      finalize(() => {
        this.loader = false;
      })
    ).subscribe((response: StatusMessageInterface) => {
      this.toastrService.successNotify(response);
      this.activeModal.close('success');
    });
  }

  handlerChangeState(fgName: string): void {
    this.form.patchValue({
      [fgName]: {
        pin: null,
      }
    });
  }
}
