import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';
import { ImportBatch } from '../../../domain/entity/import-batch/import-batch.entity';
import { FilterQueryBuilder } from '../../utils/query-builder/filter-query-builder';
import { Subscription } from 'rxjs';
import { ToastrService } from '../../services/toastr.service';
import { ImportService } from '../../services/import.service';
import { CONSTANTS } from '../../../constants/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ImportDetailsComponent } from './import-details/import-details.component';
import {ColumnInterface, ConfigInterface, FindInListInterface} from '../../components/th/th.component';
import { finalize } from 'rxjs/operators';
import {CoreService} from '../../services/core.service';
import {CurrentSeller} from '../../../domain/entity/current-seller/current-seller.entity';
import {SellerService} from '../../services/seller.service';
import {ImportBatchFacade} from '../../facades/import-batch/import-batch.facade';
import {WarehouseService} from '../../services/warehouse.service';

const DEFAULT_FILTERS: {[key: string]: ColumnInterface} = {
  batchNumber: {
    filter: { value: '', applied: false, name: 'batch_number', type: 'text' },
    sort: { name: 'batch_number', value: true, applied: true },
  },
  createdAt: {
    filter: {value: '', applied: false, name: 'created', type: 'dateRange'},
    sort: {name: 'created_at', value: null, applied: false},
  },
  warehouse: {
    filter: { value: null, applied: false, name: 'warehouse', type: 'ngSelect' },
  },
  filename: {
    filter: { value: '', applied: false, name: 'original_filename', type: 'text' },
  },
};

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit
{

  public cols: {[key: string]: ColumnInterface} = Object.assign({}, DEFAULT_FILTERS);
  public readonly queryBuilder: FilterQueryBuilder;

  public filtersApplied: boolean;
  public gridLoader: Subscription | boolean;
  public imports: ImportBatchFacade[];
  public itemsPerPage = 10;
  public total: number;
  public ImportBatch = ImportBatch;
  warehouseList: FindInListInterface[];
  public currentSeller: CurrentSeller;
  private pendingRequest: Subscription;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private importService: ImportService,
    private modalService: NgbModal,
    public coreService: CoreService,
    private sellerService: SellerService,
    private warehouseService: WarehouseService,
  )
  {
    const title = 'Dashboard';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
    this.queryBuilder = new FilterQueryBuilder(
        {
          filterSet: [
            'batch_number',
            'createdFrom',
            'createdTo',
            'warehouse',
            'original_filename',
          ]
        },
    );
    this.queryBuilder.handleSort(this.cols.batchNumber.sort.name, this.cols.batchNumber.sort.value ? 'desc' : 'asc');
    this.currentSeller = this.sellerService.currentSeller;
  }

  ngOnInit(): void
  {
    this.loadImports();
    this.warehouseList = this.currentSeller?.warehouses;
  }

  public applyFilters(): void
  {
    this.filtersApplied = true;
    this.onChangePagination();
  }

  public clearFilters(): void
  {
    this.filtersApplied = false;
    Object.values(this.cols).forEach((col) => {
      col.filter.applied = false;
      col.filter.value = null;
    });
    this.queryBuilder.clearFilters();
    this.onChangePagination();
  }

  public loadImports(): void
  {
    this.pendingRequest?.unsubscribe();

    this.pendingRequest = this.gridLoader = this.importService.loadList(this.queryBuilder)
        .pipe(
            finalize(() => this.gridLoader = false),
        )
        .subscribe(
            (response) => {
              this.imports = response?.data;
              this.total = response?.total;
            });
  }

  public onChangePagination(event?: number): void
  {
    this.queryBuilder.changePageNum(event || 1);

    this.loadImports();
  }

  public openDetail(importItem: ImportBatch): void
  {
    if (window.innerWidth > CONSTANTS.MAX_POPUP_SCREEN_SM) {
      return;
    }
    const modalRef = this.modalService.open(ImportDetailsComponent);
    modalRef.componentInstance.importItem = importItem;
  }

  public sort(sortCol: ConfigInterface): void
  {
    Object.values(this.cols).forEach((col) => {
      if (col.sort && sortCol !== col.sort) {
        col.sort.applied = false;
        col.sort.value = null;
      }
    });
    this.onChangePagination();
  }

  goToPriceList(warehouseId: string): void {
    const passedWarehouse = this.currentSeller.warehouses?.find((w) => w.id === warehouseId);
    this.warehouseService.goToPriceListByWarehouseId(warehouseId, passedWarehouse.fulfilledStock);
  }
}
