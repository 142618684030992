import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-part-link',
    templateUrl: './part-link.component.html',
    styleUrls: ['./part-link.component.scss']
})
export class PartLinkComponent implements OnInit {

    @Input() item: {
        partId?: number;
        partNumber?: string;
    };
    @Input() partId?: number | null;
    @Input() partNumber?: string;
    @Input() name?: string;
    @Input() styled?: boolean;

    constructor()
    {}

    public ngOnInit(): void
    {
        this.partId = this.partId || this.item.partId || null;
        this.partNumber = this.partNumber || this.item.partNumber;
        this.name = this.name || this.partNumber;
    }

}
