import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {ColumnInterface, ConfigInterface} from '../../../components/th/th.component';
import {UiFilterConfigInterface} from '../../mapping/ui-filter/ui-filter-config.interface';
import {UiFilter} from '../../mapping/ui-filter/ui-filter';
import {FilterQueryBuilder} from '../../../utils/query-builder/filter-query-builder';
import {Subscription} from 'rxjs';
import {Warehouse} from '../../../../domain/entity/warehouse/warehouse.entity';
import {ProcessingOrderFacade} from '../../../facades/processing-order/processing-order.facade';
import {Title} from '@angular/platform-browser';
import {BreadcrumbsService} from '../../../services/breadcrumbs/breadcrumbs.service';
import {IconUrls} from '../../../../constants/icon-urls';
import {SalesItemService} from '../../../services/sales-item.service';
import {MassActionService} from '../../../services/mass-action.service';
import {SellerService} from '../../../services/seller.service';
import {ToastrService} from '../../../services/toastr.service';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {finalize} from 'rxjs/operators';
import {CancelConfirmationComponent} from '../popups/cancel-confirmation/cancel-confirmation.component';
import {CONSTANTS} from '../../../../constants/constants';
import {SalesItem} from '../../../../domain/entity/sales-item/sales-item.entity';
import {ProcessingOrderDetailsComponent} from './processing-order-details/processing-order-details.component';
import {ProcessingItemDetailsComponent} from './processing-item-details/processing-item-details.component';
import {ShipmentDetailsComponent} from '../popups/shipment-details/shipment-details.component';
import {CreateStatusMessageInterface} from '../../../../domain/models/create-status-message.interface';

@Component({
  selector: 'app-processing-items',
  templateUrl: './processing-items.component.html',
  styleUrls: ['./processing-items.component.scss']
})
export class ProcessingItemsComponent implements OnInit, OnDestroy {
  readonly cols: {[key: string]: ColumnInterface} = {
    createAt: {
      filter: { value: '', applied: false, name: 'createAt', type: 'dateRange' },
      sort: { name: 'createAt', value: false, applied: true },
    },
    orderNumber: {
      filter: { value: '', applied: false, name: 'orderNumber', type: 'text' },
    },
    // shipBy: {
    //   filter: { value: '', applied: false, name: 'shipBy', type: 'dateRange' },
    //   sort: { name: 'shipBy', value: false, applied: false },
    // },
    total: {
      filter: { value: '', applied: false, name: 'total', type: 'numberRange', usePice: true },
    },
  };

  readonly filterConfig: UiFilterConfigInterface[] = [
    { fieldName: 'createAt', type: 'dateFrom', filterField: 'createAtFrom' },
    { fieldName: 'createAt', type: 'dateTo', filterField: 'createAtTo' },
    { fieldName: 'orderNumber', type: 'text', filterField: 'orderNumber' },
    // { fieldName: 'shipBy', type: 'dateFrom', filterField: 'shipByFrom' },
    // { fieldName: 'shipBy', type: 'dateTo', filterField: 'shipByTo' },
    { fieldName: 'total', type: 'lessThan', filterField: 'totalFrom'},
    { fieldName: 'total', type: 'moreThan', filterField: 'totalTo'},
  ];

  filter: UiFilter<ProcessingOrderFacade>;
  readonly queryBuilder: FilterQueryBuilder;
  loader: Subscription | boolean;
  ordersLoaded: boolean;
  orders: ProcessingOrderFacade[] = [];
  warehouses: Warehouse[];
  selectedWarehouse: Warehouse = null;
  sortOrder: {
    field: string;
    reverse: boolean;
  };
  SalesItem = SalesItem;

  private preparePendingRequest: Subscription;
  private pendingRequest: Subscription;

  private filterChangesSubscription: Subscription;
  private innerWidth: number;
  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    public iconUrls: IconUrls,
    public salesItemService: SalesItemService,
    public massActionService: MassActionService,
    private sellerService: SellerService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
  ) {
    const title = 'Orders - Processing';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
    this.queryBuilder = new FilterQueryBuilder({
      filterSet: [...Object.values(this.filterConfig).map((c) => c.filterField)],
    });
  }

  get showDetailsPopup(): boolean {
    return this.innerWidth <= CONSTANTS.MAX_POPUP_SCREEN_SM;
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.innerWidth = window.innerWidth;
  }

  ngOnInit(): void {
    this.loader = true;
      this.preparePendingRequest = this.sellerService.$currentSeller.subscribe((seller) => {
      this.loader = false;
      this.warehouses = seller?.warehouses;
      if (this.warehouses?.length) {
        this.selectedWarehouse = this.warehouses[0];
        this.buildOrderListGrid();
      }
      this.massActionService.init();
    }, err => {
      this.loader = false;
    });
  }

  ngOnDestroy(): void {
    this.pendingRequest?.unsubscribe();
    this.preparePendingRequest?.unsubscribe();
  }

  buildOrderListGrid(clearFilters = true): void
  {
    this.ordersLoaded = true;
    this.pendingRequest = this.loader = this.salesItemService.fetchProcessingOrderList(this.selectedWarehouse.id).pipe(
      finalize(() => this.loader = false)
    ).subscribe((dataGrid: ProcessingOrderFacade[]) => {
      this.orders = dataGrid;
      if (clearFilters) {
        this.createFilter();
      }
    });
  }

  sort(sortCol: ConfigInterface): void
  {
    Object.values(this.cols).forEach((col) => {
      if (col.sort && sortCol !== col.sort) {
        col.sort.applied = false;
        col.sort.value = null;
      }
    });
    this.sortOrder = {
      field: sortCol.name,
      reverse: sortCol.value
    };
    this.queryBuilder.changePageNum(1);
  }

  cancel(salesItems?: SalesItem[]): void {
    const modalRef = this.modalService.open(CancelConfirmationComponent, {size: 'sm'});
    modalRef.componentInstance.items = salesItems || this.massActionService.getItems;
    modalRef.result.then((type) => {
      if (type === 'success') {
        const itemIds: string[] = salesItems ? salesItems.map((item) => item.id) : this.massActionService.getResult;
        this.loader = this.salesItemService.cancel(itemIds).pipe(finalize(() => {
          this.loader = false;
        })).subscribe((data) => {

          this.queryBuilder.changePageNum(1);
          this.buildOrderListGrid(false);
          this.toastrService.successNotify(data);
        });
      }
    }, () => {});
  }

  confirmCancel(salesItem: SalesItem): void {
    const modalRef = this.modalService.open(CancelConfirmationComponent, {size: 'sm'});
    modalRef.componentInstance.items = [salesItem];
    modalRef.result.then((type) => {
      if (type === 'success') {
        this.loader = this.salesItemService.cancelApprove(salesItem.id, salesItem.qty - salesItem.packedQty).pipe(finalize(() => {
          this.loader = false;
        })).subscribe((data) => {

          this.queryBuilder.changePageNum(1);
          this.buildOrderListGrid(false);
          this.toastrService.successNotify(data);
        });
      }
    }, () => {});
  }

  pack(items: SalesItem[]): void {
    const modalRef = this.modalService.open(ShipmentDetailsComponent, {size: 'lg', scrollable: true});
    modalRef.componentInstance.items = items.filter((item) => item.canPack).slice();
    modalRef.componentInstance.warehouse = this.selectedWarehouse;
    modalRef.result.then((response: CreateStatusMessageInterface) => {
      this.buildOrderListGrid(false);
    }, () => {});
  }

  openDetail(e: MouseEvent, data: ProcessingOrderFacade | SalesItem): void {
    if (!this.showDetailsPopup) {
      return;
    }
    e.stopPropagation();
    e.preventDefault();
    const modalRef = this.modalService.open(data instanceof ProcessingOrderFacade ? ProcessingOrderDetailsComponent : ProcessingItemDetailsComponent);
    modalRef.componentInstance.facade = data;
  }

  private createFilter(): void {
    this.filter = new UiFilter(this.orders, this.filterConfig, this.queryBuilder);
    this.filterChangesSubscription = this.filter.filterChanged$
      .subscribe((v) => this.orders = v);
  }
}
