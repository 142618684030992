import { Injectable } from '@angular/core';

const BASE_URL = '../assets/icons/';

@Injectable({
    providedIn: 'root'
})
export class IconUrls
{
    public readonly calendar = `${BASE_URL}calendar.svg`;
    public readonly sortAmountUp = `${BASE_URL}sort-amount-up.svg`;
    public readonly sortAmountDown = `${BASE_URL}sort-amount-down.svg`;
    public readonly filter = `${BASE_URL}filter.svg`;
    public readonly ellipsis = `${BASE_URL}ellipsis.svg`;
    public readonly edit = `${BASE_URL}edit.svg`;
    public readonly checkSquare = `${BASE_URL}check-square.svg`;
    public readonly square = `${BASE_URL}square.svg`;
    public readonly circle = `${BASE_URL}circle.svg`;
    public readonly check = `${BASE_URL}check.svg`;
    public readonly times = `${BASE_URL}times.svg`;
    public readonly timesLight = `${BASE_URL}times-light.svg`;
    public readonly menu = `${BASE_URL}menu.svg`;
    public readonly cog = `${BASE_URL}cog.svg`;
    public readonly search = `${BASE_URL}search.svg`;
    public readonly info = `${BASE_URL}info.svg`;
    public readonly exclamationCircle = `${BASE_URL}exclamation-circle.svg`;
    public readonly folder = `${BASE_URL}folder.svg`;
    public readonly link = `${BASE_URL}link.svg`;
    public readonly unlink = `${BASE_URL}unlink.svg`;
    public readonly trash = `${BASE_URL}trash.svg`;
    public readonly circleRegular = `${BASE_URL}circle-regular.svg`;
    public readonly fileExport = `${BASE_URL}file-export.svg`;
    public readonly download = `${BASE_URL}download-solid.svg`;
    public readonly spinner = `${BASE_URL}spinner-solid.svg`;
    public readonly xmark = `${BASE_URL}xmark-solid.svg`;
}
