import { BrandMappingEntity } from '../../../domain/entity/brand-mapping/brand-mapping.entity';
import { BoodmoBrandInterface } from '../../../domain/models/boodmo-brand.interface';
import { MappedBrandInterface } from '../../../domain/models/mapped-brand.interface';

export class BrandMappingFacade
{
  readonly boodmoName: string;

  private constructor(
    private _mapping: BrandMappingEntity,
    private _brands: BoodmoBrandInterface[],
  )
  {
    this.boodmoName = _brands.find((b) => b.id === _mapping.brandId)?.name;
  }

  get id(): string
  {
    return this._mapping.id;
  }

  get brandName(): string {
    return this._mapping.brandName;
  }

  get brandId(): number {
    return this._mapping.brandId;
  }

  get active(): boolean {
    return this._mapping.active;
  }

  get additional(): string | null {
    return this._mapping.additional;
  }

  static build = (
      mapping: MappedBrandInterface,
      brands: BoodmoBrandInterface[],
  ) => new BrandMappingFacade(
      BrandMappingEntity.build(mapping),
      brands,
  );
}
