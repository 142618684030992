<div *ngIf="dataList?.length"
     class="d-sm-flex align-items-center justify-content-between text-center text-sm-left m-2">
    <label class="form-label text-nowrap text-muted mb-0"
           [ngClass]="useMoreItems ? '' : 'd-none'"
    >
        Items on page:
    <select class="form-select form-select-sm w-80px d-inline-block"
            [ngModel]="limit"
            #limitModel
            (ngModelChange)="queryBuilder?.changeLimit(+limitModel.value);changePageNum.emit(1);changeLimit.emit(+limitModel.value)"
    >
        <option *ngFor="let option of [10,25,50,100]" [value]="option">{{option}}</option>
    </select>
    </label>
    <div class="text-muted">
        Showing {{(page - 1) * limit + 1}} to {{totalItemsLength < page * limit ? totalItemsLength : page * limit}}
        <ng-container *ngIf="totalItemsLength !== null; else allLength">
            of {{totalItemsLength}} entries
        </ng-container>
        <ng-template #allLength>{{dataList?.length}}</ng-template>
    </div>
    <div *ngIf="totalItemsLength === null || totalItemsLength > limit">
        <pagination-controls class="main-pagination"
                             (pageChange)="changePageNum.emit($event)"
                             [maxSize]="7"
                             [directionLinks]="false"
        ></pagination-controls>
    </div>
</div>
<div *ngIf="!dataList?.length" class="table-info {{loader ? 'loading' : 'no-data'}}"></div>
