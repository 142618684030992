import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {CopyrightNotice} from '../../utils/copyright-notice';
import {Subscription} from 'rxjs';
import {UntypedFormGroup} from '@angular/forms';
import {LoginForm} from '../../forms/login.form';
import {AuthService} from '../../services/auth.service';
import {finalize} from 'rxjs/operators';
import {CoreService} from '../../services/core.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  fb: LoginForm;
  form: UntypedFormGroup;
  copyrightNotice: string = CopyrightNotice.fetch('text-white opacity-50');
  loading: Subscription | boolean;
  tabs = {
    sign_in: 'Sign In',
    // sign_up: 'Sign Up',
  };
  activeTab: 'sign_in' | 'sign_up' = 'sign_in';

  constructor(
    private authService: AuthService,
    private router: Router,
    public coreService: CoreService
  ) {}

  ngOnInit(): void {
    this.initForm();
    this.authService.removeToken();
  }

  tryLogin(): void {
    this.fb.markSubmitted();
    if (this.form.valid) {
      const action = this.activeTab === 'sign_in' ? 'loginUser' : 'registerUser';
      this.loading = this.authService[action](this.form.value.email, this.form.value.password).pipe(
        finalize(() => {
          this.loading = false;
        })
      ).subscribe(({token}) => {
        this.authService.subscribeUser(token);

        this.router.navigate([this.authService.redirectUrl || '/dashboard']);
      });
    }
  }

  initForm(useConfirm: boolean = false): void {
    this.fb = new LoginForm(useConfirm);
    this.fb.updateConfirmPasswordControl();
    this.form = this.fb.getForm();
  }
}
