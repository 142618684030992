import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Seller } from '../../../domain/entity/seller/seller.entity';
import { SellerService } from '../../services/seller.service';
import { Subscription } from 'rxjs';
import { ToastrService } from '../../services/toastr.service';
import { Warehouse } from '../../../domain/entity/warehouse/warehouse.entity';
import { CONSTANTS } from '../../../constants/constants';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { WarehouseDetailsComponent } from './warehouse-details/warehouse-details.component';
import { ProcessWarehouseComponent } from './process-warehouse/process-warehouse.component';
import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';
import {finalize} from 'rxjs/operators';
import {IconUrls} from '../../../constants/icon-urls';
import {Residence} from '../../../domain/entity/residence/residence.entity';
import {ColumnInterface, ConfigInterface} from '../../components/th/th.component';
import {UiFilter} from '../mapping/ui-filter/ui-filter';
import {UiFilterConfigInterface} from '../mapping/ui-filter/ui-filter-config.interface';
import {FilterQueryBuilder} from '../../utils/query-builder/filter-query-builder';

@Component({
  selector: 'app-warehouses',
  templateUrl: './warehouses.component.html',
  styleUrls: ['./warehouses.component.scss']
})
export class WarehousesComponent implements OnInit {
  readonly cols: {[key: string]: ColumnInterface} = {
    name: {
      filter: { value: '', applied: false, name: 'name', type: 'text' },
      sort: { name: 'name', value: false, applied: true },
    },
    enabled: {
      filter: { value: '', applied: true, name: 'enabled', type: 'select' },
    },
    lastImport: {
      filter: { value: '', applied: false, name: 'lastImport', type: 'dateRange' },
      sort: { name: 'lastImport', value: null, applied: false },
    }
  };

  readonly filterConfig: UiFilterConfigInterface[] = [
    { fieldName: 'name', type: 'text', filterField: 'name' },
    { fieldName: 'enabled', type: 'boolean', filterField: 'enabled' },
    { fieldName: 'lastImport', type: 'dateFrom', filterField: 'lastImportFrom' },
    { fieldName: 'lastImport', type: 'dateTo', filterField: 'lastImportTo' },
  ];

  filter: UiFilter<Warehouse>;
  readonly queryBuilder: FilterQueryBuilder;

  loader: Subscription | boolean;
  warehouses: Warehouse[] = new Array<Warehouse>();
  inlineLoader: Subscription | boolean;
  residences: Residence[];

  private filterChangesSubscription: Subscription;

  constructor(
    public iconUrls: IconUrls,
    private titleService: Title,
    private sellerService: SellerService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private breadcrumbsService: BreadcrumbsService,
  ) {
    const title = 'Settings - Warehouses';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
    this.queryBuilder = new FilterQueryBuilder({
      filterSet: [
          ...Object.values(this.filterConfig).map((c) => c.filterField)
      ],
      limit: 10
    });
    setTimeout(() => {
      this.queryBuilder.handleSort('name', 'asc');
      this.cols.enabled.filter.value = true;
      this.queryBuilder.handleFilter('enabled', true);
    });
  }

  get sortOrder() {
    const {sorting} = this.queryBuilder.getRequestObject();
    return {
      field: Object.keys(sorting)[0],
      reverse: Object.values(sorting)[0] === 'desc'
    };
  }

  ngOnInit(): void {
    this.fetchList();
  }

  processWarehouse(event: MouseEvent, warehouse?: Warehouse): void {
    event.stopPropagation();
    const modalRef = this.modalService.open(ProcessWarehouseComponent);
    modalRef.componentInstance.allResidences = this.residences;
    if (warehouse) {
      modalRef.componentInstance.warehouse = warehouse;
    }
    modalRef.result.then((type) => {
      if (type === 'success') {
        this.fetchList();
        this.onChangePagination();
      }
    }, () => {});
  }

  sort(sortCol: ConfigInterface): void
  {
    Object.values(this.cols).forEach((col) => {
      if (col.sort && sortCol !== col.sort) {
        col.sort.applied = false;
        col.sort.value = null;
      }
    });
    this.queryBuilder.changePageNum(1);
  }

  openDetail(warehouse: Warehouse): void {
    if (window.innerWidth > CONSTANTS.MAX_POPUP_SCREEN_SM) {
      return;
    }
    const modalRef = this.modalService.open(WarehouseDetailsComponent);
    modalRef.componentInstance.warehouse = warehouse;
  }

  onChangePagination($event?: any): void {
    this.queryBuilder.changePageNum($event || 1);
  }

  private fetchList(): void {
    this.loader = this.sellerService.loadSellerInfo().pipe(finalize(() => {
      this.loader = false;
    })).subscribe((seller: Seller) => {
      this.warehouses = seller?.warehouses.filter(w => !w.fulfilledStock);
      this.residences = seller.residences;
      this.createFilter();
    });
  }

  private createFilter(): void
  {
    this.filter = new UiFilter(this.warehouses, this.filterConfig, this.queryBuilder);
    this.filterChangesSubscription = this.filter.filterChanged$.subscribe((v) => this.warehouses = v);
    this.filter.filter();
  }
}
