<app-loader *ngIf="loader"></app-loader>
<div class="row justify-content-md-between align-items-center w-100 ms-0 me-0 px-1 pb-sm-2">
  <div class="col-sm-6 d-flex align-items-center justify-content-md-start justify-content-end p-0">
    <label for="warehouseFilter" class="form-label mt-1">Warehouse</label>
    <ng-select
            style="width: 200px"
            class="ng-select-sm ms-2"
            id="warehouseFilter"
            [clearable]="false"
            [placeholder]="!warehouses?.length ? 'There are no active warehouses' : 'Select Warehouse'"
            [(ngModel)]="selectedWarehouse"
            (ngModelChange)="queryBuilder.handleFilter('warehouse', selectedWarehouse.id)"
            [items]="warehouses"
            bindLabel="name"
    ></ng-select>
    <button role="button" class="btn ms-1 btn-primary btn-sm" (click)="buildOrderListGrid()" [disabled]="!selectedWarehouse">
      Choose
    </button>
  </div>

  <div class="col-sm-6 d-flex align-items-center justify-content-end px-0 pt-2 pt-sm-0"
       *ngIf="!selectedWarehouse?.fulfilledStock && ordersLoaded">

    <div class="d-none d-sm-inline-block" *ngIf="massActionService.checkMassButton(items)"
         ngbDropdown placement="bottom-right">
      <button ngbDropdownToggle id="mass_actions" class="btn btn-brand btn-sm ms-2">Mass Actions</button>
      <div ngbDropdownMenu aria-labelledby="mass_actions">
        <button class="btn btn-success" ngbDropdownItem (click)="confirm()">
          <svg-icon [src]="iconUrls.check"></svg-icon>
          Confirm
        </button>
        <button class="btn btn-danger" ngbDropdownItem (click)="cancel()">
          <svg-icon [src]="iconUrls.timesLight"></svg-icon>
          Cancel
        </button>
      </div>
    </div>
    <div class="mass-btn-wrapper d-sm-none" *ngIf="massActionService.checkEntry(items, false)">
      <div class="btn-group btn-group-sm inner">
        <button class="btn btn-success" (click)="confirm()">
          <svg-icon [src]="iconUrls.check"></svg-icon>
          <span class="ms-2">Confirm</span>
        </button>
        <button class="btn btn-danger" (click)="cancel()">
          <svg-icon [src]="iconUrls.timesLight"></svg-icon>
          <span class="ms-2">Cancel</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="card shadow">
  <div class="card-body p-0" style="position: relative;" *ngIf="ordersLoaded">
    <div class="table-wrapper overflow-auto">
      <table class="table table-bordered table-hover">
        <thead>
        <tr>
          <th>
            <app-th [colKey]="'partNumber'"
                    [config]="cols.partNumber"
                    [queryBuilder]="queryBuilder"
                    [name]="'Part Number'"
                    [placeholder]="'Part Number'"
                    (update)="filterData()"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'orderNumber'"
                    [config]="cols.orderNumber"
                    [queryBuilder]="queryBuilder"
                    [name]="'Order ID'"
                    [placeholder]="'Order ID'"
                    (update)="filterData()"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell" style="width: 15%">
            <app-th [colKey]="'brand'"
                    [config]="cols.brand"
                    [queryBuilder]="queryBuilder"
                    [options]="brandList"
                    [name]="'Brand'"
                    [inputWidth]="'120px'"
                    [showAllOption]="true"
                    (update)="filterData()"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell">Part Name</th>
          <th>Qty</th>
          <th>
            <app-th [colKey]="'price'"
                    [config]="cols.price"
                    [queryBuilder]="queryBuilder"
                    [name]="'Unit Price'"
                    (update)="filterData()"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'total'"
                    [config]="cols.total"
                    [queryBuilder]="queryBuilder"
                    [name]="'Row Total'"
                    (update)="filterData()"
            ></app-th>
          </th>
          <th>
            <app-th [colKey]="'confirmationBefore'"
                    [config]="cols.confirmationBefore"
                    [queryBuilder]="queryBuilder"
                    [name]="'Confirm Before'"
                    (update)="filterData()"
                    (sort)="sort($event)"
                    [dropdownPosition]="'bottom-right'"
            ></app-th>
          </th>
          <th class="p-0" *ngIf="!selectedWarehouse.fulfilledStock"></th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let facade of orders | orderBy: sortOrder?.field : sortOrder?.reverse | paginate: { itemsPerPage: queryBuilder.getLimit(), currentPage: queryBuilder.getPage() }; let i = index">
          <tr (click)="openDetail(facade)">
            <td class="position-relative row-toggler-wrap--left">
              <div class="row-toggler" container="body" ngbDropdown
                   *ngIf="!selectedWarehouse.fulfilledStock">
                <div [id]="'dropdownToggleMore' + i"
                     ngbDropdownToggle
                     (click)="$event.stopPropagation()">
                  <svg-icon [src]="iconUrls.ellipsis"></svg-icon>
                </div>
                <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownToggleMore' + i">
                  <button class="btn btn-success" ngbDropdownItem
                          (click)="confirm(facade)">
                    <svg-icon [src]="iconUrls.edit"></svg-icon>
                    Confirm
                  </button>
                  <button class="btn btn-danger" ngbDropdownItem
                          (click)="cancel(facade)">
                    <svg-icon [src]="iconUrls.timesLight"></svg-icon>
                    Cancel
                  </button>
                </div>
              </div>
              <app-part-link [item]="facade.salesItem"></app-part-link>
            </td>
            <td class="d-none d-sm-table-cell">
              {{ facade.orderNumber }}
            </td>
            <td class="d-none d-sm-table-cell">
                {{ facade.brand }}
            </td>
            <td class="d-none d-sm-table-cell">
              {{ facade.salesItem.partName }}
            </td>
            <td>
              {{ facade.salesItem.qty }}
            </td>
            <td>
              {{ facade.price | indianCurrency}}
            </td>
            <td class="d-none d-sm-table-cell">
              <div class="text-nowrap">Subtotal: {{ facade.subTotal | indianCurrency}}</div>
              <div class="text-nowrap">Tax: {{ facade.tax | indianCurrency}}</div>
              <b class="text-nowrap">Total: {{ facade.total | indianCurrency}}</b>
            </td>
            <td>
              {{ facade.confirmationBefore | bdate:'dateTime' }}
            </td>
            <td style="width:30px" class="position-relative row-toggler-wrap--right p-0"
                *ngIf="!selectedWarehouse.fulfilledStock">
              <div class="row-toggler w-100"
                   (click)="massActionService.entry([facade.salesItem], $event)"
                   [ngClass]="massActionService.getClass(facade.salesItem)">
                <svg-icon [src]="iconUrls[massActionService.getIcon(facade.salesItem)]"></svg-icon>
              </div>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>

    <app-tfoot [queryBuilder]="queryBuilder"
               [dataList]="orders"
               [loader]="loader"
               (changePageNum)="queryBuilder.changePageNum($event)"
    ></app-tfoot>
  </div>
</div>

<div *ngIf="!selectedWarehouse">
  Select warehouse from the list to see products
</div>
