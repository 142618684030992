import { DatePipe } from '@angular/common';
import { Inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bdate'
})
export class BdatePipe extends DatePipe implements PipeTransform {
  private readonly customFormats = {
    dateInput: 'yyyy-MM-dd',
    date: 'dd-MM-yyyy',
    time: 'HH:mm',
    dateTime: ``,
    excel: 'yyyy-MM-ddTHH:mm:ss.mmm',
  };

  constructor(@Inject(LOCALE_ID) locale?: string) {
    super(locale);
    this.customFormats.dateTime = `${this.customFormats.date} ${this.customFormats.time}`;
  }

  transform(value: Date | string | number | null | undefined, format?: string, timezone?: string, locale?: string): string | null;
  transform(value: null | undefined, format?: string, timezone?: string, locale?: string): null;
  transform(
    value: string | number | Date,
    format: 'dateInput' | 'date' | 'time' | 'dateTime' | string = 'date',
    timezone?: string,
    locale?: string
  ): string {
    format = this.customFormats[format] || format;
    return super.transform(value, format, timezone, locale);
  }

}
