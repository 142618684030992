<div class="gst-control"
     [class.is-invalid]="isInvalid"
     [class.is-valid]="isValid"
>
    <input type="text"
           class="form-control form-control-sm"
           placeholder="Enter GST"
           [required]="isRequired"
           [pattern]="fb.gstPattern"
           [minLength]="fb.gstLength"
           [maxLength]="fb.gstLength"
           [ngModel]="value"
           (ngModelChange)="writeValue($event)"
           [class.is-invalid]="isInvalid"
           [class.is-valid]="isValid"
           [readOnly]="addressCheckLoading"
    />
    <app-inline-loader *ngIf="addressCheckLoading"
                       class="gst-control__loading"
    ></app-inline-loader>
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('required', getControlKey('gstNumber'))"
>
    {{fb.getErrorMsg('required')}}
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('pattern', getControlKey('gstNumber'))"
>
    {{fb.getErrorMsg('gstPattern')}}
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('minlength', getControlKey('gstNumber'))"
>
    {{fb.getErrorMsg('min', 'GST', fb.gstLength)}}
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('gstInvalid', getControlKey('gstNumber'))"
>
    {{fb.getErrorMsg('gstInvalid')}}
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('gstNotUnique', getControlKey('gstNumber'))"
>
    {{fb.getErrorMsg('gstNotUnique')}}
</div>
<div class="invalid-feedback"
     *ngIf="form.getError('gstNotBelongState', getControlKey('gstNumber'))"
>
    {{fb.getErrorMsg('gstNotBelongState')}}
</div>
