import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {ResidenceRepository} from '../../infrastructure/repositories/residence.repository';
import {CreateStatusMessageInterface} from '../../domain/models/create-status-message.interface';
import {AddressRequest} from '../../infrastructure/requests/address.request';

@Injectable({
  providedIn: 'root'
})
export class ResidenceService {
  constructor(private residenceRepository: ResidenceRepository) {}

  create = (name: string, billingAddress: AddressRequest, shippingAddress: AddressRequest): Observable<CreateStatusMessageInterface> =>
    this.residenceRepository.create(name, billingAddress, shippingAddress);

  update = (id: string, name: string, billingAddress: AddressRequest, shippingAddress: AddressRequest): Observable<CreateStatusMessageInterface> =>
    this.residenceRepository.update(id, name, billingAddress, shippingAddress);
}
