import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

import { Seller } from '../../../domain/entity/seller/seller.entity';
import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';
import { SellerService } from '../../services/seller.service';
import { IntegrationForm } from '../../forms/integration.form';
import { UntypedFormGroup } from '@angular/forms';
import { ToastrService } from '../../services/toastr.service';

@Component({
  selector: 'app-integration',
  templateUrl: './integration.component.html',
  styleUrls: ['./integration.component.scss']
})
export class IntegrationComponent implements OnInit {

  fb: IntegrationForm;
  form: UntypedFormGroup;
  loader: Subscription | boolean;

  constructor(
    private titleService: Title,
    private breadcrumbsService: BreadcrumbsService,
    private sellerService: SellerService,
    private toastrService: ToastrService,
  ) {
    const title = 'Settings - Integration';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
  }

  get formControls() {
    return Object.keys(this.form.controls);
  }

  ngOnInit(): void {
    this.getSellerInfo();
  }

  save(): void
  {
    this.fb.markSubmitted();
    if (!this.form.valid) {
      return;
    }

    this.loader = this.sellerService.updateSellerInfo(this.sellerService.currentSeller.id, {
      email: this.sellerService.currentSeller.email,
      name: this.sellerService.currentSeller.name,
      integration: Object.values(this.form.value),
    }).pipe(finalize(() => {
      this.loader = false;
    })).subscribe((res) => {
      this.toastrService.successNotify(res.message ?? 'Integration info was successfully saved');
    });
  }

  private getSellerInfo(): void {
    this.loader = this.sellerService.loadSellerInfo().pipe(finalize(() => {
      this.loader = false;
    })).subscribe((seller: Seller) => {
      this.fb = new IntegrationForm(seller);
      this.form = this.fb.getForm();
    });
  }
}
