import { Injectable } from '@angular/core';
import { KeyValue } from '@angular/common';
import { HttpEventType } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreRepository } from '../../infrastructure/repositories/core.repository';
import {APP_CONFIG} from 'src/config/app.server.config';

@Injectable({
  providedIn: 'root'
})
export class CoreService {

  static UPLOAD_PROGRESS = 1;
  static RESPONSE_STATUS_SUCCESS = 200;

  constructor(
    private coreRepository: CoreRepository,
  ) {
  }

  originalOrder = (a: KeyValue<any, any>, b: KeyValue<any, any>): number => 0;

  uploadFile = (file: File | Blob, folder: string, fileName?: string, useOriginalName?: boolean): Observable<any> => {
    if (!file || !folder) {
      return throwError('no file or folder');
    }
    let name = fileName;
    if (file instanceof File && !fileName) {
      name = file.name;
    }
    const formData = new FormData();
    formData.append('file', file, name);
    formData.append('folder', folder);
    if (useOriginalName) {
      formData.append('use_original_name', 'true');
    }
    return this.coreRepository.uploadFile(formData).pipe(map((event) => {

        switch (event.type) {

          case HttpEventType.UploadProgress:
              // eslint-disable-next-line no-case-declarations
            const progress = Math.round(100 * event.loaded / event.total);
            return {status: CoreService.UPLOAD_PROGRESS, message: progress};

          case HttpEventType.Response:
            // console.table(event.body);
            return event.body;
          default:
            return `Unhandled event: ${event.type}`;
        }
      })
    );
  };

  downloadByPath = (path: string, prefix: string = `${APP_CONFIG.MEDIA_SERVER_FOLDER}/`, target = '_blank'): Window =>
    window.open(`${APP_CONFIG.MAIN_SERVER}${prefix}${path}`, target);
}
