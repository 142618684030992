/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class CustomAdapter extends NgbDateAdapter<string>
{
    readonly DELIMITER = '-';

    fromModel(value: string): NgbDateStruct
    {
        let result: NgbDateStruct = null;
        if (value) {
            const date = value.split(this.DELIMITER);
            result = {
                year: parseInt(date[0], 10),
                month: parseInt(date[1], 10),
                day: parseInt(date[2], 10)
            };
        }
        return result;
    }

    toModel(date: NgbDateStruct): string
    {
        let result: string = null;
        if (date) {
            result = date.year + this.DELIMITER + date.month.toString(10).padStart(2, '0') + this.DELIMITER + date.day.toString(10).padStart(2, '0');
        }
        return result;
    }
}
