<div class="modal-header">
  <h5 class="modal-title" [innerText]="title"></h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <p class="alert alert-secondary">For correct price link with catalog part check brand <a [routerLink]="'/products/mapping'" target="_blank">mapping settings</a></p>

  <form novalidate [formGroup]="form" [ngClass]="{'was-validated' : fb.isSubmitted()}" (ngSubmit)="trySubmit()">

    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="partNumber">Number:</label>
      <div class="col-sm-8">
        <input type="text" id="partNumber" class="form-control form-control-sm"
               formControlName="partNumber" required>
        <div class="invalid-feedback"
             *ngIf="form.getError('required', 'partNumber')">{{fb.getErrorMsg('required')}}</div>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="brand">Brand:</label>
      <div class="col-sm-8 position-relative">
        <div ngbDropdown #brandDrop="ngbDropdown" autoClose="outside">
          <button class="form-control form-control-sm d-flex align-items-center justify-content-between"
                  [class.is-valid]="form.get('brand').valid && fb.isSubmitted()"
                  [class.is-invalid]="!form.get('brand').valid && fb.isSubmitted()"
                  id="brandDropdown"
                  [invalid]="!form.get('brand').valid"
                  formControlName="brand"
                  ngDefaultControl
                  (click)="$event.preventDefault()"
                  ngbDropdownToggle
          >
            {{ form.get('brand').value || 'No selected brand' }}
            <app-inline-loader *ngIf="brandListLoader" class="inline-select-loader"></app-inline-loader>
          </button>

          <div ngbDropdownMenu aria-labelledby="brandDropdown" class="w-100">
            <div class="p-1">
              <input type="text" id="brand" class="form-control form-control-sm" [(ngModel)]="filter.value"
                     [ngModelOptions]="{standalone: true}"
                     #filter>
            </div>
            <div class="dropdown-inner" *ngIf="brandDrop.isOpen()">
              <button *ngFor="let brand of brandList | filterBy:filter.value"
                      (click)="selectBrand(brand); brandDrop.close()"
                      ngbDropdownItem
              >
                {{brand}}
              </button>
            </div>
          </div>
        </div>
        <div class="invalid-feedback" [ngClass]="{'d-block': !form.get('brand').valid && fb.isSubmitted()}"
             *ngIf="form.getError('required', 'brand')">{{fb.getErrorMsg('required')}}</div>


        <!--        <input list="brandList" type="text" id="brand" class="form-control form-control-sm"-->
<!--               formControlName="brand" placeholder="Select Brand" required>-->
<!--        <app-inline-loader *ngIf="brandListLoader" class="inline-select-loader"></app-inline-loader>-->

<!--        <datalist id="brandList">-->
<!--          <option value="" disabled>Select Brand</option>-->
<!--          <option *ngFor="let brand of brandList" [value]="brand"></option>-->
<!--        </datalist>-->
<!--        <div class="invalid-feedback"-->
<!--             *ngIf="form.getError('required', 'brand')">{{fb.getErrorMsg('required')}}</div>-->
      </div>
    </div>
    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="price">Price:</label>
      <div class="col-sm-8">
        <input type="number" id="price" step="0.01" class="form-control form-control-sm" required [min]="fb.minPrice" [max]="fb.maxPrice"
               [pattern]="fb.pricePattern"
               formControlName="price" (change)="changePrice('purchasePrice')">
        <div class="invalid-feedback"
             *ngIf="form.getError('required', 'price')">{{fb.getErrorMsg('required')}}</div>
        <div class="invalid-feedback"
             *ngIf="form.getError('min', 'price')">{{fb.getErrorMsg('min', 'price', fb.minPrice)}}</div>
        <div class="invalid-feedback"
             *ngIf="form.getError('max', 'price')">{{fb.getErrorMsg('max', 'price', fb.maxPrice)}}</div>
        <div class="invalid-feedback">{{fb.getErrorMsg('general', 'price')}}<br>
          {{fb.getErrorMsg('pattern', 'price', fb.moneyFormat)}}</div>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="purchasePrice">Purchase Price:</label>
      <div class="col-sm-8">
        <input type="number" id="purchasePrice" step="0.01" class="form-control form-control-sm" required [min]="fb.minPrice" [max]="fb.maxPurchasePrice"
               [pattern]="fb.pricePattern"
               formControlName="purchasePrice">
        <div class="invalid-feedback"
             *ngIf="form.getError('required', 'purchasePrice')">{{fb.getErrorMsg('required')}}</div>
        <div class="invalid-feedback"
             *ngIf="form.getError('min', 'purchasePrice')">{{fb.getErrorMsg('min', 'purchasePrice', fb.minPrice)}}</div>
        <div class="invalid-feedback"
             *ngIf="form.getError('max', 'purchasePrice')">{{fb.getErrorMsg('max', 'purchasePrice', fb.maxPurchasePrice)}}</div>
        <div class="invalid-feedback">{{fb.getErrorMsg('general', 'purchase Price')}}<br>
          {{fb.getErrorMsg('pattern', 'purchasePrice', fb.moneyFormat)}}</div>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="mrp">MRP:</label>
      <div class="col-sm-8">
        <input type="number" id="mrp" step="0.01" class="form-control form-control-sm" required [min]="fb.minPrice"
               [pattern]="fb.pricePattern"
               formControlName="mrp" (change)="changePrice()">
        <div class="invalid-feedback"
             *ngIf="form.getError('required', 'mrp')">{{fb.getErrorMsg('required')}}</div>
        <div class="invalid-feedback"
             *ngIf="form.getError('min', 'mrp')">{{fb.getErrorMsg('min', 'mrp', fb.minPrice)}}</div>
        <div class="invalid-feedback">{{fb.getErrorMsg('general', 'mrp')}}<br>
          {{fb.getErrorMsg('pattern', 'mrp', fb.moneyFormat)}}</div>
      </div>
    </div>
    <div class="mb-3 row" *ngIf="warehouse?.manageQty">
      <label class="form-label col-sm-4" for="qty">Qty:</label>
      <div class="col-sm-8">
        <input type="number" id="qty" class="form-control form-control-sm"
               [readOnly]="warehouse.fulfilledStock"
               formControlName="qty" [appNumeric]
               required [min]="fb.minQty"
               #qty
               (change)="changeQty(qty.value)"
        >
        <div class="invalid-feedback"
             *ngIf="form.getError('required', 'qty')">{{fb.getErrorMsg('required')}}</div>
        <div class="invalid-feedback"
             *ngIf="form.getError('min', 'qty')">{{fb.getErrorMsg('min', 'Qty', fb.minQty)}}</div>
        <div class="invalid-feedback">{{fb.getErrorMsg('general', 'Qty')}}</div>
      </div>
    </div>
    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="name">Part name:</label>
      <div class="col-sm-8">
        <input type="text" id="name" class="form-control form-control-sm"
               formControlName="name">
        <div class="invalid-feedback"
             *ngIf="form.getError('required', 'name')">{{fb.getErrorMsg('required')}}</div>
        <div class="invalid-feedback">{{fb.getErrorMsg('general', 'name')}}</div>
      </div>

    </div>
    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="ignored">Ignore:</label>
      <div class="col-sm-8">
        <input type="checkbox" id="ignored" formControlName="ignored">
      </div>
    </div>
    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="enabled">Enabled:</label>
      <div class="col-sm-8">
        <input type="checkbox" id="enabled" formControlName="enabled">
      </div>
    </div>
    <div class="mb-3 row">
      <label class="form-label col-sm-4" for="dispatchDays">Dispatch Days:</label>
      <div class="col-sm-8">
        <input type="number" id="dispatchDays" class="form-control form-control-sm"
               formControlName="dispatchDays" [appNumeric]
               [min]="fb.minDays">
        <div class="invalid-feedback" *ngIf="form.getError('min', 'dispatchDays')">{{fb.getErrorMsg('min', 'Dispatch Days', fb.minDays)}}</div>
        <div class="invalid-feedback">{{fb.getErrorMsg('general', 'Dispatch days')}}</div>
      </div>
    </div>
    <button type="submit" #sbmButton hidden></button>
  </form>
</div>

<div class="modal-footer btn-modal-wrapp">
  <button type="submit" class="btn btn-success btn-modal" (click)="sbmButton.click()">Submit</button>
</div>
