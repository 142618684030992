<div ngbDropdown #dRef="ngbDropdown" autoClose="outside"
     container="body" class="dropdown-filter-container">
    <span class="w-100" ngbDropdownToggle id="filter-{{ colKey }}">
        <span class="d-flex justify-content-between align-items-end">
            <span class="th-name"
                  [ngClass]="{'text-truncate': maxWidthTruncate}"
                  [style.max-width.px]="maxWidthTruncate"
                  [title]="name"
            >{{ name }}</span>
            <span class="fs-xs"
                  [style.max-width.px]="maxWidthTruncate ? 38 : null "
            >
                <svg-icon class="ms-1"
                          [class.opacity-50]="!config.sort?.applied"
                          *ngIf="config.sort && !config.sort?.value"
                          [src]="iconUrls.sortAmountUp"
                ></svg-icon>
                <svg-icon class="ms-1"
                          [class.opacity-50]="!config.sort?.applied"
                          *ngIf="config.sort && config.sort?.value"
                          [src]="iconUrls.sortAmountDown"
                ></svg-icon>
                <svg-icon class="ms-1"
                          [class.opacity-50]="!config.filter?.applied"
                          *ngIf="config.filter"
                          [src]="iconUrls.filter"
                ></svg-icon>
            </span>
        </span>
    </span>

    <ul ngbDropdownMenu [attr.aria-labelledby]="'filter-' + colKey" class="text-start dropdown-filter"
        [class.has-validation]="config?.filter?.type === 'numberRange'">
        <ng-container *ngIf="config?.sort">
            <li ngbDropdownItem
                class="py-1 px-2 d-flex align-items-center sorting-item"
                [class.active]="config.sort.value === false"
                (click)="toggleSort(config.sort, false, dRef)"
            >
                <svg-icon [src]="iconUrls.sortAmountUp"></svg-icon>
                <span class="ms-2">Sort Ascending</span>
            </li>
            <li ngbDropdownItem
                class="py-1 px-2 d-flex align-items-center sorting-item"
                [class.active]="config.sort.value"
                (click)="toggleSort(config.sort, true, dRef)"
            >
                <svg-icon [src]="iconUrls.sortAmountDown"></svg-icon>
                <span class="ms-2">Sort Descending</span>
            </li>
        </ng-container>
        <li ngbDropdownItem class="px-1 d-flex" *ngIf="config?.filter">
            <span class="pt-1">
                <svg-icon [src]="iconUrls.filter"></svg-icon>
                <span class="ms-2">Filter</span>
            </span>

            <ng-container *ngTemplateOutlet="filter"></ng-container>

        </li>
        <li ngbDropdownItem class="px-1 pt-0 text-end" *ngIf="config?.filter">
            <span [ngbTooltip]="canApplyFilter ? '' : '`Till` must be greater than `From`'">
            <button class="btn btn-primary btn-sm px-3"
                    [disabled]="!canApplyFilter"
                    (click)="applyFilter(dRef)">
                Apply
            </button></span>
            <button class="btn btn-secondary btn-sm px-3 ms-2" (click)="clearFilter(dRef)">
                Clear
            </button>
        </li>
    </ul>
</div>

<ng-template #filter>
    <ng-container [ngSwitch]="config.filter.type">
        <input *ngSwitchCase="'text'"
               type="text" class="form-control form-control-sm ms-2"
               [placeholder]="placeholder"
               [(ngModel)]="config.filter.value"
               [appAutofocus]="dRef.isOpen()"
               (keyup.enter)="applyFilter(dRef)"
        >
        <input *ngSwitchCase="'positiveNumber'"
               type="number" class="form-control form-control-sm ms-2"
               [placeholder]="placeholder"
               [appNumeric]="0"
               [(ngModel)]="config.filter.value"
               [appAutofocus]="dRef.isOpen()"
               [min]="1"
               (keyup.enter)="applyFilter(dRef)"
        >
        <div *ngSwitchCase="'dateRange'" class="ms-2">
            <div class="input-group input-group-sm" #dataFromTarget>
                <input type="text" class="form-control" placeholder="From"
                       [attr.aria-label]="name + 'from'" [attr.aria-describedby]="name + 'from'"
                       readonly required
                       (click)="datepickerFrom.toggle()"
                       ngbDatepicker #datepickerFrom="ngbDatepicker"
                       [positionTarget]="dataFromTarget"
                       [minDate]="minDate"
                       [maxDate]="maxFromDate"
                       [(ngModel)]="valueFrom"
                       [footerTemplate]="datepickerFromFooter"
                       (dateSelect)="dateSelectChange('from')"
                >
                <button class="btn btn-outline-primary" type="button" id="{{ colKey }}From" (click)="datepickerFrom.toggle()">
                    <svg-icon [src]="iconUrls.calendar"></svg-icon>
                </button>
                <ng-template #datepickerFromFooter>
                    <hr class="my-0">
                    <button class="btn btn-primary btn-sm m-2 float-start" (click)="valueFrom = currentDate;datepickerFrom.close()">Today</button>
                    <button class="btn btn-secondary btn-sm m-2 float-end" (click)="valueFrom = null;datepickerFrom.close()">Clear</button>
                </ng-template>
            </div>
            <div class="input-group input-group-sm mt-1" #dataToTarget>
                <input type="text" class="form-control" placeholder="Till"
                       [attr.aria-label]="name + 'to'" [attr.aria-describedby]="name + 'to'"
                       readonly required
                       [(ngModel)]="valueTo"
                       (click)="datepickerTo.toggle()"
                       ngbDatepicker #datepickerTo="ngbDatepicker"
                       [positionTarget]="dataToTarget"
                       [minDate]="minToDate"
                       [maxDate]="maxDate"
                       [footerTemplate]="datepickerToFooter"
                       (dateSelect)="dateSelectChange('to')"
                >
                <button class="btn btn-outline-primary" type="button" id="{{ colKey }}To" (click)="datepickerTo.toggle()">
                    <svg-icon [src]="iconUrls.calendar"></svg-icon>
                </button>
                <ng-template #datepickerToFooter>
                    <hr class="my-0">
                    <button class="btn btn-primary btn-sm m-2 float-start" (click)="valueTo = currentDate;datepickerTo.close()">Today</button>
                    <button class="btn btn-secondary btn-sm m-2 float-end" (click)="valueTo = null;datepickerTo.close()">Clear</button>
                </ng-template>
            </div>
        </div>

        <select *ngSwitchCase="'select'"
                class="form-select form-select-sm ms-2"
                [ngStyle]="{ width: inputWidth}"
                id="{{colKey}}Filter"
                [(ngModel)]="config.filter.value"
        >
            <option *ngIf="showAllOption" [ngValue]="showAllOptionValue">All</option>
            <option *ngFor="let option of options" [value]="option">
                {{option}}
            </option>
            <ng-content select=".options"></ng-content>
        </select>
        <ng-select *ngSwitchCase="'ngSelect'"
                   class="ms-2 ng-select-sm"
                   [ngStyle]="{ width: inputWidth}"
                   [placeholder]="showAllOption ? 'All' : placeholder"
                   [(ngModel)]="config.filter.value"
                   (ngModelChange)="queryBuilder.handleFilter(config.filter.name, config.filter.value, {multiple: multiselect})"
                   [items]="options"
                   [multiple]="multiselect"
                   (clear)="applyFilter(dRef)"
                   bindLabel="name"
        >
            <ng-content select="[options]"></ng-content>
            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">
                <div class="ng-value" *ngFor="let item of items | slice:0:1">
                    <span class="ng-value-label" [title]="item['name']">{{ item['name'] }}</span>
                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
                </div>
                <div class="ng-value" *ngIf="items.length > 1">
                    <span class="ng-value-label">{{items.length - 1}} more...</span>
                </div>

            </ng-template>
            <ng-template ng-option-tmp let-item="item">
                <span class="ng-option-label"
                      [ngStyle]="{whiteSpace: wrapOptions ?? 'nowrap'}"
                >{{ item.name }}</span>
            </ng-template>
        </ng-select>

        <!--        <ng-select *ngSwitchCase="'multiselect'"-->
        <!--                   class="ms-2 ng-select-sm"-->
        <!--                   [ngStyle]="{ width: inputWidth}"-->
        <!--                   id="{{colKey}}MultiFilter"-->
        <!--                   [placeholder]="showAllOption ? 'All' : placeholder"-->
        <!--                   [(ngModel)]="config.filter.value"-->
        <!--                   (ngModelChange)="queryBuilder.handleFilter(config.filter.name, config.filter.value, {multiple: multiselect})"-->
        <!--                   (clear)="applyFilter(dRef)"-->
        <!--                   [items]="options"-->
        <!--                   [multiple]="multiselect"-->
        <!--        >-->
        <!--            <ng-content select="[options]"></ng-content>-->

        <!--            <ng-template ng-multi-label-tmp let-items="items" let-clear="clear">-->
        <!--                <div class="ng-value" *ngFor="let item of items | slice:0:1">-->
        <!--                    <span class="ng-value-label">{{optionsKey ? item[optionsKey] : item}}</span>-->
        <!--                    <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>-->
        <!--                </div>-->
        <!--                <div class="ng-value" *ngIf="items.length > 1">-->
        <!--                    <span class="ng-value-label">{{items.length - 1}} more...</span>-->
        <!--                </div>-->

        <!--            </ng-template>-->
        <!--        </ng-select>-->

        <!--        <div *ngSwitchCase="'findInList'" class="ms-2 flex-grow-1" ngbDropdown #listDrop="ngbDropdown" autoClose="outside">
                    <button class="btn btn-sm btn-block btn-outline-secondary"
                            id="brandDropdown"
                            ngbDropdownToggle
                            type="button"
                    >
                        {{ findInListTitle }}
                    </button>

                    <div ngbDropdownMenu aria-labelledby="brandDropdown">
                        <div class="p-1">
                            <input type="text"
                                   class="form-control form-control-sm"
                                   [(ngModel)]="listFilter.value"
                                   #listFilter
                                   [appAutofocus]="listDrop.isOpen()"
                            >
                        </div>
                        <div class="dropdown-inner" *ngIf="listDrop.isOpen()">
                            <button disabled ngbDropdownItem *ngIf="showAllOption"
                                    (click)="config.filter.value = ''; listDrop.close()"
                            >
                                Choose
                            </button>
                            <button *ngFor="let option of options | filterBy: listFilter.value: 'name' | orderBy:'sort':'asc' "
                                    [disabled]="option?.disabled"
                                    (click)="config.filter.value = option; listDrop.close()"
                                    ngbDropdownItem
                            >
                                {{option.name}}
                            </button>
                        </div>
                    </div>
                </div>-->
        <div *ngSwitchCase="'numberRange'" class="ms-2">
            <form class="" #form="ngForm" (ngSubmit)="applyFilter(dRef)">
                <input type="number" class="form-control form-control-sm" placeholder="From"
                       [pattern]="'^\\d+((\.|\,)\\d{0,2})?$'"
                       id="from"
                       name="from"
                       [(ngModel)]="valueFrom"
                       [min]="0"
                       [max]="valueTo"
                       [step]="0.01"
                       [appAutofocus]="dRef.isOpen()"
                       [class.is-invalid]="(!canApplyFilter || form.controls.from?.invalid) && form.touched "
                       [class.is-valid]="(canApplyFilter || !form.controls.from?.invalid) && form.touched"
                >
                <input type="number" class="form-control form-control-sm mt-1" placeholder="To"
                       [(ngModel)]="valueTo"
                       id="to"
                       name="to"
                       [min]="valueFrom || 0"
                       [step]="0.01"
                       [pattern]="'^\\d+((\.|\,)\\d{0,2})?$'"
                       [class.is-invalid]="(!canApplyFilter || form.controls.to?.invalid) && form.touched "
                       [class.is-valid]="(canApplyFilter || !form.controls.to?.invalid) && form.touched"
                >
                <div class="invalid-feedback" *ngIf="!canApplyFilter">"To" must be greater than "From"</div>
                <div class="invalid-feedback" *ngIf="form.getError('pattern', 'from') || form.getError('pattern', 'to')">
                    The values must be in the correct format, like: "25.23"</div>
                <input type="submit" hidden [disabled]="!canApplyFilter || form.invalid">

            </form>
        </div>
    </ng-container>
</ng-template>
