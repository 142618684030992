<div class="d-flex justify-content-end mb-1">
  <button type="button" class="btn btn-primary btn-sm" (click)="processWarehouse($event)">New Warehouse</button>
</div>

<div class="card">
  <div class="card-body p-0">
    <div class="table-wrapper">
      <table class="table table-bordered table-hover table-striped table-pointer">
        <thead>
        <tr>
          <th>
            <app-th [colKey]="'name'"
                    [config]="cols.name"
                    [queryBuilder]="queryBuilder"
                    [name]="'Display name'"
                    [placeholder]="'#'"
                    (update)="filter.filter()"
                    (sort)="sort($event)"
            ></app-th>
          </th>
          <th>Residence</th>
          <th>Stock Active</th>
          <th>Managed stock</th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'lastImport'"
                    [config]="cols.lastImport"
                    [queryBuilder]="queryBuilder"
                    [name]="'Last import'"
                    (sort)="sort($event)"
                    (update)="filter.filter()"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell">Dispatch days</th>
          <th class="d-none d-sm-table-cell">Confirm days</th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'enabled'"
                    [config]="cols.enabled"
                    [queryBuilder]="queryBuilder"
                    [name]="'Status'"
                    (update)="filter.filter()"
                    (sort)="sort($event)"
                    [showAllOption]="true"
                    [inputWidth]="'90px'"
                    [dropdownPosition]="'bottom-right'"
            >
              <ng-container class="options">
                <option [value]="true">Enabled</option>
                <option [value]="false">Disabled</option>
              </ng-container>
            </app-th>
          </th>
        </tr>
        </thead>
        <tbody>
        <ng-container *ngFor="let warehouse of warehouses | orderBy: sortOrder?.field : sortOrder?.reverse | paginate: { itemsPerPage: queryBuilder.getLimit(), currentPage: queryBuilder.getPage() }; let i = index">
          <tr (click)="openDetail(warehouse)">
            <td class="position-relative row-toggler-wrap--left">
              <div class="row-toggler" container="body" ngbDropdown>
                <div [id]="'dropdownToggleMore' + i"
                     ngbDropdownToggle
                     (click)="$event.stopPropagation()">
                  <svg-icon [src]="iconUrls.ellipsis"></svg-icon>
                </div>

                <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownToggleMore' + i">
                  <button class="btn" ngbDropdownItem
                          (click)="processWarehouse($event, warehouse)">
                    <svg-icon [src]="iconUrls.edit"></svg-icon>
                    Edit
                  </button>
                </div>
              </div>
              {{ warehouse.name }}
            </td>
            <td>{{warehouse.residenceName}}</td>
            <td>{{warehouse.priceActive}}</td>
            <td>{{ warehouse.manageQty | booleanLabel }}</td>
            <td class="d-none d-sm-table-cell">{{warehouse.lastImport | bdate:'dateTime'}}</td>
            <td class="d-none d-sm-table-cell">{{warehouse.dispatchDays}}</td>
            <td class="d-none d-sm-table-cell">{{warehouse.confirmationDays}}</td>
            <td class="d-none d-sm-table-cell">
              <span class="badge fw-normal"
                    [ngClass]="warehouse.enabled?'bg-success':'bg-danger'">{{warehouse.enabled ? 'Enabled' : 'Disabled'}}</span>
            </td>
          </tr>
        </ng-container>

        </tbody>
      </table>
    </div>

    <app-tfoot [queryBuilder]="queryBuilder"
               [dataList]="warehouses"
               [loader]="loader"
               (changePageNum)="onChangePagination($event)"
               [useMoreItems]="true"
    ></app-tfoot>

  </div>
</div>
