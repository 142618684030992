import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { WarehouseRepository } from '../../infrastructure/repositories/warehouse.repository';
import { StatusMessageInterface } from '../../domain/models/status-message.interface';
import { WarehouseRequest } from '../../infrastructure/requests/warehouse.request';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class WarehouseService {

  readonly warehouseChanged$ = new Observable<string>();

  private readonly warehouseChangedSource$ = new BehaviorSubject<string>(null);

  constructor(
      private router: Router,
      private warehouseRepository: WarehouseRepository
  ) {
    this.warehouseChanged$ =  this.warehouseChangedSource$.asObservable();
  }

  create = (obj: WarehouseRequest): Observable<StatusMessageInterface> => this.warehouseRepository.create(obj);
  update = (obj: WarehouseRequest, id?: string): Observable<StatusMessageInterface> => this.warehouseRepository.update(id, obj);

  updateSelectedWarehouse = (id: string): void => this.warehouseChangedSource$.next(id);

  goToPriceListByWarehouseId(warehouseId: string, fulfilledStock: boolean, callback?): void
  {
    this.router.navigate([fulfilledStock ? '/products/stock_prices' : '/products/price_list'])
        .then(() => {
          this.updateSelectedWarehouse(warehouseId);
          if (callback) {
            callback();
          }
        });
  }
}
