import { AfterContentInit, Directive, ElementRef, Input, OnDestroy, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appFileUploader]',
})
export class FileUploaderDirective implements AfterContentInit, OnDestroy
{

  @Input() readonly multiple: boolean = false;
  @Input() openElement: HTMLElement;

  private fileInput: HTMLInputElement;
  private fileChangesListener: () => void;
  private openElementClickedListener: () => void;

  constructor(private _renderer: Renderer2, private _control: NgControl, private _el: ElementRef)
  { }

  ngAfterContentInit(): void
  {
    this.fileInput = this._renderer.createElement('input');
    this._renderer.setAttribute(this.fileInput, 'type', 'file');
    this._renderer.setAttribute(this.fileInput, 'multiple', this.multiple.toString());
    this._renderer.setStyle(this.fileInput, 'display', 'none');
    this._renderer.appendChild(this._el.nativeElement, this.fileInput);
    this.subscribeToFileChanges();
    this.subscribeToFOpenElementClicked();
  }

  ngOnDestroy(): void
  {
    if (this.fileChangesListener) {
      this.fileChangesListener();
    }
    if (this.openElementClickedListener) {
      this.openElementClickedListener();
    }
  }

  private subscribeToFileChanges(): void
  {
    this.fileChangesListener = this._renderer.listen(this.fileInput, 'change', (event) => {
      const files = this.multiple ? event.target.files : event.target.files.item(0);

      this._control.valueAccessor.writeValue(files);
      this._control.control.patchValue(files);
    });
  }

  private subscribeToFOpenElementClicked(): void
  {
    this.openElementClickedListener = this._renderer.listen(this.openElement, 'click', () => {
      this.fileInput.click();
    });
  }

}
