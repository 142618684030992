import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'booleanLabel'
})
export class BooleanLabelPipe implements PipeTransform {

  transform(value: any, titles: string[] = ['Yes', 'No']): any
  {
    return value ? titles[0] : titles[1];
  }

}
