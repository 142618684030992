import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import {AbstractForm, ADDRESS, CITY, NAME, normalizePin, PIN, removeSpaces} from './abstract.form';
import { Warehouse } from '../../domain/entity/warehouse/warehouse.entity';
import {Residence} from '../../domain/entity/residence/residence.entity';
import {AbstractAddress} from '../../domain/vo/abstract-address/abstract-address.vo';

export class ProcessWarehouseForm extends AbstractForm {
  minDispatchDays = 1;
  minDays = 1;

  constructor(public warehouse?: Warehouse, private residence?: Residence) {
    super();

    this.errorMsg = Object.assign(this.errorMsg, {
      length: 'The %path% should consist of %value% numbers',
    });
    const isFCResidence = Boolean(residence?.fulfilmentCenter);

    // this.maxPrice = warehouse?.mrp / 100 || 0;
    this.form = new UntypedFormGroup({
      name: new UntypedFormControl({ value: this.warehouse?.name, disabled: isFCResidence }, [
        Validators.required as any,
        Validators.minLength(this.minLength) as any,
        Validators.maxLength(this.nameMaxLength) as any,
        Validators.pattern(NAME),
        removeSpaces,
      ]),
      residence: new UntypedFormControl({ value: this.residence?.id || '', disabled: isFCResidence }, [
        Validators.required as any,
      ]),
      dispatchDays: new UntypedFormControl({ value: this.warehouse?.dispatchDays || '', disabled: isFCResidence }, [
        Validators.required as any,
        Validators.min(this.minDispatchDays) as any,
      ]),
      enabled: new UntypedFormControl({value: warehouse ? warehouse.enabled : true, disabled: isFCResidence }),
      manageQty: new UntypedFormControl({ value: this.warehouse?.manageQty ?? false, disabled: isFCResidence }, [
        Validators.required as any,
      ]),
      shippingAddress: this.createAddressFb(isFCResidence, this.warehouse?.shippingAddress)
    });
  }

  private createAddressFb(isFCResidence: boolean, addressFb?: AbstractAddress): UntypedFormGroup
  {
    return new UntypedFormGroup({
      // title: new FormControl({ value: addressFb?.title, disabled: isFCResidence }, [
      //     // Validators.required as any,
      //     // Validators.minLength(this.minLength) as any,
      //     Validators.maxLength(this.nameMaxLength) as any,
      // ]),
      // firstName: new FormControl({ value: addressFb?.contactFirstName, disabled: isFCResidence }, [
      //     // Validators.required as any,
      //     Validators.minLength(this.minLength) as any,
      //     Validators.maxLength(this.nameMaxLength) as any,
      // ]),
      // lastName: new FormControl({ value: addressFb?.contactLastName, disabled: isFCResidence }, [
      //     // Validators.required as any,
      //     Validators.minLength(this.minLength) as any,
      //     Validators.maxLength(this.nameMaxLength) as any,
      // ]),
      // contactPhone: new FormControl({ value: addressFb?.contactPhone, disabled: isFCResidence }, [
      //     // Validators.required as any,
      //     Validators.pattern(this.phonePattern) as any,
      // ]),
      addressLine : new UntypedFormControl({ value: addressFb?.addressLine, disabled: isFCResidence }, [
        Validators.required as any,
        Validators.minLength(this.minAddressLength) as any,
        Validators.maxLength(this.maxAddressLength) as any,
        Validators.pattern(ADDRESS),
      ]),
      pin: new UntypedFormControl({ value: addressFb?.pin, disabled: isFCResidence }, [
        Validators.required as any,
        Validators.pattern(PIN) as any,
        Validators.minLength(this.pinLength) as any,
        Validators.maxLength(this.pinLength) as any,
        normalizePin,
      ]),
      state: new UntypedFormControl({ value: addressFb?.state?.abbr || '', disabled: isFCResidence }, [
        Validators.required as any
      ]),
      cityName: new UntypedFormControl({ value: addressFb?.cityName, disabled: isFCResidence }, [
        Validators.required as any,
        Validators.minLength(this.minLength) as any,
        Validators.maxLength(this.cityLength) as any,
        Validators.pattern(CITY),
      ]),
    });
  }
}
