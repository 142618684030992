<app-loader *ngIf="loader"></app-loader>

<ng-template #elseProcessItem>
  <div class="row-toggler row-toggler--danger w-100">
    <svg-icon [src]="iconUrls.exclamationCircle"></svg-icon>
  </div>
</ng-template>

<div class="row justify-content-md-between align-items-center w-100 ms-0 me-0 px-1 pb-sm-2">
  <div class="col d-flex align-items-center justify-content-md-start justify-content-end p-0">
    <label for="warehouseFilter" class="form-label mt-1">Warehouse</label>
    <ng-select
            style="width: 200px"
            class="ng-select-sm ms-2"
            id="warehouseFilter"
            [clearable]="false"
            [placeholder]="!warehouses?.length ? 'There are no active warehouses' : 'Select Warehouse'"
            [(ngModel)]="selectedWarehouse"
            (ngModelChange)="queryBuilder.handleFilter('warehouse', selectedWarehouse.id); orders=[]"
            [items]="warehouses"
            bindLabel="name"
    ></ng-select>
    <button role="button" class="btn ms-1 btn-primary btn-sm" (click)="buildOrderListGrid()" [disabled]="!selectedWarehouse">
      Choose
    </button>
  </div>

  <div class="col-sm-6 d-flex align-items-center justify-content-end px-0 pt-2 pt-sm-0"
       *ngIf="!selectedWarehouse?.fulfilledStock && ordersLoaded">

    <div class="d-none d-sm-inline-block" *ngIf="massActionService.checkMassButtonByGroups(orders)"
         ngbDropdown placement="bottom-right">
      <button ngbDropdownToggle id="mass_actions" class="btn btn-brand btn-sm ms-2">Mass Actions</button>
      <div ngbDropdownMenu aria-labelledby="mass_actions">
        <button class="btn btn-danger" ngbDropdownItem (click)="cancel()">
          <svg-icon [src]="iconUrls.timesLight"></svg-icon>
          Cancel
        </button>
      </div>
    </div>
    <div class="mass-btn-wrapper d-sm-none" *ngIf="massActionService.checkEntry(orders, false)">
      <div class="btn-group btn-group-sm inner">
        <button class="btn btn-danger" (click)="cancel()">
          <svg-icon [src]="iconUrls.timesLight"></svg-icon>
          <span class="ms-2">Cancel</span>
        </button>
      </div>
    </div>
  </div>
</div>

<div class="card shadow">
  <div class="card-body p-0" style="position: relative;" *ngIf="ordersLoaded">
    <div class="table-wrapper overflow-auto">
      <table class="table table-bordered table-striped table-hover two-levels">
        <thead>
        <tr>
          <th>
            <app-th [colKey]="'orderNumber'"
                    [config]="cols.orderNumber"
                    [queryBuilder]="queryBuilder"
                    [name]="'Order ID'"
                    [placeholder]="'Order ID'"
                    (update)="filter.filter()"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'createAt'"
                    [config]="cols.createAt"
                    [queryBuilder]="queryBuilder"
                    [name]="'Order date'"
                    [placeholder]="'Order date'"
                    (update)="filter.filter()"
                    (sort)="sort($event)"
            ></app-th>
          </th>
          <th class="d-none d-sm-table-cell">Customer</th>
          <th>
            Ship by
<!--            <app-th [colKey]="'shipBy'"-->
<!--                    [config]="cols.shipBy"-->
<!--                    [queryBuilder]="queryBuilder"-->
<!--                    [name]="'Ship by'"-->
<!--                    [placeholder]="'Ship by'"-->
<!--                    (update)="filter.filter()"-->
<!--            ></app-th>-->
          </th>
          <th>Order Qty</th>
          <th class="d-none d-sm-table-cell">
            <app-th [colKey]="'total'"
                    [config]="cols.total"
                    [queryBuilder]="queryBuilder"
                    [name]="'Row Total'"
                    (update)="filter.filter()"
                    [dropdownPosition]="'bottom-right'"
            ></app-th>
          </th>
          <th class="p-0" *ngIf="!selectedWarehouse?.fulfilledStock"></th>
        </tr>
        </thead>
        <tbody class="first-level">
        <ng-container *ngFor="let facade of orders | orderBy: sortOrder?.field : sortOrder?.reverse | paginate: { itemsPerPage: queryBuilder.getLimit(), currentPage: queryBuilder.getPage() }; let i = index">
          <tr (click)="facade.toggled=!facade.toggled">
            <td class="position-relative row-toggler-wrap--left">
              <div class="row-toggler" container="body" ngbDropdown
                   *ngIf="showDetailsPopup || !selectedWarehouse?.fulfilledStock"
              >
                <div [id]="'dropdownToggleMore' + i"
                     ngbDropdownToggle
                     (click)="$event.stopPropagation()">
                  <svg-icon [src]="iconUrls.ellipsis"></svg-icon>
                </div>
                <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownToggleMore' + i">
                  <button class="btn btn-success" ngbDropdownItem [disabled]="!facade.canPack"
                          *ngIf="!selectedWarehouse?.fulfilledStock"
                          (click)="pack(facade.items)">
                    <svg-icon [src]="iconUrls.edit"></svg-icon>
                    Pack order
                  </button>
                  <button class="btn btn-danger" ngbDropdownItem [disabled]="!facade.canCancel"
                          *ngIf="!selectedWarehouse?.fulfilledStock"
                          (click)="cancel(facade.items)">
                    <svg-icon [src]="iconUrls.timesLight"></svg-icon>
                    Cancel order
                  </button>
                  <button class="btn btn-danger" ngbDropdownItem *ngIf="showDetailsPopup"
                          (click)="openDetail($event, facade)">
                    <svg-icon [src]="iconUrls.info"></svg-icon>
                    Show details
                  </button>
                </div>
              </div>
              {{ facade.orderNumber }}
            </td>
            <td class="d-none d-sm-table-cell">
              {{ facade.createdAt | bdate:'dateTime' }}
            </td>
            <td class="d-none d-sm-table-cell">
              Name: {{ facade.customerInfo.contactFirstName }} {{ facade.customerInfo.contactLastName }}<br>
              PIN: {{ facade.customerInfo.pin }}<br>
              State: {{ facade.customerInfo.state.name }}<br>
              City: {{ facade.customerInfo.cityName }}
            </td>
            <td>
              {{ facade.shipBy | bdate:'dateTime' }}
            </td>
            <td>
              {{ facade.qty }}
            </td>
            <td class="d-none d-sm-table-cell">
              <div class="text-nowrap">Subtotal: {{ facade.subTotal | indianCurrency}}</div>
              <div class="text-nowrap">Tax: {{ facade.tax | indianCurrency}}</div>
              <b class="text-nowrap">Total: {{ facade.total | indianCurrency}}</b>
            </td>
            <td style="width:30px" class="position-relative p-0"
                *ngIf="!selectedWarehouse?.fulfilledStock">
              <div class="row-toggler w-100"
                   *ngIf="!facade.blockMassCancel(facade.items); else elseProcessItem"
                   (click)="massActionService.entry(facade.items, $event)"
                   [ngClass]="massActionService.getClassByGroup(facade)">
                <svg-icon [src]="iconUrls[massActionService.getIconByGroup(facade)]"></svg-icon>
              </div>
            </td>
          </tr>
          <tr *ngIf="facade.toggled" class="p-0"></tr>
          <tr *ngIf="facade.toggled" class="second-level">
            <td colspan="7">
              <table class="table">
                <thead class="">
                <tr>
                  <th>PN</th>
                  <th>Brand</th>
                  <th class="d-none d-sm-table-cell">Name</th>
                  <th class="d-none d-sm-table-cell">Status</th>
                  <th class="d-none d-sm-table-cell">States</th>
                  <th>Qty</th>
                  <th class="d-none d-sm-table-cell">Unit price</th>
                  <th class="d-none d-sm-table-cell">Row Total</th>
                  <th class="p-0"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngFor="let salesItem of facade.items" (click)="openDetail($event, salesItem)">
                  <td class="position-relative row-toggler-wrap--left">
                    <div class="row-toggler" container="body" ngbDropdown
                         *ngIf="!selectedWarehouse?.fulfilledStock">
                      <div [id]="'dropdownToggleMore' + i"
                           ngbDropdownToggle
                           (click)="$event.stopPropagation()">
                        <svg-icon [src]="iconUrls.ellipsis"></svg-icon>
                      </div>
                      <div ngbDropdownMenu [attr.aria-labelledby]="'dropdownToggleMore' + i">
                        <button class="btn btn-success" ngbDropdownItem [disabled]="!salesItem.canConfirmCancel"
                                (click)="confirmCancel(salesItem)">
                          <svg-icon [src]="iconUrls.edit"></svg-icon>
                          Confirm cancel
                        </button>
                        <button class="btn btn-danger" ngbDropdownItem [disabled]="!salesItem.canCancel"
                                (click)="cancel([salesItem])">
                          <svg-icon [src]="iconUrls.timesLight"></svg-icon>
                          Cancel item
                        </button>
                        <button class="btn btn-danger" ngbDropdownItem [disabled]="!salesItem.canPack"
                                (click)="pack([salesItem])">
                          <svg-icon [src]="iconUrls.checkSquare"></svg-icon>
                          Pack item
                        </button>
                      </div>
                    </div>
                    <app-part-link [item]="salesItem"></app-part-link>
                  </td>
                  <td>{{salesItem.brand}}</td>
                  <td class="d-none d-sm-table-cell">{{salesItem.partName}}</td>
                  <td class="d-none d-sm-table-cell" [class.text-danger]="salesItem.canConfirmCancel">{{salesItem.status}}</td>
                  <td class="d-none d-sm-table-cell text-center">
                    <span class="text-nowrap">Packed: {{salesItem.packedQty}}</span>
                  </td>
                  <td>{{salesItem.qty}}</td>
                  <td class="d-none d-sm-table-cell"><span class="text-nowrap">{{salesItem.price | indianCurrency}}</span></td>
                  <td class="d-none d-sm-table-cell"><span class="text-nowrap">{{salesItem.total | indianCurrency}}</span></td>
                  <td style="width:30px" class="position-relative p-0"
                      *ngIf="!selectedWarehouse?.fulfilledStock">
                    <ng-container [ngSwitch]="true">
                      <div class="row-toggler w-100"
                           *ngSwitchCase="!facade.blockMassCancel(facade.items)"
                           (click)="massActionService.entry([salesItem], $event)"
                           [ngClass]="massActionService.getClass(salesItem)">
                        <svg-icon [src]="iconUrls[massActionService.getIcon(salesItem)]"></svg-icon>
                      </div>
                      <ng-container *ngSwitchCase="facade.blockMassCancel(facade.items) && facade.blockMassCancel([salesItem])"
                                    [ngTemplateOutlet]="elseProcessItem"></ng-container>
                      <ng-container *ngSwitchDefault></ng-container>
                    </ng-container>
                  </td>
                </tr>
                </tbody>
              </table>
            </td>
          </tr>
        </ng-container>
        </tbody>
      </table>
    </div>

    <app-tfoot [queryBuilder]="queryBuilder"
               [dataList]="orders"
               [loader]="loader"
               (changePageNum)="queryBuilder.changePageNum($event)"
    ></app-tfoot>
  </div>
</div>

<div *ngIf="!selectedWarehouse">
  Select warehouse from the list to see products
</div>
