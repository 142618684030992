import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { FilterQueryBuilder } from '../../utils/query-builder/filter-query-builder';
import { ColumnInterface, ConfigInterface } from '../../components/th/th.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProcessMappingComponent } from './process-mapping/process-mapping.component';
import { Title } from '@angular/platform-browser';
import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';
import { IconUrls } from '../../../constants/icon-urls';
import { UiFilter } from './ui-filter/ui-filter';
import { UiFilterConfigInterface } from './ui-filter/ui-filter-config.interface';
import { CONSTANTS } from '../../../constants/constants';
import { CoreService } from '../../services/core.service';
import { BoodmoBrandInterface } from '../../../domain/models/boodmo-brand.interface';
import { ImportService } from '../../services/import.service';
import { BrandMappingFacade } from '../../facades/brand-mapping/brand-mapping.facade';
import {ConfirmService} from '../../services/confirm-modal.service';
import {ToastrService} from '../../services/toastr.service';
import {finalize} from 'rxjs/operators';

@Component({
  selector: 'app-brand-mapping',
  templateUrl: './brand-mapping.component.html',
  styleUrls: ['./brand-mapping.component.scss']
})
export class BrandMappingComponent implements OnInit
{

  readonly cols: {[key: string]: ColumnInterface} = {
    brandName: {
      filter: { value: '', applied: false, name: 'brand_name', type: 'text' },
      sort: { name: 'brandName', value: null, applied: false },
    },
    boodmoName: {
      filter: { value: '', applied: false, name: 'boodmo_name', type: 'text' },
      sort: { name: 'boodmoName', value: null, applied: false },
    },
    active: {
      filter: { value: '', applied: false, name: 'active', type: 'select' },
      sort: { name: 'active', value: null, applied: false },
    }

  };
  readonly queryBuilder: FilterQueryBuilder;

  brands: BoodmoBrandInterface[];
  filter: UiFilter<BrandMappingFacade>;
  gridLoader: Promise<any> | boolean;
  mappings: BrandMappingFacade[];
  sortOrder: {
    field: string;
    reverse: boolean;
  };

  private readonly filterConfig: UiFilterConfigInterface[] = [
    { fieldName: 'brandName', type: 'text', filterField: 'brand_name' },
    { fieldName: 'boodmoName', type: 'text', filterField: 'boodmo_name' },
    { fieldName: 'active', type: 'boolean', filterField: 'active' },
  ];

  private filterChangesSubscription: Subscription;

  constructor(
    public iconUrls: IconUrls,
    private coreService: CoreService,
    private breadcrumbsService: BreadcrumbsService,
    private importService: ImportService,
    private modalService: NgbModal,
    private titleService: Title,
    public confirmService: ConfirmService,
    private toastrService: ToastrService,
  )
  {
    const title = 'Listing - Brand Mapping';
    this.titleService.setTitle(`${title} · Seller Cabinet`);
    this.breadcrumbsService.init(title);
    this.queryBuilder = new FilterQueryBuilder({
      filterSet: ['brand_name', 'boodmo_name', 'active']
    });
  }

  get isSmallDisplay(): boolean
  {
    return window.innerWidth <= CONSTANTS.MAX_POPUP_SCREEN_SM;
  }

  ngOnInit(): void
  {
    this.buildMappingItemsList();
  }

  openProcessMapping(mapping?: BrandMappingFacade): void
  {
    const modalRef = this.modalService.open(ProcessMappingComponent, { size: 'md' });
    modalRef.componentInstance.mapping = mapping;
    modalRef.componentInstance.brands = this.brands;
    modalRef.result.then((newMapping: BrandMappingFacade) => {
      this.buildMappingItemsList();
    }, () => {});
  }

  sort(sortCol: ConfigInterface): void
  {
    Object.values(this.cols).forEach((col) => {
      if (col.sort && sortCol !== col.sort) {
        col.sort.applied = false;
        col.sort.value = null;
      }
    });
    this.sortOrder = {
      field: sortCol.name,
      reverse: sortCol.value
    };
    this.queryBuilder.changePageNum(1);
  }

  applyFilters(): void
  {
    this.filter.filter();
    this.queryBuilder.changePageNum(1);
  }

  deleteMapping(mapping: BrandMappingFacade) {
    this.confirmService.confirm({
      title: 'Accept action',
      message: `Are you sure you want to delete "${mapping.brandName}-${mapping.boodmoName}" Brand-mapping?`,
    }).then(() => {
      this.gridLoader = true;
      this.importService.deleteMapping(mapping.id).pipe(
          finalize(() => {
            this.gridLoader = false;
          })
      ).subscribe((resp) => {
        this.buildMappingItemsList();
        this.toastrService.successNotify(resp);
      });
    }, () => {});
  }

  private buildMappingItemsList(): void
  {
    this.gridLoader = this.importService.prepareMappingData().then((res) => {
      this.mappings = res.mappings;
      this.brands = res.brands;
      this.createFilter();
    }).finally(() => this.gridLoader = false);
  }

  private createFilter(): void
  {
    this.filter = new UiFilter(this.mappings, this.filterConfig, this.queryBuilder);
    this.filterChangesSubscription = this.filter.filterChanged$
      .subscribe((v) => this.mappings = v);
    this.filter.filter();
  }
}
