<div class="modal-header">
  <h5 class="modal-title">Processing item details</h5>
  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>
<div class="modal-body">
  <p class="row">
    <b class="col-5">Part Number:</b>
    <span class="col-7"><app-part-link [item]="facade"></app-part-link></span>
  </p>
  <p class="row">
    <b class="col-5">Brand:</b>
    <span class="col-7">{{ facade?.brand }}</span>
  </p>
  <p class="row">
    <b class="col-5">Part Name:</b>
    <span class="col-7">{{ facade?.partName }}</span>
  </p>
  <p class="row">
    <b class="col-5">Status:</b>
    <span class="col-7">{{ facade?.status }}</span>
  </p>
  <p class="row">
    <b class="col-5">States:</b>
    <!--    <span class="col-7">{{ facade.confirmationBefore | bdate: 'dateTime' }}</span>-->
  </p>
  <p class="row">
    <b class="col-5">Qty:</b>
    <span class="col-7">{{ facade?.qty }}</span>
  </p>
  <p class="row">
    <b class="col-5">Unit Price:</b>
    <span class="col-7">{{ facade?.price | indianCurrency}}</span>
  </p>
  <p class="row">
    <b class="col-5">Row Total:</b>
    <span class="col-7">{{ facade?.total | indianCurrency }}</span>
  </p>

</div>
<div class="modal-footer btn-modal-wrapp">
  <button type="button" class="btn btn-primary btn-modal" (click)="activeModal.close()">Close</button>
</div>
