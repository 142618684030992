<div class="modal-header px-3 py-2">
  <h5 class="modal-title text-center flex-grow-1">{{ facade ? 'Edit' : 'Add' }} residence</h5>

  <button type="button" class="btn-close" aria-label="Close" (click)="activeModal.dismiss()"></button>
</div>

<div class="modal-body pt-sm-5 px-sm-5">
<!--  [ngClass]="{'was-validated' : fb.isSubmitted()}"-->
  <form class="mb-3 dimensions-form" [formGroup]="form" (ngSubmit)="trySubmit()">

<!--    [pattern]="fb.getPattern('NAME')"-->
    <div class="row">
      <div class="col-lg-6">
        <div class="mb-3 row">
          <label class="form-label col-sm-4" for="displayName">Display name:</label>
          <div class="col-sm-8">
            <input type="text" id="displayName"
                   class="form-control form-control-sm"
                   formControlName="name"
                   [minLength]="fb.minLength"
                   [maxLength]="fb.nameMaxLength"
                   [class.is-invalid]="form.get('name').invalid && fb.isSubmitted()"
                   [class.is-valid]="!form.get('name').invalid && fb.isSubmitted()"
                   required
            >
            <app-form-control-error [control]="form.get('name')"
                                    [formSubmit]="fb.isSubmitted()"
            ></app-form-control-error>
          </div>
        </div>

        <div class="mb-3 row">
          <label class="form-label col-sm-4" for="companyName">Company name:</label>
          <div class="col-sm-8">
            <input type="text" id="companyName" class="form-control form-control-sm"
                   required
                   formControlName="companyName"
                   [minLength]="fb.nameMinLength"
                   [maxLength]="fb.nameMaxLength"
                   [class.is-invalid]="form.get('companyName').invalid && fb.isSubmitted()"
                   [class.is-valid]="!form.get('companyName').invalid && fb.isSubmitted()"
            >
            <app-form-control-error [control]="form.get('companyName')"
                                    [formSubmit]="fb.isSubmitted()"
            ></app-form-control-error>
          </div>
        </div>

        <div class="mb-3 row"
             formGroupName="billingAddress"
        >
          <label class="form-label col-sm-4">GST code:</label>
          <div class="col-sm-8">
            <app-gst-control formControlName="gstNumber"
                             [fb]="fb"
                             [form]="form"
                             [isRequired]="true"
                             [groupName]="'billingAddress'"
            ></app-gst-control>
          </div>
        </div>
      </div>

      <div class="col-lg-6" *ngIf="isUpdate && facade.residence.isFC">
        <div class="mb-3">
          <div class="form-check">
            <input class="form-check-input" disabled type="checkbox" checked>
            <label class="form-check-label text-muted">
              This residence is based on Boodmo Fulfilment Center
            </label>
          </div>
        </div>
        <div class="mb-3 row">
          <label class="form-label col-sm-4">Boodmo FC:</label>
          <div class="col-sm-8">
            {{facade.residence.fulfilmentCenter}}
          </div>
        </div>
        <div class="mb-3 row">
          <label class="form-label col-sm-4">Dispatch time:</label>
          <div class="col-sm-8">{{facade?.residence.dispatchDays}} day(s)</div>
        </div>
        <div class="">
          <div class="invalid-feedback d-block">Shipping and billing address of the Fulfillment center wil be used in invoices</div>
        </div>
      </div>
    </div>

    <div class="row pt-5">
      <div class="col-lg-6">
        <div class="fw-bold mb-4">Billing address</div>

        <ng-container *ngTemplateOutlet="formTemplate;context:{form: form, fgName: 'billingAddress', type: 'billing'}"></ng-container>
      </div>

      <div class="col-lg-6">
        <div class="fw-bold mb-4">Shipping address</div>

        <ng-container *ngTemplateOutlet="formTemplate;context:{form: form, fgName: 'shippingAddress', type: 'shipping'}"></ng-container>
      </div>
    </div>
    <button type="submit" hidden #submitButton>submit</button>
  </form>
</div>

<div class="modal-footer btn-modal-wrapp">
<!--  <button type="button" class="btn btn-outline-secondary btn-modal" (click)="activeModal.dismiss()">Close</button>-->
  <button type="submit" class="btn btn-success btn-modal" (click)="submitButton.click()">Save</button>
</div>

<ng-template #formTemplate let-form="form" let-type="type" let-fgName="fgName">
  <ng-template #readOnlyAddress>
    <div [innerHTML]="facade?.residence[fgName].convertAddressToLine | safeHtml"></div>
  </ng-template>
  <div [formGroup]="form" *ngIf="canEditAddress; else readOnlyAddress">
    <div [formGroupName]="fgName">
      <div class="mb-3 row">
        <label class="form-label col-sm-4" [for]="type + 'addressLine'">Office address:</label>
        <div class="col-sm-8">
          <input type="text" [id]="type + 'addressLine'" class="form-control form-control-sm"
                 required
                 formControlName="addressLine"
                 [maxLength]="fb.maxAddressLength"
                 [minLength]="fb.minAddressLength"
                 [class.is-invalid]="form.get([fgName, 'addressLine']).invalid && fb.isSubmitted()"
                 [class.is-valid]="!form.get([fgName, 'addressLine']).invalid && fb.isSubmitted()"
          >
          <app-form-control-error [control]="form.get([fgName, 'addressLine'])"
                                  [formSubmit]="fb.isSubmitted()"
          ></app-form-control-error>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="form-label col-sm-4" [for]="type + 'pin'">PIN:</label>
        <div class="col-sm-8">
          <app-pin-control formControlName="pin"
                           [groupName]="fgName"
                           [fb]="fb"
                           [form]="form"
          ></app-pin-control>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="form-label col-sm-4" [for]="type + 'state'">State</label>
        <div class="col-sm-8">
          <select [id]="type + 'state'" class="form-select form-select-sm"
                  formControlName="state"
                  required
                  (change)="handlerChangeState(fgName)"
                  [class.is-invalid]="form.get([fgName, 'state']).invalid && fb.isSubmitted()"
                  [class.is-valid]="!form.get([fgName, 'state']).invalid && fb.isSubmitted()"
          >
            <option value="">Select state</option>
            <option [value]="state.abbr" *ngFor="let state of states">{{state.name}}</option>
          </select>
          <app-inline-loader *ngIf="statesLoading"></app-inline-loader>
          <app-form-control-error [control]="form.get([fgName, 'state'])"
                                  [formSubmit]="fb.isSubmitted()"
          ></app-form-control-error>
        </div>
      </div>

      <div class="mb-3 row">
        <label class="form-label col-sm-4" [for]="type + 'city'">City</label>
        <div class="col-sm-8">
          <input type="text" [id]="type + 'city'" class="form-control form-control-sm"
                 required
                 [minLength]="fb.minLength"
                 [maxLength]="fb.cityLength"
                 formControlName="cityName"
                 [class.is-invalid]="form.get([fgName, 'cityName']).invalid && fb.isSubmitted()"
                 [class.is-valid]="!form.get([fgName, 'cityName']).invalid && fb.isSubmitted()"
          >
          <app-form-control-error [control]="form.get([fgName, 'cityName'])"
                                  [formSubmit]="fb.isSubmitted()"
          ></app-form-control-error>
        </div>
      </div>
    </div>
  </div>
</ng-template>
