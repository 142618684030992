export class ShipmentBoxItemFacade {
    private constructor(
        private _brandName,
        private _number,
        private _partName,
        private _deliveryPrice,
        private _price,
        private _qty,
        private _gst,
    ) {
    }

    get brandName() {
        return this._brandName;
    }

    get partNumber() {
        return this._number;
    }

    get partName() {
        return this._partName;
    }

    get deliveryPrice() {
        return this._deliveryPrice;
    }

    get price() {
        return this._price;
    }

    get qty() {
        return this._qty;
    }

    get gst() {
        return this._gst;
    }

    static build = (
        {
            brand_name,
            number,
            part_name,
            delivery_price,
            price,
            qty,
            gst,
        }: {
            brand_name: string;
            number: string; // TODO number?
            part_name: string;
            delivery_price: number;
            price: number;
            qty: number;
            gst: number;
        }) => new ShipmentBoxItemFacade(
        brand_name,
        number,
        part_name,
        delivery_price,
        price,
        qty,
        gst,
    );
}
