import { BillingAddress } from '../../vo/billing-address/billing-address.vo';
import { ShippingAddress } from '../../vo/shipping-address/shipping-address.vo';

export class Residence {
  private constructor(
    private _id: string,
    private _name: string,
    private _fulfilmentCenter: string | null,
    private _invoicePrefix: string,
    private _dispatchDays: number,
    private _billingAddress: BillingAddress,
    private _shippingAddress: ShippingAddress,
  ) {
  }

  get id(): string {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get fulfilmentCenter(): string | null {
    return this._fulfilmentCenter;
  }

  get invoicePrefix(): string {
    return this._invoicePrefix;
  }

  get dispatchDays(): number
  {
    return this._dispatchDays;
  }

  get billingAddress(): BillingAddress {
    return this._billingAddress;
  }

  get shippingAddress(): ShippingAddress {
    return this._shippingAddress;
  }

  get isFC(): boolean
  {
    return Boolean(this.fulfilmentCenter);
  }

  static build = (
      {
        id,
        name,
        fulfilment_center,
        invoice_prefix,
        dispatch_days,
        billingAddress,
        shippingAddress,
      }: {
        id: string;
        name: string;
        fulfilment_center: string | null;
        invoice_prefix;
        dispatch_days;
        billingAddress: BillingAddress;
        shippingAddress: ShippingAddress;
      }): Residence => new Residence(
      id,
      name,
      fulfilment_center,
      invoice_prefix,
      dispatch_days,
      billingAddress,
      shippingAddress,
  );
}
