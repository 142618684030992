import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { CurrentSeller } from '../../../../domain/entity/current-seller/current-seller.entity';
import { SellerService } from '../../../services/seller.service';
import { IconUrls } from '../../../../constants/icon-urls';
import { Router } from '@angular/router';
import { WarehouseService } from '../../../services/warehouse.service';

@Component({
  selector: 'app-profile-panel',
  templateUrl: './profile-panel.component.html',
  styleUrls: ['./profile-panel.component.scss']
})
export class ProfilePanelComponent {
  @HostBinding('class.profile-panel') profilePanel = true;
  @HostBinding('class.profile-panel-show') @Input() showProfilePanel = false;
  @Input() currentSeller: CurrentSeller;
  @Output() hideProfilePanel = new EventEmitter<boolean>();
  constructor(
    public sellerService: SellerService,
    public iconUrls: IconUrls,
    private router: Router,
    private warehouseService: WarehouseService,
    ) {
  }

  goToImports(warehouseId: string): void
  {
    this.router.navigate(['/products/import/step1'])
        .then(() => {
          this.warehouseService.updateSelectedWarehouse(warehouseId);
          this.hideProfilePanel.emit(false);
        });
  }

  goToPriceList(warehouseId: string, fulfilledStock: boolean): void
  {
      this.warehouseService.goToPriceListByWarehouseId(warehouseId, fulfilledStock, () => {
          this.hideProfilePanel.emit(false);
      });
  }
}
