import { HttpEvent } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AbstractRepository } from './abstract.repository';
import { StatusMessageInterface } from '../../domain/models/status-message.interface';

@Injectable({
  providedIn: 'root',
})

export class CoreRepository extends AbstractRepository {
  uploadFile = (formData: FormData): Observable<HttpEvent<StatusMessageInterface>> =>
    // TODO fix url
    this.httpClient.post<StatusMessageInterface>(this.buildUrl('srm/upload-file'), formData, {
      reportProgress: true,
      observe: 'events'
    });
}
