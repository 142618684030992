import { Component, Input } from '@angular/core';
import {AbstractControl} from '@angular/forms';

import * as FormValidators from '../../forms/abstract.form';

@Component({
  selector: 'app-form-control-error',
  templateUrl: './form-control-error.component.html',
  styleUrls: ['./form-control-error.component.scss'],
})
export class FormControlErrorComponent {
  @Input() control: AbstractControl;
  @Input() formSubmit?: boolean;
  readonly validators = FormValidators;

  /**
   * @desc Checks if formControl has error and it should be displayed
   */
  hasError(): boolean {
    const { invalid, dirty } = this.control;

    return (this.formSubmit && invalid) || (invalid && dirty);
  }

  /**
   * @desc Check which pattern is being used
   * @param pattern
   */
  isPattern(pattern: RegExp): boolean {
    return this.control.errors.pattern.requiredPattern === String(pattern);
  }
}
