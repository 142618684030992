export enum SalesItemStatus
{
    'New',
    'Processing',
    'Cancel Requested',
    'Pending',
    'Closed',
    'Cancelled',
    'Completed'
}
