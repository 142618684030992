import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {SalesItemRepository} from '../../infrastructure/repositories/sales-item.repository';
import {NewOrderItemFacade} from '../facades/new-order-item/new-order-item.facade';
import {ProcessingOrderFacade} from '../facades/processing-order/processing-order.facade';
import {StatusMessageInterface} from '../../domain/models/status-message.interface';
import {SalesItemGroupToOrderService} from './sales-item-group-to-order.service';
import {SalesItemConfirmRequest} from '../../infrastructure/requests/sales-item-confirm.request';

@Injectable({
  providedIn: 'root'
})
export class SalesItemService {

  constructor(public salesItemRepository: SalesItemRepository) {
  }

  fetchNewOrderList = (warehouseId: string): Observable<NewOrderItemFacade[]> =>
    this.salesItemRepository.fetchNewOrderList(warehouseId).pipe(
      map(({data}) => data?.map(NewOrderItemFacade.build))
    );

  fetchProcessingOrderList = (warehouseId: string): Observable<ProcessingOrderFacade[]> =>
    this.salesItemRepository.fetchProcessingOrderList(warehouseId).pipe(
      map(({data}) => SalesItemGroupToOrderService.fetch(data)),
    );

  confirm = (ids: SalesItemConfirmRequest[]): Observable<StatusMessageInterface> => this.salesItemRepository.confirm(ids);

  cancel = (ids: string[]): Observable<StatusMessageInterface> => this.salesItemRepository.cancel(ids);

  cancelApprove = (salesItemId: string, qty: number): Observable<StatusMessageInterface> => this.salesItemRepository.cancelApprove(salesItemId, qty);
}
