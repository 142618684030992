import {MerchantAccountResponse} from '../../../infrastructure/response/merchant-account.response';
import {BillingAddress} from '../../vo/billing-address/billing-address.vo';

export class MerchantAccount {
  private constructor(
    private _billingAddress: BillingAddress,
    private _contactEmail: string,
    private _cinNumber: string,
    private _bankName: string | null,
    private _bankIfsc: string | null,
    private _bankAccountNumber: string | null,
    private _bankBranchName: string | null,
    private _cancelledCheque: string | null,
    private _panCard: string | null,
    private _gstCert: string | null,
    private _sellerSignature: string | null,
  ) {
  }

  get billingAddress(): BillingAddress {
    return this._billingAddress;
  }

  get contactEmail(): string {
    return this._contactEmail;
  }

  get cinNumber(): string {
    return this._cinNumber;
  }

  get bankName(): string | null {
    return this._bankName;
  }

  get bankIfsc(): string | null {
    return this._bankIfsc;
  }

  get bankAccountNumber(): string | null {
    return this._bankAccountNumber;
  }

  get bankBranchName(): string | null {
    return this._bankBranchName;
  }

  get cancelledCheque(): string | null {
    return this._cancelledCheque;
  }

  get panCard(): string | null {
    return this._panCard;
  }

  get gstCert(): string | null {
    return this._gstCert;
  }

  get sellerSignature(): string | null {
    return this._sellerSignature;
  }

    static build = (
        {
            bank_name,
            bank_ifsc,
            bank_account_number,
            bank_branch_name,
            cancelled_cheque,
            pan_card,
            gst_cert,
            seller_signature,
            contact_email,
            cin_number,
            billing_address,
        }: MerchantAccountResponse
    ): MerchantAccount => new MerchantAccount(
        BillingAddress.build(billing_address),
        contact_email,
        cin_number,
        bank_name,
        bank_ifsc,
        bank_account_number,
        bank_branch_name,
        cancelled_cheque,
        pan_card,
        gst_cert,
        seller_signature,
    );
}
